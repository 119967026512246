import React, { useState } from 'react';
import {Form,Modal,Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import actions from '../../action/user';
import axios from '../../helpers/requests'
import PictureUploader from '../common/PictureUploader';

interface MyProps {
    show: boolean;
    admin?: boolean;
    userId?:string;
    user:any,
    onHide?: any;
    t?:any
}

const EditProfileModal: React.FC<MyProps> = (props: any) => {

   const[password,setPassword]=useState("")
   const[image,setImage]=useState("")
   const[curUser,setCurUser]=useState("")
   const[frEror,setFrError]=useState("")
   const {t} = useTranslation();
   const onSubmit = async (e) => {
		e.preventDefault()
      setFrError("")
      if(password){
         if(password.length<=5){
            setFrError(props.t("Password must be minimum 6 char long"))
         }else{
            if(props.admin){
               props.updateAdmin({password:password},props.userId, props.history)

            }else{
               props.update({password:password}, props.history)
            }
            setPassword("")

         }
      }else{
         setFrError(props.t("Password must be minimum 6 char long"))

      }

		
	}
   
    	return (
	        <Modal 
	        	show={props.show} 
	        	onHide={props.onHide}
		        size="sm"
		        centered
		   	  >
			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h5' id="edit-profile">{props.t("profile.Profile")}</Modal.Title>
			  </Modal.Header>
           <Form role="form" onSubmit={onSubmit}>
			  <Modal.Body>
			   
               <div className="form-row">
               <Form.Group className="col-md-12  text-center">
                     <PictureUploader admin={props.admin} userId={props.userId} current={props.user['avatar']?props.user['avatar']:"/img/user/default.png"}/>
                  </Form.Group>
                  <Form.Group className="col-md-12">
                     <Form.Label>{props.t("profile.Phone")}</Form.Label>
                     <Form.Control type="text" disabled defaultValue={props.user['phone']} placeholder={props.t("profile.Phone")} />
                  </Form.Group>
                  <Form.Group className="col-md-12">
                     <Form.Label>{props.t("profile.Email")}</Form.Label>
                     <Form.Control type="text" disabled defaultValue={props.user['email']} placeholder={props.t("profile.Email")}/>
                  </Form.Group>
                  <Form.Group className="col-md-12 mb-0">
                     <Form.Label>{props.t("profile.Password")}</Form.Label>
                     <Form.Control type="password" autoComplete="off" value={password} onChange={e => setPassword(e.target.value)}  placeholder={props.t("profile.Password")}/>
                     <Form.Text><i>{frEror}</i></Form.Text>
                  </Form.Group>
               </div>
         
			  </Modal.Body>

			  <Modal.Footer>
			    <Button type='button' onClick={props.onHide} variant="outline-primary" size="sm" className="d-flex text-center justify-content-center">{props.t("profile.Cancel")}</Button>
             <Button
											
											className="btn  btn-outline-secundary w-50 btn-lg w-50 mt-1 text-white btn bg-white"
											color="warning"
											size="sm"
											type="submit"
										>
											{t("profile.Update")}
                          			</Button>			  </Modal.Footer> </Form>
			</Modal>
    	);
     
}
const mapStateToProps = (state) => {
   return { state: state.auth }
}

export default connect(mapStateToProps, actions)(EditProfileModal);
 