import React, { useEffect, useState } from "react";
import { Card, CardImg, Col, Container, Row } from "react-bootstrap";
import { CardBody } from "reactstrap";
import SectionHeading from "../common/SectionHeading";
import Ripples from "react-ripples";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBicycle, faCar, faWalking } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel3";

/* import { useTranslation } from "react-i18next";
import { usePromiseTracker } from "react-promise-tracker";
 */

type ListProps = {
  history?: any;
  latest?: boolean;
  haveMini?: boolean;
  loop?: boolean;
  autoplay?: boolean;
  featuredC?: boolean;
  restaurants: any;
  heading: string;
  neer?: boolean;
  items0?: number;
  items764?: number;
  items765?: number;
  items1200?: number;
};

const Features: React.FC<ListProps> = ({
  neer,
  history,
  featuredC = false,
  items0 = 1,
  haveMini = false,
  items764 = 2,
  items765 = 3,
  items1200 = 3,
  autoplay = false,
  restaurants,
  heading,
}) => {
  /* const { promiseInProgress } = usePromiseTracker();
	const { t } = useTranslation(); */
  const [latestl, setLatestl] = useState(false);

  const [options, setoptions] = useState({
    responsive: {
      0: {
        items: items0,
      },
      764: {
        items: items764,
      },
      765: {
        items: items765,
      },
      1200: {
        items: items1200,
      },
    },
    lazyLoad: true,
    loop: true,
    autoplay: autoplay,
    autoplaySpeed: 2000,
    dots: false,
    autoplayTimeout: 2000,
    nav: false,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    autoplayHoverPause: true,
  });
  if (neer) {
    if (restaurants.length > 1) {
      restaurants.sort((a, b) => {
        if (a["distanceformuser"] < b["distanceformuser"]) return -1;
        if (a["distanceformuser"] > b["distanceformuser"]) return 1;
        return 0;
      });
    }
  }
  useEffect(() => {}, [restaurants]);

  return (
    <Container className="p-2">
      {restaurants.length > 2 && (
        <SectionHeading haveMini={haveMini} heading={heading} />
      )}
      <Row>
        <Col md={12}>
          {restaurants.length > 1 && (
            <OwlCarousel {...options} className="futures-ad owl-theme">
              {restaurants.map((restaurant) => {
                const { id, name, featured, image, logo, distancefromuser } =
                  restaurant;
                return (
                  (featured === 1 || featuredC) && (
                    <div className="mr-2 myfrontslide" key={id}>
                      <Ripples
                        className="d-block myblock"
                        onClick={() => {
                          setTimeout(function () {
                            history.push(`/shop/${id}`);
                          }, 275);
                        }}
                      >
                        <Card className="my-featured shadow-sm p-0 m-0">
                          <img
                            className="banner-img-front"
                            src={image}
                            alt=""
                          />
                          <CardImg
                            className="rounded-sm feture-logo"
                            src={logo}
                            alt=""
                          />
                          <CardBody className="p-2 h-restaurant text-left">
                            <h6 className="my-0 font-weight-bold shop-name">
                              {name}
                            </h6>
                            {localStorage.getItem("GPS") !== "0" && (
                              <div className="mt-1 d-flex align-items-center text-muted">
                                {distancefromuser.toFixed(2) < 300 && (
                                  <small className="">
                                    <FontAwesomeIcon
                                      icon={
                                        distancefromuser.toFixed(2) < 10
                                          ? faWalking
                                          : distancefromuser.toFixed(2) < 20
                                          ? faBicycle
                                          : faCar
                                      }
                                      className="align-middle"
                                    />{" "}
                                    {distancefromuser.toFixed(2)} km
                                  </small>
                                )}
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      </Ripples>
                    </div>
                  )
                );
              })}
            </OwlCarousel>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default Features;
