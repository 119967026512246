import React, { useEffect, useState } from "react";

import { Row, Col, Container, Button } from "react-bootstrap";
import axios from "./../helpers/requests";
import { Link } from "react-router-dom";
import TopSearch from "./home/TopSearch";
import $ from "jquery";
import SectionHeading from "./common/SectionHeading";
import Ripples from "react-ripples";

import FontAwesome from "./common/FontAwesome";
import { useTranslation } from "react-i18next";
import Geolocation from "react-native-geolocation-service";
import Features from "./home/Features";
import FeaturesList from "./home/FeaturesList";
import Categories from "./home/Categories";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import GoogleMapReact from 'google-map-react';
import Marker from "./common/map2/Marker";

import {
  RootStateOrAny,
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from "react-redux";

import { Parallax } from "react-parallax";
import restaurant from "../action/restaurant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEnvelope, faFeather, faGifts, faMapPin, faSatellite, faSearch, faSpinner, faStar, faSubscript } from "@fortawesome/free-solid-svg-icons";

export const useSelector: TypedUseSelectorHook<RootStateOrAny> =
  useReduxSelector;

const Index: React.FC = (props: any) => {
  const authState = useSelector((state: RootStateOrAny) => state.auth);
 let temprand='0'
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  //const [slides, setSlides] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [countryCode, setCountryCode] = useState("");
   const [restaurants2, setRestaurants2] = useState([]);
   const [latest, setLatest] = useState([]);
   const [zoomC, setZoom] = useState(5);
   const [categories, setCategories] = useState([]);
   const [slides, setSlides] = useState([]);
   const [loading, setLoading] = useState(false);
   const [locations, setLocations] = useState<any>([]);

  const { t } = useTranslation();
  const { promiseInProgress } = usePromiseTracker();
  const exampleMapStyles = [
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d3d3d3"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "color": "#808080"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#b3b3b3"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "weight": 1.8
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d7d7d7"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ebebeb"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#a7a7a7"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#efefef"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#696969"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#737373"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d6d6d6"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {},
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dadada"
            }
        ]
    }
];
  function getData(lat,lng) {
    setRestaurants([])
    axios
      .get<Object>("/restaurants/nearby", {
        params: {
          dstc: localStorage.getItem("dstc"),
          lat: lat,
          lng: lng,
        },
      })
      .then((res) => {
        setRestaurants(res.data["restaurants"]);
        setCategories(res.data["categories"]);

        setSlides(res.data["slides"]);
      })
      .catch((err) => {
        console.log(err);
      });
    getData2(lat,lng);
  }

  function getData2(lat,lng) {
    axios
      .get<Object>("/restaurants/latest", {
        params: {
          dstc: localStorage.getItem("dstc"),
          lat: lat,
          lng: lng,
        },
      })
      .then((res) => {
        setLatest(res.data["restaurants"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function getLocations() {
    axios
      .get<any>("/locations")
      .then((res) => {
        setLocations((locations) => [...locations, ...res.data]);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function setLocationLatLng(lat,lng){
    setCenter({
      lat: lat,
      lng: lng,
    });
    getData(lat,lng)
    localStorage.setItem("GPS","0");
  }
  function locateMe() {
    setLoading(true)
    Geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCenter({
          lat: latitude,
          lng: longitude,
        });
        getData(latitude,longitude);
        getData2(latitude,longitude);
        localStorage.setItem("GPS","1");
        localStorage.setItem("lat",latitude.toString());
        localStorage.setItem("lng",longitude.toString());
        
        setZoom(17)
        setLoading(false)
      },
      (error) => {
        props.history.push("/nogps");
      },
      { enableHighAccuracy: false, timeout: 6000, maximumAge: 9000 }
    );
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getLocations();

    getData(center.lat,center.lng);
  }, []);

  return (
    <>
     <div   className="section mt-5 pb-0  bg-white" style={{  position: 'relative', width: '100%', top: '0', left: '0', height: '350px' }}>
    {center.lat === 0 && <div className="floatingMapButton mb-0 text-center row">
    <h2 className="text-white text-center"> 
            <Button onClick={()=>{locateMe();setCountryCode("")}} className={"btn-locirajme btn btn-small ml-3 p-2 btn-location text-white "}>
                  <FontAwesomeIcon className={loading?"fa-spin":""}icon={faSatellite} /> {t("Locate me")}
                </Button></h2>
               
          </div>
  }
     {center.lat !== 0 && <div  className="floatingMap"  style={{ zIndex: 1, position: 'absolute' }}>
         { restaurants.map(marker => {
      //const onClick = props.onClick.bind(this, marker)
      let a = "";
      a= marker["name"]
      if(!a.includes('Test')){
      return (
        <Ripples
                        className="d-block myblock"
                        onClick={() => {
                          setTimeout(function () {
                            props.history.push(`/shop/${marker["id"]}`);
                          }, 275);
                        }}
                      >
          <Row
              key={marker["id"]}
              className="p-2"
              
          >
             <div className="col-md-3 text-center ">
                      <img style={{width:"30px",height:"30px"}}alt={marker["name"]} src={marker["logo"]}/>
            </div>
                       <div className="col-md-9 text-left my-2">
                       {marker["name"]}
            </div>
          </Row>
          </Ripples>
      )
      }
  })}
  
  </div>}
   <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyC8NydMkPVNT4AsZOUolwFcQn6XVm-Q-YQ' }}
              defaultCenter={center.lat===0?{ lat: 45.8150108 ,lng: 15.981919 }:center}
              center={center.lat===0?{ lat: 45.8150108 ,lng: 15.981919 }:center}
              onClick={()=>{}}
              options={{
                  gestureHandling: "greedy",
                  styles: exampleMapStyles,
                  mapTypeControl: false,
                  streetViewControl: false,
                  zoomControl: false,
                  fullscreenControl: false,
                  zoom:zoomC,
                  minZoom: 5,
                  maxZoom: 10
              }}

              defaultZoom={zoomC}
          >
              {restaurants.map(marker => {
      //const onClick = props.onClick.bind(this, marker)
      let a = "";
      a= marker["name"]
      if(!a.includes('Test')){
      return (
          <Marker
              key={marker["id"]}
              id={marker["id"]}
              lat={marker["addresses"][0]["latitude"]}
              lng={marker["addresses"][0]["longitude"]}
              /* onClick={}
              onTouchEnd={} */
              name={marker["name"]}
              address={marker["name"]}
              tel={marker["phone"]}
              color="blue"
              position={{ lat: marker["addresses"][0]["latitude"], lng:marker["addresses"][0]["longitude"] }}
          >
              
          </Marker>
      )
      }
  })}
          
          </GoogleMapReact>
          </div>
      <section className="section pt-5 pb-0 products-section bg-white">
        {slides.length > 0 ? (
          <TopSearch history={props.history} slides={slides} heading={""} />
        ) : (
          <div className="spinner">
            <Loader type="ThreeDots" color="#F5365F" height="100" width="100" />
          </div>
        )}
      </section>
      <section className="section pt-2 pb-2 mb-0 products-section bg-primary">
        <div className="p-2 container">
          <div className="section-header mb-0 text-center row">
            <h2 className="text-white col-12 mb-0 text-center">
            <Link to="/search"> 
            <Button className={"btn-locirajme btn btn-small ml-3 p-2 btn-location text-white "}>
                  <FontAwesomeIcon className={"fa-spin"} icon={faSearch} /> {t("Search")}
                </Button></Link>
                
                <Link to="/contact"> 
            <Button className={"btn-locirajme btn btn-small ml-3 p-2 btn-location text-white "}>
                  <FontAwesomeIcon className={"fa-spin"} icon={faEnvelope} /> {t("Contact us")}
                </Button></Link></h2>

                
               
          </div>
  
 
        </div>
      </section>
      
 
      <section className="section pt-2 pb-2 products-section bg-red">
        <div className="p-2 container">
          <div className="section-header ">
           
          </div>
          <div className="text-white text-center mb-3">
          <Button onClick={()=>setCountryCode("BA")} className={"btn-locirajme btn btn-small ml-3 p-2 btn-location text-white "}>
                  {t("🇧🇦 Bosnia")}
                </Button>  
                <Button onClick={()=>setCountryCode("AT")} className={"btn-locirajme btn btn-small ml-3 p-2 btn-location text-white "}>
                  {t("🇦🇹 Austria")}
                </Button>
                <Button onClick={()=>setCountryCode("SL")} className={"btn-locirajme btn btn-small ml-3 p-2 btn-location text-white "}>
                  {t("🇸🇮 Slovenia")}
                </Button>
          </div>

         { countryCode!="" && <div className="row locationDiv">
             
            {locations.map((location) => {
              const { city, total, longitude, latitude,country_code } = location;
              let rand = 1 + Math.random() * (4);
              if(temprand === rand.toFixed(0)){
                rand = 1 + Math.random() * (4);
                temprand=rand.toFixed(0)
              }else{
                temprand=rand.toFixed(0)
              }
              return (
                
               countryCode===country_code && <Button id={country_code} onClick={()=>setLocationLatLng(latitude,longitude)} className={"color-"+rand.toFixed(0)+" btn-location text-white border-secundary "}>
                  <FontAwesomeIcon icon={faMapPin} /> {city}
                </Button>
              );
            })}
          </div>}
        </div>
      </section>

      <section className="section pt-2 pb-2 products-section bg-gray">
        {slides.length > 0 ? (
          <></>
        ) : (
          <div className="spinner">
            <Loader type="ThreeDots" color="#F5365F" height="100" width="100" />
          </div>
        )}

        <Features
          heading={t("home.Featured")}
          items0={1}
          restaurants={restaurants}
          history={props.history}
        />
      </section>
      <section className="section pt-2 pb-2 allcatneer products-section bg-gray ">
        {categories.length > 0 ? (
          <></>
        ) : (
          <div className="spinner">
            {categories}
            <Loader type="ThreeDots" color="#F5365F" height="100" width="100" />
          </div>
        )}

        {categories ? (
          <Categories
            heading={t("home.Top Categories")}
            categories={categories}
            history={props.history}
          />
        ) : (
          ""
        )}
      </section>
      <section className="section pt-2 pb-2 products-section bg-white">
        {restaurants ? (
          <></>
        ) : (
          <div className="spinner">
            <Loader type="ThreeDots" color="#F5365F" height="100" width="100" />
          </div>
        )}

        <Features
          heading={t("home.Near you")}
          items0={1}
          items765={3}
          items1200={3}
          featuredC={true}
          neer={true}
          restaurants={restaurants}
          history={props.history}
        />
      </section>
      <section className="react-ripples-my section pt-2 pb-2 products-section bg-white">
        {promiseInProgress && (
          <div className="spinner">
            <Loader type="ThreeDots" color="#F5365F" height="100" width="100" />
          </div>
        )}

        <FeaturesList
          autoplay={false}
          items0={2}
          items765={4}
          items1200={4}
          heading={t("New")}
          featuredC={true}
          restaurants={latest}
          loop={true}
          latest={true}
          history={props.history}
        />
      </section>
      {authState.user ? (
        ""
      ) : (
        <section className="restaurant-detailed-banner">
          <Parallax
            blur={{ min: -51, max: -21 }}
            bgImage={"/img/gallery/bg.jpg"}
            bgImageAlt=""
            strength={-220}
          >
            <Container className="mt-5 mb-5 text-center text-white register">
              <SectionHeading
                className="mt-3"
                heading={t("Become iTable Member")}
                subHeading={t(
                  "Become iTable member and start experiencing the world of CAFES and RESTAURANTS in a different way"
                )}
              />

              <Row className="p-5">
                <Col sm={12} className="text-center">
                  <Link
                    to="register"
                    className="btn btn-outline-primary btn-lg"
                  >
                    {t("Create an Account")}{" "}
                    <FontAwesome icon="chevron-circle-right" />
                  </Link>
                </Col>
              </Row>
            </Container>
          </Parallax>
        </section>
      )}
    </>
  );
};

export default Index;
