import React, { useEffect, useState } from 'react';
import {Row,Col,Button,Spinner} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CardItem from '../common/CardItem';
import axios from '../../helpers/requests'
const Favourites: React.FC = (props: any) => {

	const {t} = useTranslation()
	const [favorites, setFavorites] = useState<any>([]);
	function getData() {
	  axios
		.get("/user/favorite")
		.then((res) => {
			setFavorites(res.data);
		  console.log(res.data);
		})
		.catch((err) => {
		  console.log(err);
		});
	}
	useEffect(() => {
	  getData();
	}, []);
    	return (
    		<>
    		    <div className='p-4 bg-white shadow-sm'>
	              <Row>
	                 <Col md={12}>
	                    <h4 className="font-weight-bold mt-0 mb-3">{t("myprofile.Favourites")}</h4>
	                 </Col>
					 {favorites &&
          favorites.map((fav) => {
            const { id,description,name,image,phone,email,web,tip} = fav;
			 
            return (
	                 <Col md={6} sm={6} className="mb-4 pb-2">
	                    <CardItem 
					   		title={name}
							   tip={tip}
							   phone={phone}
							   web={web}
							   email={email}
							subTitle={description}
						  	imageAlt={image}
						    image={image}
						    imageClass='img-fluid item-img'
						    linkUrl={'/shop/'+id}
						    offerText='65% off Coupon OSAHAN50'
						    offerColor='danger'
							time='15–25 min'
							price='$100 FOR TWO'
							showPromoted={true}
							promotedVariant='dark'
							favIcoIconColor='text-danger'
							rating='3.1 (300+)'
					   	/>
	                 </Col>)})}
	           
	          
	          
	            
	                 <Col md={12} className="text-center load-more">
	                    {favorites.length>0?"":(<Button variant="primary" type="button">
	                    	<Spinner animation="grow" size="sm" className='mr-1' />
	                        {t("Please wait...")}
	                    </Button> )} 
	                 </Col>
	              </Row>
			    </div>
		    </>
    	);
    
}
export default Favourites;