 import axios from '../helpers/requests'
function  chck(){
    let resp = ""

  
             console.log(resp)

             return resp    
  }

  export const myAccHelper = {
   chck
};
