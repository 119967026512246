import React, { useEffect, useState } from "react";
import { Card, CardImg, Col, Container, Row } from "react-bootstrap";
import { CardBody } from "reactstrap";
import SectionHeading from "../common/SectionHeading";
import Ripples from 'react-ripples';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBicycle, faCar, faInfoCircle, faWalking } from '@fortawesome/free-solid-svg-icons'
import OwlCarousel from 'react-owl-carousel3';
import PackBoxCounter from "../Utilities/pack-box-counter";
import { useTranslation } from "react-i18next";

/* import { useTranslation } from "react-i18next";
import { usePromiseTracker } from "react-promise-tracker";
 */

type ListProps = {
  history?: any,
  latest?: boolean,
  haveMini?: boolean,
  loop?: boolean,
  autoplay?: boolean,
  featuredC?: boolean,
  restaurants: any,
  curr?: string,
  heading: string,
  search?: boolean,
  items0?: number,
  items764?: number,
  items765?: number,
  items1200?: number

}

const FeaturesList: React.FC<ListProps> = ({ curr = "KM", search = false, history, featuredC = false, items0 = 1, haveMini = false, items764 = 2, items765 = 3, items1200 = 3, autoplay = false, restaurants, heading }) => {
  /* const { promiseInProgress } = usePromiseTracker();
  const { t } = useTranslation(); */
  const [latestl, setLatestl] = useState(false);
const {t} = useTranslation()
  const [options, setoptions] = useState({
    responsive: {
      0: {
        items: items0,
      },
      764: {
        items: items764,
      },
      765: {
        items: items765,
      },
      1200: {
        items: items1200,
      },
    },
    lazyLoad: true,
    loop: true,
    autoplay: autoplay,
    autoplaySpeed: 2000,
    dots: false,
    autoplayTimeout: 2000,
    nav: false,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    autoplayHoverPause: true,
  });


  return (
    <Container className="p-1 pb-8 container  pl-0 pr-0 ">

      {restaurants.length > 2 && (<SectionHeading haveMini={haveMini}
        heading={heading}

      />)}
      <Row className=" ml-1  p-0 pr-3 row">

        {restaurants.length > 0 && (restaurants.map((restaurant) => {
          const { id, name, featured, image, logo, distancefromuser } = restaurant;
          return (
            (featured === 1 || featuredC) && (
              <Col md={4} key={id} xs={12} className="mt-2  p-0 pr-2 futures-ad owl-theme">
                <Ripples className="d-block myblock" onClick={() => {
                  setTimeout(function () {
                    history.push(`/shop/${id}`);
                  }, 275)
                }}>
                  <Card className="my-featured shadow-sm p-0 m-0">

                    <img className="banner-img-front" src={image} alt="" />
                    <CardImg className="rounded-sm feture-logo" src={logo} alt="" />
                    <CardBody className="p-2 h-restaurant text-left w-100 ">

                      <h6 className="my-0 font-weight-bold shop-name">{name}</h6>

                      <div className="mt-1 d-flex align-items-center text-muted">
                        <small className=""><FontAwesomeIcon icon={distancefromuser.toFixed(2) < 10 ? faWalking : distancefromuser.toFixed(2) < 20 ? faBicycle : faCar} className="align-middle" /> {distancefromuser.toFixed(2)} km</small>

                      </div>

                    </CardBody>


                  </Card>
                </Ripples>
                <div className=" p-0 mb-1 col-md-12">

                  
                  {restaurant.dishes ? restaurant.dishes.filter((rest,idx) => idx < 5).map((dish) => {
                    
                    const { name } = dish;
                    return (
                      <Ripples
                        className="mt-1 d-block myblock w-100"
                        onClick={() => {
                          setTimeout(function () {
                             history.push(`/shop/${restaurant.id}#art-${dish.id}`);
                          }, 275);
                        }}
                      >

                        <div id={"art-"+dish.id} className="bg-white card p-0 order-list shadow-sm col-12">
                          <div className="gold-members p-2 bg-gray">
                            <div className="media">
                              <img src={dish.image} alt="" className="mr-4 w-25" />
                              <div className="media-body row">
                                <div className="col-12 p-0">
                                  <h6 className="mb-2">

                                    {dish.name}{" "}

                                  </h6>


                                  <p className="text-gray mb-1"> {dish.description}</p>
                                  <p className="text-primary pricetext mb-1"> {dish.price} {restaurant.currencie.code}</p>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </Ripples>)
                 }) : <></>}
                  {restaurant.dishes ? restaurant.dishes.length>5 && 
                    <Ripples
                    className="mt-1 d-block myblock w-100"
                    onClick={() => {
                      setTimeout(function () {
                         history.push(`/shop/${restaurant.id}`);
                      }, 275);
                    }}
                  >
                  <div className="bg-white card p-0 order-list shadow-sm col-12">
                         
                          <div className="gold-members p-2 bg-gray">
                            <div className="media">
                              
                              <div className="media-body pt-2 text-center">
                                <div className="col-12 p-0">
                                  <h6 className="mb-2">

                                    {t("Restaurant has ")}{t(" more dishes")} -&gt;


                                  </h6>


                                 
                                </div>

                              </div>
                            </div>
                          </div>
                        </div> </Ripples>:<></>}

                </div>
              </Col>
            ))
        })

        )}

      </Row>
    </Container>
  );




}
export default FeaturesList;