import axios from '../helpers/requests';
import { authConstants } from '../helpers/constants';
import { alertActions } from './alert'

export const update = (data, history) => async dispatch => {
    console.log(data)
    try {
        dispatch(alertActions.page_loader(true));
        const response = await axios.put<Object>('/user/update', { password: data.password })
        const datares = response
        console.log(response)

        /*   dispatch({ type: authConstants.VERIFY_SUCCESS, payload: auth_token });
          dispatch(alertActions.page_loader(false));
  
          history.replace('/') */
    } catch (e: any) {
        dispatch({ type: authConstants.VERIFY_FAILURE, payload: e.toString() });
        dispatch(alertActions.page_loader(false));
    }
};
export const updateAdmin = (data, id, history) => async dispatch => {
    console.log(data)
    try {
        dispatch(alertActions.page_loader(true));
        const response = await axios.put<Object>('/admin/user/update', { password: data.password, id: id })
        const datares = response
        console.log(response)

        /*   dispatch({ type: authConstants.VERIFY_SUCCESS, payload: auth_token });
          dispatch(alertActions.page_loader(false));
  
          history.replace('/') */
    } catch (e: any) {
        dispatch({ type: authConstants.VERIFY_FAILURE, payload: e.toString() });
        dispatch(alertActions.page_loader(false));
    }
};
function update_avatar(payload) {
    return { type: authConstants.UPDATE_AVATAR, payload: payload }
}

function like(idShop) {

    try {
        const response = axios.post<Object>('/like', { id: idShop })
        const datares = response
        console.log(response)
        return datares

        /*  history.replace('/')  */
    } catch (e: any) {
        return e
    }
}
export const addUser = (data, history) => async dispatch => {

    try {

        dispatch(alertActions.page_loader(true));
        if (data.get("role") === "3") {
            const response = await axios.post<Object>('/admin/owner/store', data)
            console.log(response)
        } else if (data.get("role") === "4") {
            const response = await axios.post<Object>('/admin/users/store', data)
            console.log(response)
        }


        dispatch(alertActions.page_loader(false));

        /*  history.replace('/')  */
    } catch (e: any) {
        dispatch({ type: authConstants.VERIFY_FAILURE, payload: e.toString() });
        dispatch(alertActions.page_loader(false));
    }
};
export const updateProfile = (data, history) => async dispatch => {

    try {
        dispatch(alertActions.page_loader(true));
        const response = await axios.post<Object>('/user/profile/image_update', data)
        const datares = response
        console.log(response)
        dispatch(update_avatar(response.data));

        dispatch(alertActions.page_loader(false));

        /*  history.replace('/')  */
    } catch (e: any) {
        dispatch({ type: authConstants.VERIFY_FAILURE, payload: e.toString() });
        dispatch(alertActions.page_loader(false));
    }
};
export const updateProfileAdmin = (data, history) => async dispatch => {

    try {
        dispatch(alertActions.page_loader(true));
        const response = await axios.post<Object>('/admin/user/profile/image_update', data)
        console.log(response)
        dispatch(update_avatar(response.data));

        dispatch(alertActions.page_loader(false));

        /*  history.replace('/')  */
    } catch (e: any) {
        dispatch({ type: authConstants.VERIFY_FAILURE, payload: e.toString() });
        dispatch(alertActions.page_loader(false));
    }
};
function logout() {
    return { type: authConstants.LOGOUT }
}

export default {
    update,
    addUser,
    updateProfile,
    updateProfileAdmin,
    updateAdmin,
    update_avatar,
    logout,
    like
};