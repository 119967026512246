import React, { useEffect, useState } from 'react';
 import { useTranslation } from 'react-i18next';
import { Button, Input } from 'reactstrap';
import axios from '../../helpers/requests'
import actions from '../../action/user'
import { connect } from 'react-redux';
interface MyProps {
  current: any,
  admin?:boolean,
  userId?:string
   
}
const PictureUploader: React.FC<MyProps> = (props: any) => {
   
  const [picture,setPicture]= useState("")
  const [src,setSrc]= useState("")
  const [saved,setSaved]= useState(false)
  const {t} = useTranslation()
  const hiddenFileInput = React.useRef<any>();

 function handlePictureSelected(event) {
    var picture = event.target.files[0];
    var src     = URL.createObjectURL(picture);

    setPicture( picture)
     setSrc( src)
       
  }
  const handleClick = event => {
    
    hiddenFileInput.current?.click();
    
  };
  function renderPreview() {
    if(src) {
      return (
        <>
        <img onClick={handleClick} className="mb-3 rounded-pill w-50 shadow-sm mt-1" src={src}/>
        <p><i>{t("Click on image to change")}</i></p>
        </>
       
      );
    } else {
      return (
        <>
        <img onClick={handleClick} className="mb-3 rounded-pill w-50 shadow-sm mt-1" src={props.current}/>
        <p><i>{t("Click on image to change")}</i></p>
        </>
      );
    }
  }

  const upload = async (e) => {
		e.preventDefault()

    var formData = new FormData();

    formData.append("image",picture);
    setSaved(true)
    if(!props.admin){
   props.updateProfile(formData, props.history)
  }else{
    formData.append("id",props.userId);
    props.updateProfileAdmin(formData ,props.history)

  }
  }
  
 useEffect(()=>{
  window.scrollTo(0, 0);

  
 })
 
  
    return (
      <div>
        <h5>{t("Profile Picture")}</h5>

        <input hidden
          type="file"  ref={hiddenFileInput}
          onChange={handlePictureSelected.bind(this)}
        />
        <br/>
        <div>
        {renderPreview()}
        {src && !saved &&  <p><i>{t("To save plase click Update")}</i></p>}
        </div>
        <hr/>
        {src && !saved && <Button
											
											className="btn  btn-outline-secundary w-50 btn-lg w-50 mt-1 text-white btn bg-white"
											color="warning"
											size="sm"
                      onClick={upload.bind(this)}
											type="submit"
										>
											{t("profile.Update")}
                          			</Button>	
                                }
      </div> 
    );
 
}

const mapStateToProps = (state) => {
  return { state: state.auth }
}

export default connect(mapStateToProps, actions)(PictureUploader);

 