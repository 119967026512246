import React from 'react';
import PropTypes from 'prop-types'; 
import { Parallax } from "react-parallax";
 

interface MyProps {
	 
	image?: string;
	title: string;
	subTitle?:string; 
	t?: any;
  }
const PageTitle:  React.FC<MyProps> = (props: any) => {

 
    	return (
	     !props.image ? <section className="breadcrumb-osahan pt-9 pb-5 bg-primary position-relative text-center">
	         <h1 className="text-white">{props.title}</h1>
	         {props.subTitle?
	         	<h6 className="text-white-50">{props.subTitle}</h6>
	         	:""
	         }
	      </section>:
		  <section className="breadcrumb-osahan pt-7   position-relative text-center">
	
		  <Parallax 
		  className="breadcrumb-osahan   bg-primary position-relative text-center imagePageDetails"
 		  blur={{ min: 1, max: 2 }}
		  bgImage={props.image}
		  bgImageAlt=""
		  strength={-280}
		><h1 className="mt-5 text-white">{props.title}</h1>
		{props.subTitle?
			<h6 className="text-white-50">{props.subTitle}</h6>
			:""
		}</Parallax>
		 </section>

    	);
    }
 

 

export default PageTitle;