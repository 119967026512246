import React from 'react';
import {Link} from 'react-router-dom';
import {Image,Badge} from 'react-bootstrap';
import PropTypes from 'prop-types'; 
import Icofont from 'react-icofont';
import { useTranslation } from 'react-i18next';
interface MyProps {
	title: string,
  imageAlt: string,
  image: string,
  tip?:string,
  phone?:string,
  web?:string,
  email?:string,
  imageClass: string,
  linkUrl: string,
  offerText: string,
  offerColor: string,
  subTitle: string,
  time: string,
  price: string,
  showPromoted: boolean,
  promotedVariant: string,
  favIcoIconColor: string,
  rating: string,
};
 	const CardItem: React.FC<MyProps> = (props: any) => {
 const {t} = useTranslation()
    	return (
    		<div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
               <div className="list-card-image">
               	  {props.rating ? (
	                  <div className="star position-absolute">
                  		{props.tip=="1"?(<Badge variant="success">
	                  		  {t("profile.Restauraunt")}
	                  	</Badge>):(<Badge variant="warning">
						  {t("profile.Caffe")}
	                  	</Badge>)}
	              	  </div>
	              	  )
	              	  :""
	              }
                  <div className={`favourite-heart position-absolute ${props.favIcoIconColor}`}>
                  	<Link to={props.linkUrl}>
                  		<Icofont icon='heart'/>
                  	</Link>
                  </div>
                  
                  <Link to={props.linkUrl}>
                  	<Image src={props.image} className={props.imageClass} alt={props.imageAlt} />
                  </Link>
               </div>
               <div className="p-3 position-relative">
                  <div className="list-card-body">
                     <h6 className="mb-1"><Link to={props.linkUrl} className="text-black">{props.title}</Link></h6>
                     {props.subTitle ? (
	                     <p className="text-gray mb-3">{props.subTitle}</p>
	                     )
	                     :''
	                 }
	                 {(props.time || props.price)? (
	                     <>
	                     	{props.time ? (
								 <p className="text-gray mb-3 time">
		                     	<span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
		                     		<Icofont icon='phone'/> {props.phone}
		                     	</span> 
								 </p>
		                     	)
		                     	:""
		                    }
							{props.price ? (
								 <p className="text-gray mb-3 time">
								<span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
		                     		<Icofont icon='envelope'/> {props.email}
		                     	</span>	 
								 </p>                   		)
	                     		:""
	                     	}
	                     </>
	                   	):''
	                 }
                  </div>
               
               </div>
            </div>
		);
	 
}


export default CardItem;