import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_DE } from "./translation/de";
import { TRANSLATIONS_EN } from "./translation/en";
import { TRANSLATIONS_SR } from "./translation/sr";
import { TRANSLATIONS_BA } from "./translation/ba";
import { TRANSLATIONS_SL } from "./translation/sl";
const lang = ["sr", "sl", "en", "de"];
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
    en: {
      translation: TRANSLATIONS_EN
    },ba: {
      translation: TRANSLATIONS_SR
    },
     sr: {
       translation: TRANSLATIONS_SR
     },
     de: {
       translation: TRANSLATIONS_DE
     },
     sl: {
       translation: TRANSLATIONS_SL
     }
   }
 });
 let s=""
 console.log(localStorage.getItem("i18nextLng"))
 if(!lang.includes(localStorage.getItem("i18nextLng"))){
   s="sr"
 }else
 {
   s=localStorage.getItem("i18nextLng")
 }
i18n.changeLanguage(s);