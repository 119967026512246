
import axios from 'axios';
export const apiConstants = {
    baseUrl:  'https://api.itable.app/api',
}

axios.defaults.headers.common = { 'Accept': 'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'*', 'Content-Type': 'application/json' }
axios.defaults.baseURL = apiConstants.baseUrl


axios.interceptors.request.use(function (config) {
    const storage = JSON.parse(localStorage.getItem('persist:storage') as any)

    let auth_token
    if (storage) {
        auth_token = JSON.parse(storage.auth).auth_token
    }    
      
   
    if (config.headers !== undefined) {
    config.headers['Authorization'] = 'Bearer ' + auth_token;
    }
    return config;

}, error => {
    if (error.response.status === 401) {
     //place your reentry code
    }
    return error;
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code range of 2xx
    return response.data;
}, function (error) {

    const originalRequest = error.config;
     if (error.response.status === 500 && originalRequest.url === `${apiConstants.baseUrl}/refresh`) {
        localStorage.removeItem('auth_token')
        window.location.href = '/login';
        return Promise.reject(error);
    }
   

    if (error.response.status === 401 && originalRequest.headers.Authorization === "Bearer undefined") {

        originalRequest._retry = true;

         
       
            let auth_token =localStorage.getItem('auth_token')
        
        error.config.headers['Authorization'] = 'Bearer ' + auth_token;
        return Promise.reject(error);

    } else if (error.response.status === 401 && !originalRequest._retry) {

        originalRequest._retry = true;
        
        window.location.href = '/';

       const access_token = localStorage.getItem('access_token')
          const tokens = {
             tokens: access_token,
          }
          return axios.post<any>('/refresh', tokens)
              .then(response => {
                 localStorage.setItem('access_token', response.data.access_token)
                 axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`
               error.hasRefreshedToken = true;
             // return instance.request(error.config);
             return Promise.reject(error);
            })
    } else {
        if (error.response.status === 401 ) {
            console.log(error)
            console.log(error.response.status, error.response.data.errors)
                 
            return Promise.reject(error);
        }
    }
  
});


export default axios;
