import React, {  useState,useEffect } from "react";
type ListProps = {
    history?: any,
 
  }
   
 const Checker: React.FC<ListProps> = ({ history }) => {
    useEffect(() => {
       var s=localStorage.getItem("user")
       if(s){
           let a= JSON.parse(s)
            if(!a.phone_verified){
                console.log(a.phone_verified)
                history.push('/auth/verify')
            }
       }
        
    })
    return (<></>)
}

export default Checker;
