import { faLock, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';

import {Modal,Button} from 'react-bootstrap';
interface MyProps {
    show: boolean;
    reload?: boolean;
    onHide?: any;
    btn_text?: string;
    text:string,
	image:string,
    location?:any,
    t?:any
}

const LanguageModal: React.FC<MyProps> = (props: any) => {
    const [pressed,setPressed]=useState("/img/icons/common/bell.gif")
    const [image,setImage]=useState(props.image)
   

    const saveData=()=>
	{

		setImage("/img/icons/common/succes.gif")
	setTimeout(() => {  if(!props.reload){props.onHide()}else{window.location.reload()} ;setImage(props.image) }, 1500); 
    
   }
   useEffect(() => {
    setImage(props.image)
    if(props.btn_text){
    
    }
}, [props.show]);
    	return (
	        <Modal 
			backdrop={"static"}
	        	show={props.show} 
	         className="info-modal"
		        size="lg"
		        centered
		   	  >
			   
			  <Modal.Body>
                <div className=" text-center p-2">
			 <img   className="radius-primary w-50" src={image}/>
             <h5  className="mt-4 mb-4 w-100 text-center text-default">{props.text}</h5>
             <div  className="text-center react-ripples d-block myblock bg-primary radius-primary border border-primary shadow">
                <div className="card">
                  <div className="col-12  lngbtnqr col bg-white text-primary col">
                      <Button className={"btn  btn bg-white border-0 text-white "+ (localStorage.getItem("i18nextLng")==="sr"?"active":"")}><span className="lngqr" onClick={() => { props.t.changeLanguage("sr");saveData() }}>🇧🇦</span></Button>
                      <Button className={"btn  btn bg-white border-0 text-white "+ (localStorage.getItem("i18nextLng")==="en"?"active":"")}><span className="lngqr" onClick={() => { props.t.changeLanguage("en");saveData()  }}>🇺🇸</span></Button>
                      <Button className={"btn  btn bg-white border-0 text-white "+ (localStorage.getItem("i18nextLng")==="de"?"active":"")}><span className="lngqr" onClick={() => { props.t.changeLanguage("de");saveData()  }}>🇩🇪</span></Button>
                      <Button className={"btn  btn bg-white border-0 text-white "+ (localStorage.getItem("i18nextLng")==="sl"?"active":"")}><span className="lngqr" onClick={() => { props.t.changeLanguage("sl") ;saveData() }}>🇸🇮</span></Button>
                      </div>
                    </div>
	 

                        </div>      
				    
               </div>
           
			  </Modal.Body>

			  
			</Modal>
    	);
     
}
export default LanguageModal;