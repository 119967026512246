import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import i18n from "i18next";
import axios from "../../helpers/requests";

import { Navbar, Nav, Container, NavDropdown, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCrosshairs,
  faHome,
  faMapMarked,
  faQrcode,
  faSignInAlt,
  faSkullCrossbones,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import {
  RootStateOrAny,
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from "react-redux";
import QR from "../QR";
import Select from "react-select";
export const useSelector: TypedUseSelectorHook<RootStateOrAny> =
  useReduxSelector;

const Header: React.FC = (props: any) => {
  const colourOptions = [
    { value: "blue", label: "Blue", color: "#0052CC" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
  ];

  const flavourOptions = [
    { value: "vanilla", label: "Vanilla", rating: "safe" },
    { value: "chocolate", label: "Chocolate", rating: "good" },
  ];

  const groupedOptions = [
    {
      label: "Colours",
      options: colourOptions,
    },
    {
      label: "Flavours",
      options: flavourOptions,
    },
  ];
  let { t } = useTranslation();
  const history = useHistory();
  const [user, setUser] = useState([]);
  const [role, setRole] = useState("");
  const [lng, setLngu] = useState("🇺🇸");
  const [qr, setQr] = useState("");

  const authState = useSelector((state: RootStateOrAny) => state.auth);

  function setLng(s) {
    i18n.changeLanguage(s);
    checkFlag();
  }
  function checkFlag() {
    if (localStorage.getItem("i18nextLng") === "ba") {
      setLngu("🇧🇦");
    } else if (localStorage.getItem("i18nextLng") === "sr") {
      setLngu("🇷🇸");
    } else if (localStorage.getItem("i18nextLng") === "en") {
      setLngu("🇺🇸");
    } else if (localStorage.getItem("i18nextLng") === "de") {
      setLngu("🇦🇹");
    } else if (localStorage.getItem("i18nextLng") === "sl") {
      setLngu("🇸🇮");
    }
  }
  async function setUserGlobal(user) {
    setUser(user);
  }
  function checkRole() {
    axios
      .get<Object>("/user/chck")
      .then((res) => {
        setRole(res.data["role"].toString());
      })
      .catch((err) => {
        return;
      });
  }
  useEffect(() => {
    setUserGlobal(authState.user);

    checkRole();
    checkFlag();
    let qrc = localStorage.getItem("qr");
    if (qrc) {
      setQr(qrc);
    }
  }, []);

  return (
    <Navbar
      color="light"
      expand="lg"
      className="navbar navbar-expand navbar-light osahan-nav fixed-top bg-transparent osahan-nav-bgs"
    >
      
      <Container>
        <Navbar.Brand>
          {window.location.pathname === "/" && (
            <Nav.Link
              eventKey={0}
              as={NavLink}
              exact
              onClick={() => {
                history.push("/");
              }}
              to="/"
            >
              <Image src="/img/logo.png" alt="" />{" "}
            </Nav.Link>
          )}
        </Navbar.Brand>
{/*         <Select  className="myPlace" options={groupedOptions} />
 */}        {window.location.pathname !== "/" && (
          <Navbar.Text className="navleft">
            <Nav.Link eventKey={3} as={NavLink} exact to="/">
              <FontAwesomeIcon icon={faHome} />{" "}
              <span className="sr-only">(current)</span>
            </Nav.Link>
          </Navbar.Text>
        )}
        {qr
          ? window.location.pathname !== "/" && (
              <Navbar.Text className="navleft">
                <Nav.Link
                  eventKey={3}
                  as={NavLink}
                  exact
                  to={"/qr/" + qr + "?r=1"}
                >
                  <FontAwesomeIcon icon={faQrcode} />{" "}
                  <span className="sr-only">(current){qr}</span>
                </Nav.Link>
              </Navbar.Text>
            )
          : window.location.pathname !== "/" && (
              <Navbar.Text className="navleft">
                <Nav.Link
                  onClick={() => {
                    history.goBack();
                  }}
                  eventKey={2}
                  as={NavLink}
                  exact
                  to="#"
                >
                  <FontAwesomeIcon icon={faArrowLeft} />{" "}
                  <span className="sr-only">(current)</span>
                </Nav.Link>
              </Navbar.Text>
            )}
        <Navbar.Toggle />
		 
        <Navbar.Collapse id="navbarNavDropdown">
          <Nav activeKey={0} className="ml-auto">
 
            {/* <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/">
			               Home <span className="sr-only">(current)</span>
			            </Nav.Link>
			            <Nav.Link eventKey={1} as={NavLink} activeclassname="active" to="/offers">
             				<Icofont icon='sale-discount'/> Offers <Badge variant="danger">New</Badge>
			            </Nav.Link>
			            <NavDropdown title="Restaurants" alignRight className="border-0">
			            	<NavDropdown.Item eventKey={2.1} as={NavLink} activeclassname="active" to="/listing">Listing</NavDropdown.Item>
			            	<NavDropdown.Item eventKey={2.2} as={NavLink} activeclassname="active" to="/detail">Detail + Cart</NavDropdown.Item>
			            	<NavDropdown.Item eventKey={2.3} as={NavLink} activeclassname="active" to="/checkout">Checkout</NavDropdown.Item>
			            </NavDropdown>*/}

            {authState.user && (
              <Nav.Link
                eventKey={1}
                as={NavLink}
                exact
                to={
                  role === "Customer"
                    ? "/profile/orders"
                    : role === "Admin"
                    ? "/profile/admin/dashboard"
                    : role === "Owner"
                    ? "/profile/admin/dashboard"
                    : ""
                }
              >
                <FontAwesomeIcon icon={faUser} /> {t("profile.Profile")}{" "}
                <span className="sr-only">(current)</span>
              </Nav.Link>
            )}
            {authState.user ? (
              ""
            ) : (
              <Nav.Link eventKey={2} as={NavLink} exact to="/login">
                <FontAwesomeIcon icon={faSignInAlt} /> {t("login.Sign in")}{" "}
                <span className="sr-only">(current)</span>
              </Nav.Link>
            )}

            {/* <NavDropdown alignRight
			            	title={
			            		<DropDownTitle 
			            			className='d-inline-block' 
			            			image="img/user/4.png"
			            			imageAlt='user'
			            			imageClass="nav-osahan-pic rounded-pill"
			            			title='My Account'
			            		/>
			            	}
			            >
							<NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/myaccount/orders"><Icofont icon='food-cart'/> Orders</NavDropdown.Item>
							<NavDropdown.Item eventKey={4.2} as={NavLink} activeclassname="active" to="/myaccount/offers"><Icofont icon='sale-discount'/> Offers</NavDropdown.Item>
							<NavDropdown.Item eventKey={4.3} as={NavLink} activeclassname="active" to="/myaccount/favourites"><Icofont icon='heart'/> Favourites</NavDropdown.Item>
							<NavDropdown.Item eventKey={4.4} as={NavLink} activeclassname="active" to="/myaccount/payments"><Icofont icon='credit-card'/> Payments</NavDropdown.Item>
							<NavDropdown.Item eventKey={4.5} as={NavLink} activeclassname="active" to="/myaccount/addresses"><Icofont icon='location-pin'/> Addresses</NavDropdown.Item>
			            </NavDropdown> */}
            {/*    <NavDropdown activeclassname="active" alignRight className="dropdown-cart" 
			            	title={
			            		<DropDownTitle 
			            			className='d-inline-block' 
			            			faIcon='shopping-basket'
			            			iconClass='mr-1'
			            			title='Cart'
			            			badgeClass='ml-1'
			            			badgeVariant='success'
			            			badgeValue={5}
			            		/>
			            	}
			            >

			                <div className="dropdown-cart-top shadow-sm">
			               	  {
			               	  	<CartDropdownHeader 
			               	  		className="dropdown-cart-top-header p-4" 
			               	  		title="Gus's World Famous Chicken"
			               	  		subTitle="310 S Front St, Memphis, USA"
			               	  		image="img/cart.jpg"
			               	  		imageClass="img-fluid mr-3"
			               	  		imageAlt="osahan"
			               	  		NavLinkUrl="#"
			               	  		NavLinkText="View Full Menu"
			               	    />
			               	  } 
			                  <div className="dropdown-cart-top-body border-top p-4">
			                     <CartDropdownItem 
			                     	icoIcon='ui-press'
			                     	iconClass='text-success food-item'
			                     	title='Corn & Peas Salad x 1'
			                     	price='$209'
			                     />

			                     <CartDropdownItem 
			                     	icoIcon='ui-press'
			                     	iconClass='text-success food-item'
			                     	title='Veg Seekh Sub 6" (15 cm) x 1'
			                     	price='$133'
			                     />

			                     <CartDropdownItem 
			                     	icoIcon='ui-press'
			                     	iconClass='text-danger food-item'
			                     	title='Chicken Tikka Sub 12" (30 cm) x 1'
			                     	price='$314'
			                     />

			                     <CartDropdownItem 
			                     	icoIcon='ui-press'
			                     	iconClass='text-success food-item'
			                     	title='Corn & Peas Salad x 1 '
			                     	price='$209'
			                     />
			                  </div>
			                  <div className="dropdown-cart-top-footer border-top p-4">
			                     <p className="mb-0 font-weight-bold text-secondary">Sub Total <span className="float-right text-dark">$499</span></p>
			                     <small className="text-info">Extra charges may apply</small>  
			                  </div>
			                  <div className="dropdown-cart-top-footer border-top p-2">
			                     <NavDropdown.Item eventKey={5.1} as={Link} className="btn btn-success btn-block py-3 text-white text-center dropdown-item" to="/checkout"> Checkout</NavDropdown.Item>
			                  </div>
			                </div>
			            </NavDropdown> */}
          </Nav>
          <NavDropdown id="" title={lng} className="border-2 langm">
            <NavDropdown.Item eventKey={2.1}>
              <span
                onClick={() => {
                  setLng("sr");
                }}
              >
                🇷🇸
              </span>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey={2.1}>
              <span
                onClick={() => {
                  setLng("ba");
                }}
              >
                🇧🇦
              </span>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey={2.2}>
              <span
                onClick={() => {
                  setLng("en");
                }}
              >
                🇺🇸
              </span>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey={2.2}>
              <span
                onClick={() => {
                  setLng("de");
                }}
              >
                🇦🇹
              </span>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey={2.2}>
              <span
                onClick={() => {
                  setLng("sl");
                }}
              >
                🇸🇮
              </span>
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Container>
      
    </Navbar>
  );
};

export default Header;
