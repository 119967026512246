import React, { useEffect, useState } from "react";
// reactstrap components
import { Col, Row } from "reactstrap";
import { useDispatch, useStore } from "react-redux";
import actions from './../action/cart'
import { useTranslation } from "react-i18next";

interface SubBillProps {
	currency_symbol?: string,
	sub_total?: any,
	restaurant_charge?: number,
	delivery_charge?: number,
	taxes?: number,
	charges?: any,
	coupon?: any
}

const CartBillings: React.FC<SubBillProps> = ({ currency_symbol, sub_total, charges, coupon }) => {

	const dispatch = useDispatch();
	const show=useState(false);
    const {t} = useTranslation();
	function subTotal() {
/* 		const subTotal = (parseInt(sub_total) + parseInt(charges.restaurant_charges) + parseInt(charges.delivery_charges))
 */		
const subTotal = (sub_total)
return subTotal
	}

	function tax() {
		const tax =  (subTotal() - coupons()) * (parseInt(charges.taxes) / 100)
	 
		return   (tax * 100) / 100
	}

	function coupons() {
		let sub_total = subTotal()

		if (coupon.id) {
			if (coupon.discount_type === 'PERCENTAGE')
				sub_total = sub_total * Math.round((coupon.discount) / 100)
			else if (coupon.discount_type === 'FIXED')
				sub_total = coupon.discount

			return  (sub_total * 100) / 100
		}
		return 0
	}

	const grand_total = () => {
		let coupon = coupons()
		let sub_total = subTotal() - coupon

		const total = tax() + sub_total

		return  (total).toFixed(2)
	}

	const Taxes = () => <>{tax()}</>
	const Coupon = () => <>{coupons()}</>
	const GrandTotal = () => <>{grand_total()}</>



	useEffect(() => {
		dispatch(actions.grand_total(grand_total()))
	}, [coupon])

	return (
		<>
		{/* 	<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
				<Col className="p-0">
					<h6 className="my-0 font-weight-light item-name">Item Sub-Total </h6>
					<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

					</div>
				</Col>
				<Col xs="3" className="p-0">
					<span className="text-dark text-right font-weight-light d-block">{sub_total} {currency_symbol}</span>
				</Col>
			</Row> */}
			{
				(charges.restaurant_charges != null && parseInt(charges.restaurant_charges) !== 0 && !show) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light item-name">Restaurant Packaging Fee</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="3" className="p-0">
							<span className="text-dark text-right font-weight-light d-block">{currency_symbol}{charges.restaurant_charges}</span>
						</Col>
					</Row>
				)
			}
			{
				(charges.delivery_charges != null && parseInt(charges.delivery_charges) !== 0  && !show) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light item-name">Delivery Fee</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="3" className="p-0">
							<span className="text-dark text-right font-weight-light d-block">{currency_symbol}{charges.delivery_charges }</span>
						</Col>
					</Row>
				)
			}
			{
				(coupon.discount  && !show) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light text-success item-name">Coupon Disount</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="3" className="p-0">
							<span className="text-success text-right font-weight-light d-block">(-) {currency_symbol}<Coupon /></span>
						</Col>
					</Row>
				)
			}
			{
				(charges.taxes != null && parseInt(charges.taxes) !== 0  && !show) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light item-name">Taxes and Charges ({charges.taxes}%)</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="3" className="p-0">
							<span className="text-dark text-right font-weight-light d-block">{currency_symbol}<Taxes /></span>
						</Col>
					</Row>
				)
			}
			<Row className="flex-row flex-wrap shadow-0 p-3 mx-0 row border-bottom">
				<Col className="p-0">
					<h5 className="my-0 totalsum font-weight-bold">{t("Grand Total")}</h5>
					<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

					</div>
				</Col>
				<Col xs="6" className="p-0">
					<h5 className="text-dark totalsum text-right font-weight-bold pr-0"><GrandTotal /> {currency_symbol}</h5>
				</Col>
			</Row>
		</>
	)
};

export default CartBillings;