import React, { useState } from "react";
import { Row, Col, Container, Form, Button, InputGroup } from "react-bootstrap";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledAlert,
} from "reactstrap";
import actions from "../action/auth";
import axios from "../helpers/requests";
import { alertActions } from "../action/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Verify: React.FC = (props: any) => {
  const [code, setCode] = useState("");

  const auth = useSelector((state: RootStateOrAny) => state.auth);
  const errorMsg = useSelector((state: RootStateOrAny) => state.auth.errorMsg);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(auth.user);
    const phone = auth.user.phone;
    const email = auth.user.email;
    console.log(phone, email);

    dispatch(actions.verify({ code, phone, email }, props.history));
  };

  const resendOTP = async (e) => {
    try {
      const phone = auth.user.phone;
      dispatch(alertActions.page_loader(true));
      await axios.post("/resend_otp", { phone_number: phone });
      dispatch(alertActions.page_loader(false));
    } catch (e) {
      dispatch(alertActions.page_loader(false));
    }
  };

  return (
    <Container fluid className="bg-white">
      <Row>
        <Col md={4} lg={6} className="d-none d-md-flex bg-image"></Col>
        <Col md={8} lg={6}>
          <div className="login d-flex align-items-center py-5">
            <Container>
              <Row>
                <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                  <h3 className="login-heading mb-4">{t("Verify Phone!")}</h3>
                  <Form role="form" onSubmit={onSubmit}>
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FontAwesomeIcon
                              icon={faPhoneSquare}
                              className="ni ni-hat-3"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Code"
                          type="number"
                          name="email"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          required
                        />
                      </InputGroup>
                    </FormGroup>

                    {props.state.errorMsg && (
                      <UncontrolledAlert color="warning">
                        <strong>{props.state.errorMsg}</strong>
                      </UncontrolledAlert>
                    )}
                    <div className="text-center">
                      <Button
                        className="btn bg-white btn-outline-secundary w-50 btn-lg w-50 mt-1 text-white"
                        type="submit"
                      >
                        {t("login.Sign in to start your session")}
                      </Button>
                    </div>
                  </Form>{" "}
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return { state: state.auth };
};

export default connect(mapStateToProps, actions)(Verify);
