import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Image, Card, Button } from "react-bootstrap";
import axios from "../helpers/requests";
import QrCard from "./common/QrCard";
import Geolocation from "react-native-geolocation-service";
import Loader from "react-loader-spinner";
import Icofont from "react-icofont";
import i18n from "i18next";


import { usePromiseTracker } from "react-promise-tracker";
import { faBusinessTime, faClock, faEnvelope, faMapMarkedAlt, faPhone, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Ripples from "react-ripples";
import { Parallax } from "react-parallax";
import WiFiModal from "./modals/WiFiModal";
import KonobarModal from "./modals/KonobarModal";
import Infomodal from "./modals/Infomodal";
import { createPublicKey } from "crypto";
import { isSetAccessorDeclaration } from "typescript";
import LanguageModal from "./modals/LanguageModal";

const QR: React.FC = (props: any) => {
  const [state, setState] = useState({
    showAddressModal: false,
    showDeleteModal: false,
  });
  let { t } = useTranslation();


  const [shop, setShop] = useState({});
  const [alertImage, setAlertImage] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertTextBig, setAlertTextBig] = useState("");
  const [alertBtnText, setAlertBtnText] = useState("");
  const [alertReload, setAlertReload] = useState(false);
  
  const [wifiModal, showWifiModal] = useState(false);
  const [languageModal, showLanguageModal] = useState(false);
  const [konobarModal, showKonobarModal] = useState(false);
  const [infoModal, showInfoModal] = useState(false);


  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [qr, setQr] = useState("");
  const { promiseInProgress } = usePromiseTracker();

  function getDataQr(qr, position) {
    axios
      .get<any>("/restaurant/qr", {
        params: {
          code: qr,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      })
      .then((res) => {
        if (res["data"] === "to_fare") {
          props.history.push("/tofar");
        } else {
          setShop(res.data);
          console.log(res['data']['table'])
          if(res['data']['table'])
          {
         
          const query = new URLSearchParams(props.location.search);
          if(query.get('r')){
            
          }else{
          localStorage.setItem("qr",qr)
          localStorage.setItem("time",Math.floor(Date.now() / 1000).toString())
          }
        }else{
          setAlertImage("img/icons/common/disabled.gif")
          setAlertReload(true)
          setAlertText(t("This table is disabled by Owner."))
          showInfoModal(true)
        }
      }
      })
      .catch(function (error) {
        if (error) {
          props.history.push("/norest");
        }
      });
  }
  
  const _watchId = () =>
    Geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        setLocation({ lat: latitude, lng: longitude });
      },
      (error) => {
        props.history.push("/nogps");

        console.log(error);
      },
      {
        enableHighAccuracy: true,
        distanceFilter: 0,
        interval: 5000,
        fastestInterval: 2000,
      }
    );
    const setAction = (i,dis)=>{
     
      if(dis ===0){
        setAlertImage("img/icons/common/disabled.gif")
        setAlertText(t("Action is disabled."))
        setAlertTextBig(t(""))
        showInfoModal(true)
      }else{
      let time= localStorage.getItem("time")? localStorage.getItem("time") : "0"

      if(time){
      let totime=parseInt(time)
      
      if((Date.now() / 1000)-totime > 300 && i!=5){
       // localStorage.removeItem("time")
       // localStorage.removeItem("qr")
        setAlertImage("img/icons/common/time.gif")
        setAlertText(t("Time for action is limited to 5min. Please scann iTable code again."))
        setAlertTextBig("")
        showInfoModal(true)
       // props.history.push("/")
      }else{
        switch(i){
          case 1:
            console.log("meni")
            props.history.replace("/shop/"+shop['id'])
            break
          case 2:
            showKonobarModal(true)
            console.log("konobar")
            break
          case 3:
            let summ=0
            getDataQr(props.match.params.qr, {coords:{latitude:location.lat,longitude:location.lng}})
            shop['orders'].forEach(element => {
              summ += parseFloat(element.total)
            });
            
            setAlertTextBig(summ+" "+shop['currencie']['code'])
            setAlertImage("img/icons/common/state.gif")
            setAlertBtnText(t("Close"))
            
            setAlertText(t("Current state for this table is:"))
            showInfoModal(true)
            console.log("stanje")
            break
          case 4:
              console.log("racun")
              break
          case 5:
            showLanguageModal(true)
              console.log("language")
              break

          

        }
      }
     }
    }
   }
   const setIModal=(a)=>{
    console.log("modal")
    setAlertImage("img/icons/common/time.gif")
    setAlertTextBig(t(""))

    showInfoModal(false)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
   
    if (window.location.href.indexOf("qr") > -1) {
      if (props.match.params.qr !== 0) {
        Geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({lat:position.coords.latitude,lng:position.coords.longitude})
            getDataQr(props.match.params.qr, position);
           },
            (error) => {
              props.history.push("/nogps");
      
             
            },{ enableHighAccuracy: false, timeout: 8000, maximumAge: 30000 }
             
        );
  
        setQr(props.match.params.qr);
        //_watchId();
      }
    }

   
    return () => {
      if (_watchId) {
        Geolocation.clearWatch(_watchId());
      }
    };
  }, [props.match.params.qr]);
  return shop["name"] ? (
    <>       <WiFiModal show={wifiModal} text={shop["wifi"]} onHide={()=>showWifiModal(false)} t={t} />
   <KonobarModal show={konobarModal} id_rest={shop['id']} qr_code={qr} onHide={()=>showKonobarModal(false)} t={t} />
   <Infomodal textBig={alertTextBig} reload={alertReload} show={infoModal} btn_text={alertBtnText} text={alertText} image={alertImage} onHide={()=>{setIModal(false)}} t={t} />
    <LanguageModal reload={false} show={languageModal}   text={t("To change language, please click on flag.")} image={"/img/icons/common/language.gif"} onHide={()=>{showLanguageModal(false)}} t={i18n} />
    <section className="section qrscan osahan-not-found-page">
      <Container>

      <div className="row row-cols-md-12">

      <Row md={12} className="col-md-12 p-0 ml-2 m-0 mt-3 mr-2 addresses-item  bg-primary border border-primary shadow  radius-primary row row-cols-md-12">
                        <Col className="col-5 p-0 bg-primary imagecontainer">
                          
                            <img className="banner-img " src={shop["image"]} />
                            <img className="logo-shopqr " src={shop['logo']}/>
                            
                           
                        </Col>

                        <Col className="col-7 ">
                          <div className="media-body w-100">

                            <h5 className="mt-2 mb-2 text-center qrshopname text-white">
                              {shop["name"]}
                            </h5>
                            <div className="shopdetailsqr leftqr text-white ">
                              <h6><FontAwesomeIcon icon={faClock}/></h6>
                              <p>Radni dan <span className="detailsqr pl-3">{shop["openWorking"]} - {shop["closeWorking"]}</span></p>
                              <p>Vikendom <span className="detailsqr pl-3">{shop["openWeekend"]} - {shop["closeWeekend"]}</span></p>
                              </div>
                              <div className="shopdetailsqr rightqr text-white ">
                              <h6> </h6>
                              <p><FontAwesomeIcon icon={faPhone}/><span className="detailsqr pl-3">{shop["phone"]} </span></p>
                              <p><FontAwesomeIcon icon={faEnvelope}/><span className="detailsqr pl-3">{shop["email"]} </span></p>
                               </div>
                          </div>
                        </Col>
                      </Row>
                      </div>
                     
        <Row md={12}>
        <Col md={3} sm={12}>
						   <h6 className="mb-2 mt-4 text-center"> {t("Download app")}</h6>
                <p className=" mb- text-center">{t("For full expirience please download iTable aplication")}</p>
			                   <div className="app">
			                     
			                     <a href="https://play.google.com/store/apps/details?id=org.itable.app&hl=en_US&gl=US">
			                     	<Image src="img/google.png" alt='' className="p-1 img-fluid w-50" fluid />
			                     </a>
			                     <a href="https://apps.apple.com/us/app/itable-app/id1595596660">
			                     	<Image src="img/apple.png" alt='' className="p-1 img-fluid w-50"  fluid />
			                     </a>
			                  </div>
			               </Col>
          </Row>
          <Row md={12}>

          <Col md={6}   className="mt-2 pt-2 nodesk col-6  pl-2 pr-1">
            <QrCard
              boxClass="border border-primary shadow"
              
              title={t("Meni")}
              icoIcon="fork-and-knife"
              iconclassName="icofont-2x"
            
              onEditClick={() =>
                setAction(1,"1")
              }
              onDeleteClick={() =>
                setState({ showAddressModal: false, showDeleteModal: true })
              }
            />
          </Col>
          <Col md={6} className="mt-2 pt-2 nodesk  pl-1 pr-2  col-6">
            <QrCard
              boxClass="border border-primary shadow"
              title={t("Konobar")}
              disabled={shop["waiter"] === 0 ? "disabled" : "disabled"}
              icoIcon="waiter-alt"
              iconclassName="icofont-2x"
              onEditClick={() =>
                setAction(2,shop["waiter"])
              }
              onDeleteClick={() =>
                setState({ showAddressModal: false, showDeleteModal: true })
              }
            />
          </Col>
          <Col md={6} className="nodesk  col-6  pl-2 pr-1">
            <QrCard
              boxClass="border border-primary shadow"
              title={t("Stanje")}
              disabled={shop["tablestatus"] === 0 ? "disabled" : ""}
              icoIcon="info-circle"
              iconclassName="icofont-2x"
              onEditClick={() =>
                setAction(3,shop["tablestatus"])
              }
              onDeleteClick={() =>
                setState({ showAddressModal: false, showDeleteModal: true })
              }
            />
          </Col>
          <Col md={6} className="nodesk pl-1 pr-2  col-6">
            <QrCard
              boxClass="border border-primary shadow"
              title={t("Bill")}
              disabled={shop["billtotable"] === 0 ? "disabled" : "disabled"}
              icoIcon="coins"
              iconclassName="icofont-2x"
              onEditClick={() =>
                setAction(4,shop["billtotable"])
              }
              onDeleteClick={() =>
                setState({ showAddressModal: false, showDeleteModal: true })
              }
            />
          </Col>
        
          
            <Col md={6} className="nodesk pl-2 pr-1  col-6">
              <QrCard
                boxClass="border border-primary shadow"
                title="WiFi"
                icoIcon="wifi"
                iconclassName="icofont-2x"
                onEditClick={()=>showWifiModal(true)}
                
              />
            </Col>
            <Col md={6} className="nodesk pl-1 pr-2  col-6">
              <QrCard
                boxClass="border border-primary shadow"
                title={t("Language")}
                icoIcon="speech-comments"
                iconclassName="icofont-2x"
                onEditClick={() =>
                  setAction(5,1)
                }
                onDeleteClick={() =>
                  setState({ showAddressModal: false, showDeleteModal: true })
                }
                
              />
            </Col>
            
          
          <Col md={12}>
            <Col md={12} className="text-center pt-5 pb-5">
              <Link to="/">
                <div className="text-center">
                  <Image
                    className="w-50 logoqrpage align-center mb-5"
                    src="/img/logo.png"
                    alt=""
                  />{" "}
                </div>
              </Link>
            </Col>
          </Col>
        </Row>
      </Container>

    </section>
    </>
  ) : (
    <div className="spinner">
      <Loader type="ThreeDots" color="#F5365F" height="100" width="100" />
    </div>
    
  );
};

export default QR;
