  import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import Ripples from 'react-ripples';
import { Button, Col, Container, Input, Row } from 'reactstrap';
import CartBillings from './../../mcards/cart-billing';
import CartItems from './../../mcards/cart-items';
import axios from '../../helpers/requests';

import actions from '../../action/cart'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignRight, faArrowRight, faForward, faIdCard, faLongArrowAltLeft, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Cart: React.FC = (props: any) => {

	const [data, setData] = useState({
		details: {},
		charges: {},
		address: ''
	} as any);
	const [load, setLoad] = useState(false);
	const [coupon, setCoupon] = useState({
		code: '',
		request: false,
		returnData: false
	});

	//Selectors
	const getDishState = store => store.cart.dishes

	// Get index
	const getAllDishes = (store) => {
		const dishes = getDishState(store) ?? []
		let total = 0.00
		dishes.map((dish) => {
			let addons_cost = 0
			dish.addons_dish.map(d =>
				d.addons.map(d => addons_cost +=parseFloat(d.price))
			)
 			return total += parseFloat(dish.count) * parseFloat(dish.price) + addons_cost
		})
		return { count: dishes.length, total }
	}

	//Redux Hooks
	const basket_restaurant_id = useSelector((state: RootStateOrAny) => state.cart.restaurant_id);
	const couponStore = useSelector((state: RootStateOrAny) => state.cart.coupon);
	const sub_total = useSelector((state: RootStateOrAny) => getAllDishes(state));
	const default_address = useSelector((state: RootStateOrAny) => state.auth.default_address);
	const currency_symbol = useSelector((state: RootStateOrAny) => state.auth.currency_symbol);
    const {t} = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (basket_restaurant_id === '' || basket_restaurant_id === undefined)
			return

		axios.get<any>(`/cart/${basket_restaurant_id}?t=${new Date().getTime()}`)
			.then(res => {
				const data = res.data
				setData({
					details: data.restaurant_details,
					charges: data.charges,
					address: data.restaurant_details.addresses[0]
				})
				setLoad(true);
			})
			.catch(err => {
				console.log(err)
			})
	}, [basket_restaurant_id]);


	const couponSubmit = () => {

		if (coupon.code.trim() === '')
			return

		axios.post<any>(`/coupon/verify`, { coupon_code: coupon.code })
			.then(res => {
				const data = res.data
				setCoupon({ ...coupon, request: true, returnData: res.data });
				if (data)
					dispatch(actions.coupon_add(res.data))
				else
					dispatch(actions.coupon_remove())
			})
			.catch(err => {
				console.log(err)
			})
	}

	return (
		 
  <>
				{(basket_restaurant_id === '' || basket_restaurant_id === undefined || (sub_total.count === 0 && sub_total.total === 0)) ?
					(
						<Container fluid={true} className="h-100 d-flex align-items-center">
							<Col>
								<h4 className="font-weight-bold text-center">Your cart is empty</h4>
 								<p className="font-weight-bold text-center text-default">Good food gives you Good mood. <br />Add something to let us start cooking</p>
							</Col>
						</Container>
					)
					:
					(
						<>
						 	<section className="pt-5 cartsec homepage-shop-block position-relative">

							<Container fluid={true} className="my-3">
								<h6 className="font-weight-bold">{t("ITEM IN CART")}</h6>
								<Row className="my-2">
									<Col lg="5" className="py-2 bg-white">
									{load && (
										<CartItems currency_symbol={data.details.currencie.code} />
									)}
										</Col>
								</Row>
							</Container>

							<Container fluid={true} className="my-3 bg-white pb-5">
								
								<Row>
									<Col className="ml-2">
										{(coupon.request || (couponStore && couponStore.coupon_code)) && (
											couponStore.id ? (
												<>
													<small className="text-success">Applied Coupon successfully!</small>
													<small className="text-danger ml-3 font-weight-bold" onClick={() => { setCoupon({ ...coupon, code: '', request: false, returnData: false }); dispatch(actions.coupon_remove()) }}>REMOVE COUPON</small>
												</>
											) :
												<small className="text-danger">Invalid Coupon. Try again!</small>
										)}
										

									</Col>
									 
								 
								</Row>
								<Row>
										<Col className="p-0">
										{load && (
											<CartBillings coupon={couponStore} sub_total={sub_total.total} currency_symbol={data.details.currencie.code} charges={data.charges} />
										)}
										</Col>
										</Row>
							</Container>

							<Container fluid={true} className="mt-3 mb-5 pb-8">
								
							</Container>
							</section>
							<section className="pt-5 cartsec homepage-shop-block position-relative">
							<Container fluid={true} className="position-fixed fixed-bottom w-100 bg-white p-0  shadow border-top">
						
								<div className="m-0 px-3 pt-3">
									<Col className="p-0">
										<h6 className="font-weight-bold"></h6>
									</Col>
									 
								</div>
								 
								 
								
								<div className="m-0 py-0 px-3">
								<h6 className="font-weight-light text-capitalize">{(data.address && (<><b className="font-weight-bold"> {data.details.name} </b> </>)) ?? ''}</h6>
								</div>
								<div className="m-0 py-0 px-3">
									<h6 className="font-weight-light text-capitalize">{(data.address && (<> {data.address.street} <br />{data.address.postal_code} {data.address.city}</>)) ?? ''}</h6>
								</div>
								<Link className="row  btncardbottom p-3 ml-0 w-100 mb-0 mr-0 bg-primary text-white" to="/payment">
                             <Col className="text-right p-0">
                                {t("Checkout")}
                                <FontAwesomeIcon icon={faArrowRight} className="ml-2 align-text-top text-white" />
                            </Col>
                        </Link>
							</Container>
							</section>
						</>
					)}
		


		</>
	);
};

export default Cart;
