import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router,Route} from 'react-router-dom';
import './index.css';
import App from './App';
import { store } from "./helpers/configStore";
import { Provider } from "react-redux";

import "./i18n"
import * as serviceWorker from './serviceWorker';
import 'react-owl-carousel3';
//import 'bootstrap/dist/css/bootstrap.css';


    
ReactDOM.render(
    <Provider store={store}>
      <Router>
          <Route path="/" component={App} />
         
      </Router></Provider>, 
      document.getElementById('root'));

 
serviceWorker.unregister();
