import React from 'react';
import PropTypes from 'prop-types'; 
import {Link} from 'react-router-dom';
import {Row,Col,Container,Form,InputGroup,FormControl,Button,Image} from 'react-bootstrap';
import FontAwesome from './FontAwesome';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = (props: any) => {

	const { t } = useTranslation();

    	return (
    		<>
			    <section className="section pt-5 pb-5 text-center bg-white">
			         <Container>
			            <Row>
			               <Col sm={12}>
			                  <h5 className="m-0">{t("Operate caffe or restaurant?")} <Link to="contact">{t("Work With Us")}</Link></h5>
			               </Col>
			            </Row>
			         </Container>
			    </section>
			    <section className="footer pt-5 pb-5">
			         <Container>
			            <Row>
			               <Col md={3} sm={12}>
						   <h6 className="mb-3"> {t("Download app")}</h6>

			                   <div className="app">
			                     
							   <a href="https://play.google.com/store/apps/details?id=org.itable.app&hl=en_US&gl=US">
			                     	<Image src="img/google.png" alt='' className="p-1 img-fluid w-50" fluid />
			                     </a>
			                     <a href="https://apps.apple.com/us/app/itable-app/id1595596660">
			                     	<Image src="img/apple.png" alt='' className="p-1 img-fluid w-50"  fluid />
			                     </a>
			                  </div>
			               </Col>
			               
			               <Col md={3} sm={4} xs={6}>
			                  <h6 className="mb-3">{t("About Us")}</h6>
			                  <ul>
			                     <li><Link to="/contact">{t("About Us")}</Link></li>
			                    
			                     
			                     <li><Link to="/contact">{t("Contact")}</Link></li>
			                     <li><Link to="/contact">{t("Careers")}</Link></li>
			                  {/*    <li><Link to="/prices">{t("Prices")}</Link></li> */}
 			                  </ul>
			               </Col>
			               <Col md={3} sm={4} xs={6}>
						   <h6 className="mb-3">{t("iTable Modules")}</h6>
			                  <ul>
							  <li><Link to="/contact">{t("See digital MENU")}</Link></li>
			                     <li><Link to="/contact">{t("Call the WAITER")}</Link></li>
			                     <li><Link to="/contact">{t("Current BALANCE")}</Link></li>
			                     <li><Link to="/contact">{t("Ask for BILL")}</Link></li>
			                      
			                  </ul>
			               </Col>
			               <Col md={3} sm={4} xs={4} className="m-none">
			                 
							  <h6 className="mb-3">{t("For Restaurants")}</h6>
			                  <ul>
			                     <li><Link to="/contact">{t("Advertise")}</Link></li>
			                     <li><Link to="/contact">{t("Free WEB page on iTable")}</Link></li>
			                     <li><Link to="/contact">{t("Live statistic")}</Link></li>
			                     <li><Link to="/contact">{t("Track working hours ")}</Link></li>
			                     <li><Link to="/contact">{t("Satisfied customers")}</Link></li>
			                     
			                  </ul>
			               </Col>
			            </Row>
			         </Container>
			    </section>
		      <section className="footer-bottom-search pt-5 pb-5 bg-white">
		         <div className="container">
		            <Row>
		               <Col xs={12} className="p-2">
		                  <p className="text-black">{t("COUNTRIES")}</p>
		                  <div className="search-links">
		                     <Link to="#">Bosna i Hercegovina</Link> |  
		                     <Link to="#">Srbija</Link> | 
		                     <Link to="#">Austrija</Link> |  
		                     <Link to="#">Slovenija</Link>   
 		                    
		                  </div>
		                {/*   <p className={props.popularFHclassName}>POPULAR FOOD</p>
		                  <div className="search-links">
		                     
		                  </div> */}
		               </Col>
		            </Row>
		         </div>
		      </section>
		      <footer className="pt-4 pb-4 text-center">
		         <Container>
		            <p className="mt-0 mb-0">© Copyright 2021 iTable . All Rights Reserved</p>
		            <small className="mt-0 mb-0"> Made with <FontAwesome icon="heart heart-icon text-danger"/> by
		            <Link className="text-danger ml-1" target="_blank" to={'https://itable.app'}>iTable</Link> - <Link className="text-primary" target="_blank" to="https://ddsoft.app">DDSOFT</Link>
		            </small>
		         </Container>
		      </footer>
		    </>
    	);
    
}


 
 



export default Footer;