import React, { useState } from "react";
import 'react-phone-input-2/lib/style.css';

import {Row,Col,Container,Form, FormGroup, Button, InputGroup,Image} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { Input, InputGroupAddon, InputGroupText, UncontrolledAlert } from 'reactstrap';
import { connect } from 'react-redux';
import actions from '../action/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Register: React.FC = (props: any) => {
	const [t] = useTranslation();

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");

	const onSubmit = (e) => {
		e.preventDefault()
		props.register({ name, email, phone, password }, props.history)
	}
    	return (
    	  <Container fluid className='bg-white'>
	         <Row>
	            <Col md={4} lg={6} className="d-none d-md-flex bg-image"></Col>
	            <Col md={8} lg={6}>
	               <div className="login d-flex align-items-center py-5">
	                  <Container>
	                     <Row>
	                        <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
							<div className="text-center">
							<Image className="w-50 align-center mb-5" src="/img/logo.png" alt="" />{" "}
							</div>
	                           <Form role="form" onSubmit={onSubmit}>
									<FormGroup>
										<InputGroup className="input-group-alternative mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<FontAwesomeIcon icon={faUser} className="ni ni-hat-3" />
												</InputGroupText>
											</InputGroupAddon>
											<Input placeholder={t("profile.Full Name")} type="text" name="name" value={name} onChange={e => setName(e.target.value)} required />

										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup className="input-group-alternative mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
												<FontAwesomeIcon icon={faEnvelope} className="ni ni-hat-3" />
												</InputGroupText>
											</InputGroupAddon>
											<Input placeholder="Email" type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} required />

										</InputGroup>
									</FormGroup>
									<FormGroup>
									<InputGroup className="input-group-alternative">
										 
										<PhoneInput onlyCountries={['ba','at','si','de','rs']} country={'ba'}
											buttonClass="bg-white"
											inputClass="w-100"
											value={phone} onChange={v => setPhone('+'+v)}
											inputProps={{
												name: 'phone', required: true
											}}
										/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup className="input-group-alternative">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
												<FontAwesomeIcon icon={faLock} className="ni ni-hat-3" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder="Password"
												type="password"
												name="password"
												autoComplete="off"
												value={password} onChange={e => setPassword(e.target.value)} required />
										</InputGroup>
									</FormGroup>
									{props.state.errorMsg && (
										<UncontrolledAlert color="warning">
											<strong>{props.state.errorMsg}</strong>
										</UncontrolledAlert>
									)}
									<div className="text-center">
										<Button
											className="btn bg-white btn-outline-secundary w-50 btn-lg w-50 mt-1 text-white"
											
											type="submit"
										>
												{t("login.Sign up")}
                          </Button>
									</div>
									<div className="row">
										<div className="text-center col-sm-12">
											<a className="btn btn-outline-primary btn-lg w-50 mt-1" href="/">	{t("nav.Home")} <i className="fa fa-home "></i>
											</a>
											</div>
											</div>
								</Form>	                        </Col>
	                     </Row>
	                  </Container>
	               </div>
	            </Col>
	         </Row>
	      </Container>
    	);
     
}


const mapStateToProps = (state) => {
	return { state: state.auth }
}

export default connect(mapStateToProps, actions)(Register);
