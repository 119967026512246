/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Card, CardImg, Col, Container, Row } from "react-bootstrap";
import { CardBody } from "reactstrap";
import SectionHeading from "../common/SectionHeading";
import { scroller } from "react-scroll";

import Ripples from "react-ripples";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel3";
import { Parallax } from "react-parallax";
import PackBoxCounter from "../Utilities/pack-box-counter";
import Addons from "../../mcards/addons";
import internal from "stream";
import { propTypes } from "react-bootstrap/esm/Image";

/* import { useTranslation } from "react-i18next";
import { usePromiseTracker } from "react-promise-tracker";
 */

type ListProps = {
  history?: any;
  restaurant_id?:number;
  latest?: boolean;
  scroll?: boolean;
  list?: boolean;
  loop?: boolean;
  dots?: boolean;
  autoplay?: boolean;
  curr?: any;
  featuredC?: boolean;
  dishes: any;
  heading: string;
  items0?: number;
  items764?: number;
  items765?: number;
  items1200?: number;
};

const Features: React.FC<ListProps> = ({
  featuredC = false,
  items0 = 1,
  loop = true,
  dots = false,
  scroll = false,
  items764 = 2,
  curr ,
  items765 = 3,
  items1200 = 3,
  autoplay,
  dishes,
  heading,
  list = false,
}) => {
  /* const { promiseInProgress } = usePromiseTracker();
	const { t } = useTranslation(); */
  var dishesAll: any = [];
  const [dishCat, setDishCat] = useState([]);
  const [catId, setCatId] = useState(0);
  const [latestl, setLatestl] = useState(false);
  const [loading, setLoading] = useState(false);
  const insideStyles = {
    background: "white",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  };
  const [options, setoptions] = useState({
    responsive: {
      0: {
        items: items0,
      },
      764: {
        items: items764,
      },
      765: {
        items: items765,
      },
      1200: {
        items: items1200,
      },
    },
    lazyLoad: true,
    loop: loop,
    autoplay: autoplay,
    autoplaySpeed: 2000,
    dots: dots,
    autoplayTimeout: 2000,
    nav: false,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    autoplayHoverPause: true,
  });

  async function parseDishes(dishes: any) {
    var categories: any = [];

    dishes.forEach((dish) => {
      if (
        categories.filter((e) => e.id === dish.dish_category_id).length === 0
      ) {
        categories.push({
          id: dish.dish_category_id,
          name: dish.dish_category.name,
          items: [],
        });
      }
    });

    dishes.forEach((dish) => {
      if (categories.filter((e) => e.id === dish.dish_category_id).length > 0) {
        categories.forEach((element) => {
          if (element.id === dish.dish_category_id) {
            element.items.push(dish);
          }
        });
      }
    });
    categories = categories.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    setDishCat(categories);
  }

  function renderComponent() {
    var id = 0;
    const list = dishesAll.map((product) => {
       return product.dish_category.id !== id ? (
        
        <div key={product.id}
          id={product.dish_category_id + "_" + product.dish_category.name}
          className="bg-gray pb-2 "
        >
           <Ripples
                          className="d-block myblock"
                          onClick={() => {
                            setTimeout(function () {
                              // history.push(`/shop/${id}`);
                            }, 275);
                          }}
                        >
          <h5
            className="bg-gray m-2  col-12 p-0"
            {...(id = product.dish_category_id)}
          >
            {product.dish_category.name}
          </h5>
          <div id={"art-"+product.id} className="bg-white card p-0 order-list shadow-sm col-12">
          <div className={"gold-members p-2 a2 "+(product.id==window.location.href.split("#art-")[1]?"bg-gray":"bg-white ")}>
              <div className="media">
                <img src={product.image} alt="" className="mr-4" />
                <div className="media-body row">
                  <div className="col-7 p-0">
                    <h6 className="mb-2">
                      
                        {product.name}{" "}
                      
                    </h6>
                    <p className="text-gray mb-1">
                      <FontAwesomeIcon icon={faInfoCircle} />{" "}
                      <i>{product.dish_category.name}</i>
                    </p>

                    <p className="text-gray mb-1"> {product.description}</p>
                    <p className="text-primary pricetext mb-1"> {product.price} {curr}</p>

                  </div>
                  <div className="col-5 ">
                    <div className="text-gray mb-1 price-art">
                      {" "}
                       <PackBoxCounter curr={curr} dish={product} restaurant_id={product.restaurant_id}/>
                    </div >
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Ripples>
        </div>
      ) : (
        (id = product.dish_category_id) && (
          <div key={Date.now()+"_"+product.id} className="bg-gray pb-2">
             <Ripples
                          className="d-block myblock"
                          onClick={() => {
                            setTimeout(function () {
                              // history.push(`/shop/${id}`);
                            }, 275);
                          }}
                        >
            <div id={"art-"+product.id} className="bg-white card  order-list p-0 shadow-sm  col-12">
            <div className={"gold-members p-2 a2 "+(product.id==window.location.href.split("#art-")[1]?"bg-gray":"bg-white ")}>
                <div className="media">
                  <img src={product.image} alt="" className="mr-4" />
                  <div className="media-body row">
                    <div className="col-7 p-0">
                      <h6 className="mb-2">
                        
                          {product.name}{" "}
                      
                      </h6>
                      <p className="text-gray mb-1">
                        <FontAwesomeIcon icon={faInfoCircle} />{" "}
                        <i>{product.dish_category.name}</i>
                      </p>

                      <p className="text-gray mb-1"> {product.description}</p>
                      <p className="text-primary pricetext mb-1"> {product.price} {curr}</p>
                    </div>
                    <div className="col-5  ">
                    <div className="text-gray mb-1 price-art">
                      {" "}
                      
                      <PackBoxCounter curr={curr} dish={product} restaurant_id={product.restaurant_id}/>
                    </div >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Ripples>
          </div>
        )
      );
    });

    return (
      <div
        data-spy="scroll" 
         data-target="#navbar-example2"
        data-offset="0"
        className="col-md-12 col-sm-12 px-4"
      >
        {list}
      </div>
    );
  }
  function scrollToSection(id) {
    scroller.scrollTo(id, {
      duration: 100,
      offset: -140,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  function scrollToSection2(id) {
 
     
    setTimeout(() => {
    const element = document.getElementById(id);
    if (element != null) { element.scrollIntoView({ behavior: 'smooth', block: 'center'} ); 
  
  }
  }, 275);
    
  }
  useEffect(() => {
     if(window.location.href.indexOf("#art-")!==0){
      let split= window.location.href.split("#art-")  
      console.log(split)
      scrollToSection2("art-"+split[1])
    }
    parseDishes(dishes);
  }, [dishes]);
  return (
    <Container className="w-100 px-2">
      {dishes.length > 2 && heading !== "" && (
        <SectionHeading haveMini={true} heading={heading} />
      )}
      <Row>
        {(!list && (
          <Col className="p-1" md={12} sm={12}>
            {dishes.length > 2 && (
              <OwlCarousel {...options} className="futures-ad owl-theme">
                {dishes.map((dish) => {
                  const { id, name, featured, image, price } = dish;
                  return (
                    (featured === 1 || featuredC) && (
                      <div className="mr-3 myfrontslide" key={id}>
                        <Ripples
                          className="d-block myblock"
                          onClick={() => {
                            setTimeout(function () {
                              // history.push(`/shop/${id}`);
                            }, 275);
                          }}
                        >
                          <Card className="my-dish shadow-sm p-0 m-0">
                            
                             <Parallax
        className="rounded-lg"
      blur={{ min: -51, max: -21}}
      bgImage={image}
      bgImageAlt={name+"_img"}
      strength={-15}
  >
  <div style={{"height":100}}></div>
</Parallax>
                            <CardBody   className="p-2 h-dish">
                              <h6 className=" ">
                                <a className="text-black" href="#detail">
                                  {name}{" "}
                                </a>
                              </h6>
                              

                              {/* <p className="text-gray   dishdesc">
                                {" "}
                                {dish.description}
                              </p> */}
                              <div className="mt-1 align-items-center text-muted">
                                <small className="dishPrice price-artH">
                                  {price} {curr}
                                  <PackBoxCounter login={false} curr={curr} dish={dish} restaurant_id={dish.restaurant_id}/>

                                </small>
                              </div>
                            </CardBody>
                          </Card>
                        </Ripples>
                      </div>
                    )
                  );
                })}
              </OwlCarousel>
            )}
          </Col>
        )) || (
          <div className="col-sm-12 p-0">
            <div className="osahan-account-page-left shadow-smmy bg-gray col-sm-12">
              <Row
                className={
                  "dishesList bg-white " +
                  (scroll ? " sticky-top stickedtop" : "")
                }
                md={12}
              >
                {dishCat && (
                  <nav id="navbar-example2" className="navbar col-md-12">
                    <ul className={"nav nav nav-pills flex-column border-0 "}>
                      {dishCat.map((dish) => {
                        const { id, name, items } = dish;
                        Object(items).forEach((element) => {
                           dishesAll.push(element);
                        });
                        return (
                          <li key={id}>
                            <button
                              className="btn btn-white-transparent  ml-2 pull-right "
                              onClick={() => {
                                scrollToSection("" + id + "_" + name);
                              }}
                            >
                              {name}
                            </button>
                          </li>
                        );
                      })}{" "}
                    </ul>
                  </nav>
                )}
              </Row>
              <Row>{renderComponent()}</Row>
            </div>
          </div>
        )}
      </Row>
      <Row></Row>
 
    </Container>
  );
};
export default Features;
