import React, { useState } from 'react';
import {Form,Modal,Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import actions from '../../../action/user';
import axios from '../../../helpers/requests'
 
interface MyProps {
    show: boolean;
    onHide?: any;
    t?:any
}

const AddUserModal: React.FC<MyProps> = (props: any) => {

   const[password,setPassword]=useState("")
   const[role,setRole]=useState("")
   const[name,setName]=useState("")
   const[email,setEmail]=useState("")
   const[phone,setPhone]=useState("")


    const {t} = useTranslation();
   const onSubmit = async (e) => {
		e.preventDefault()
      const data = new FormData(e.target);
      
       props.addUser(data,props.history)
 	}
   
    	return (
	        <Modal 
	        	show={props.show} 
	        	onHide={props.onHide}
		        size="sm"
		        centered
		   	  >
			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h5' id="edit-profile">{props.t("profile.Profile")}</Modal.Title>
			  </Modal.Header>
           <Form role="form" onSubmit={onSubmit}>
			  <Modal.Body>
			   
               <div className="form-row">
               <Form.Group className="col-md-12">
                     <Form.Label>{props.t("Role")}</Form.Label>
                     <select name="role" className="form-control" onChange={e => setRole(e.target.value)}  >
                        <option value="1">Admin</option>
                        <option value="2">Delivery</option>
                        <option value="3">Owner</option>
                        <option value="4">Customer</option>
                        </select>
                  </Form.Group>
                  <Form.Group className="col-md-12">
                     <Form.Label>{props.t("profile.Full Name")}</Form.Label>
                     <Form.Control type="text" autoComplete="off" name="name" onChange={e => setName(e.target.value)}  placeholder={props.t("profile.Full Name")}/>
                  </Form.Group>
                  <Form.Group className="col-md-12">
                     <Form.Label>{props.t("profile.Phone")}</Form.Label>
                     <Form.Control type="text"   name="phone" onChange={e => setPhone(e.target.value)} placeholder={props.t("profile.Phone")}/>
                  </Form.Group>
                  <Form.Group className="col-md-12">
                     <Form.Label>{props.t("profile.Email")}</Form.Label>
                     <Form.Control type="text"  name="email" onChange={e => setEmail(e.target.value)} placeholder={props.t("profile.Email")}/>
                  </Form.Group>
                  <Form.Group className="col-md-12 mb-0">
                     <Form.Label>{props.t("profile.Password")}</Form.Label>
                     <Form.Control type="password" name="password" autoComplete="off"  onChange={e => setPassword(e.target.value)}  placeholder={props.t("profile.Password")}/>
                   </Form.Group>
               </div>
         
			  </Modal.Body>

			  <Modal.Footer>
			    <Button type='button' onClick={props.onHide} variant="outline-primary" size="sm" className="d-flex text-center justify-content-center">{props.t("profile.Cancel")}</Button>
             <Button
											
											className="btn  btn-outline-secundary w-50 btn-lg w-50 mt-1 text-white btn bg-white"
											color="warning"
											size="sm"
											type="submit"
										>
											{t("profile.Update")}
                          			</Button>			  </Modal.Footer> </Form>
			</Modal>
    	);
     
}
const mapStateToProps = (state) => {
   return { state: state.auth }
}

export default connect(mapStateToProps, actions)(AddUserModal);
 