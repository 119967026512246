import { faLock, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {Modal,Button} from 'react-bootstrap';
interface MyProps {
    show: boolean;
    onHide?: any;
    text?:string;
    wifiPass?: string;
    t?:any
}

const WiFiModal: React.FC<MyProps> = (props: any) => {

	function saveData(data)
	{
	   
   console.log(data)
   props.onHide()
   }
    	return (
	        <Modal 
	        	show={props.show} 
	        	onHide={props.onHide}
		        size="lg"
		        centered
		   	  >
			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h5' id="edit-profile">{props.t("WiFi password")}</Modal.Title>
			  </Modal.Header>

			  <Modal.Body>
                <div className=" text-center p-2">
                   <h3><FontAwesomeIcon icon={faWifi }/> <FontAwesomeIcon icon={faLock }/> <span className="ml-2 mr-2">{props.text}</span> <FontAwesomeIcon icon={faLock }/> <FontAwesomeIcon icon={faWifi }/></h3>
               </div>
           
			  </Modal.Body>

			  <Modal.Footer>
			    <Button type='button' onClick={props.onHide} variant="outline-primary" className="d-flex text-center justify-content-center">{props.t("Close")}</Button>
 			  </Modal.Footer>
			</Modal>
    	);
     
}
export default WiFiModal;