import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import actions from '../../../action/restaurant';

import axios from '../../../helpers/requests'

import Map from '../../common/map/Map';

interface MyProps {
   show: boolean;
   code:string;
   onHide?: any;
   t?: any
}

const AddRestaurantModal: React.FC<MyProps> = (props: any) => {

   const [position, setPosition] = useState({ lat: 0, lng: 0 })
   const [mins, setMins] = useState([0,10,20,30,40,50])
   const [hours, setHours] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23])
   const [center, setCenter] = useState({
      lat: 44.727873285161714, lng: 18.085802097754108
   })
   const [role, setRole] = useState("")
   const [owners, setOwners] = useState<any>([])
   const [name, setName] = useState("")
   const [email, setEmail] = useState("")
   const [phone, setPhone] = useState("")


   const { t } = useTranslation();
   const onSubmit = async (e) => {
      e.preventDefault()
      const data = new FormData(e.target);
      console.log(data.get('long'))

    props.add(data, props.history)
   }
   function getUsers(){
      axios
			.get("/admin/owners")
			.then((res) => {
				setOwners(res.data);
 			})
			.catch((err) => {
 			});
   }
   function OnClickMap(pos) {
      setPosition(pos)
   }
 
   useEffect(() => {
      getUsers()
       } ,[])

   return (
      <Modal
         show={props.show}
         onHide={props.onHide}
         size="lg"
         centered
      >
         <Modal.Header closeButton={true}>
            <Modal.Title as='h5' id="edit-profile">{props.t("restaurant.Add New Restaurant")}</Modal.Title>
         </Modal.Header>
         <Form role="form" onSubmit={onSubmit}>
            <Modal.Body>

               <div className="form-row">
                  
              
                       <Form.Group className="col-6">
                     <Form.Label>{props.t("Tip")}</Form.Label>
                      <select name="user_id" className="form-control">
                      {owners.map((m) =>{return( <option value={m.id} >{m.name} {m.email}</option>)})}

                       </select>
                   </Form.Group>
                  <Form.Group className="col-6">
                     <Form.Label>{props.t("Tip")}</Form.Label>
                     <select name="tip" className="form-control" onChange={e => setRole(e.target.value)}  >
                        <option value="1">Restaurant</option>
                        <option value="2">Caffe</option>
                        <option value="3">Pizzashop</option>
                        <option value="4">Cackeshop</option>
                     </select>
                  </Form.Group>
                  <Form.Group className="col-6">
                     <Form.Label>{props.t("restaurant.Name")}</Form.Label>
                     <Form.Control type="text" autoComplete="off" name="name" required placeholder={props.t("restaurant.Name")} />
                  </Form.Group>
                  <Form.Group className="col-6 pt-4">
                     <Form.Label>{props.t("restaurant.License Code")} ({props.code}) </Form.Label>
                     <Form.Control type="text" hidden autoComplete="off" name="license_code" required defaultValue={props.code} />
                  </Form.Group>
                  <Form.Group controlId="formFileSm" className="mb-3 p-2">
    <Form.Label>Logo</Form.Label>
    <Form.Control name="logo" type="file" size="sm" />
  </Form.Group>
  <Form.Group controlId="formFileSm" className="mb-3 p-2">
    <Form.Label>Baner</Form.Label>
    <Form.Control name="image" type="file" size="sm" />
  </Form.Group>
                  <Form.Group className="col-md-12">
                     <Form.Label>{props.t("restaurant.Description")}</Form.Label>
                     <Form.Control type="text" name="description" required placeholder={props.t("restaurant.Description")} />
                  </Form.Group>

                  <Form.Group className="col-4">
                     <Form.Label>{props.t("restaurant.Phone")}</Form.Label>
                     <Form.Control type="text" name="phone" required placeholder={props.t("restaurant.Phone")} />
                  </Form.Group>

                  <Form.Group className="col-4">
                     <Form.Label>{props.t("restaurant.Email")}</Form.Label>
                     <Form.Control type="text" name="email" required placeholder={props.t("restaurant.Email")} />
                  </Form.Group>

                  <Form.Group className="col-4">
                     <Form.Label>{props.t("restaurant.Web")}</Form.Label>
                     <Form.Control type="text" name="web" placeholder={props.t("restaurant.Web")} />
                  </Form.Group>
                  <Form.Group className="col-md-12">
                     <Form.Label>{props.t("restaurant.Work time")}</Form.Label>

                  </Form.Group>
                  <Form.Group className="col-md-12">
                     <Form.Label>{props.t("restaurant.Work days")}</Form.Label>

                  </Form.Group>
                  <Form.Group className="col-md-2">
                     <Form.Label>{props.t("restaurant.Hours")}</Form.Label>

                     <select name="open_working_hours" className="form-control">
                     {hours.map((h) =>{return( <option key={h} value={h} >{h<10?"0"+h:h}</option>)})}
            
                        </select>
                  </Form.Group>
                  <Form.Group className="col-md-2">
                     <Form.Label>{props.t("restaurant.Minutes")}</Form.Label>
                     <select name="open_working_minutes" className="form-control">
                     {mins.map((m) =>{return( <option key={m} value={m} >{m<10?"0"+m:m}</option>)})}

                          </select>

                  </Form.Group>
                  <Form.Group className="col-md-1">
                     <Form.Label>   </Form.Label>
                     <p className="text-center pt-3">{"->"}</p>
                  </Form.Group>
                  <Form.Group className="col-md-2">
                     <Form.Label>{props.t("restaurant.Hours")}</Form.Label>


                     <select name="close_working_hours" className="form-control">
                     {hours.map((h) =>{return( <option key={h} value={h} >{h<10?"0"+h:h}</option>)})}

                         </select>

                  </Form.Group>
                  <Form.Group className="col-md-2">
                     <Form.Label>{props.t("restaurant.Minutes")}</Form.Label>

                   
                     <select name="close_working_minutes" className="form-control">
                     {mins.map((m) =>{return( <option key={m} value={m} >{m<10?"0"+m:m}</option>)})}

                      </select>

                  </Form.Group>
                  <Form.Group className="col-md-12">
                     <Form.Label>{props.t("restaurant.Weekend")}</Form.Label>

                  </Form.Group>
                  <Form.Group className="col-md-2">
                     <Form.Label>{props.t("restaurant.Hours")}</Form.Label>


                     <select name="open_weekend_hours" className="form-control">
                     {hours.map((h) =>{return( <option key={h} value={h} >{h<10?"0"+h:h}</option>)})}

                         </select>

                  </Form.Group>
                  <Form.Group className="col-md-2">
                     <Form.Label>{props.t("restaurant.Minutes")}</Form.Label>

                      <select name="open_weekend_minutes" className="form-control">
                      {mins.map((m) =>{return( <option   key={m}  value={m} >{m<10?"0"+m:m}</option>)})}

                      </select>

                  </Form.Group>
                  <Form.Group className="col-md-1">
                     <Form.Label>   </Form.Label>
                     <p className="text-center pt-3">{"->"}</p>
                  </Form.Group>
                  <Form.Group className="col-md-2">
                     <Form.Label>{props.t("restaurant.Hours")}</Form.Label>

                     <select name="close_weekend_hours" className="form-control">
                     {hours.map((h) =>{return( <option  key={h} value={h} >{h<10?"0"+h:h}</option>)})}

                         </select>
                  </Form.Group>
                  <Form.Group className="col-md-2">
                     <Form.Label>{props.t("restaurant.Minutes")}</Form.Label>

                      <select name="close_weekend_minutes" className="form-control">
                      {mins.map((m) =>{return( <option  key={m} value={m} >{m<10?"0"+m:m}</option>)})}

                       </select>

                  </Form.Group>
                  <Form.Group className="col-6">
                     <Form.Label>{props.t("restaurant.Web")}</Form.Label>
                     <Form.Control type="text" name="web" placeholder={props.t("restaurant.Web")} />
                  </Form.Group>

                  <Form.Group className="col-6">
                     <Form.Label>{props.t("Currencie")}</Form.Label>
                     <select name="currencie_id" className="form-control"  >
                        <option value="1">KM</option>
                        <option value="2">$</option>
                        <option value="3">€</option>

                     </select>
                  </Form.Group>

                  <Form.Group className="col-md-12">
                     <Form.Label>{props.t("restaurant.Full Address")}</Form.Label>
                     <Form.Control type="text" name="address" required placeholder={props.t("restaurant.Full Address")} />
                  </Form.Group>

                  <Form.Group className="col-6">
                     <Form.Label>{props.t("restaurant.Zip Code")}</Form.Label>
                     <Form.Control type="number" name="pincode" required placeholder={props.t("restaurant.Zip Code")} />
                  </Form.Group>

                  <Form.Group className="col-6">
                     <Form.Label>{props.t("restaurant.City")}</Form.Label>
                     <Form.Control type="text" name="city" required placeholder={props.t("restaurant.City")} />
                  </Form.Group>

                  <Form.Group className="col-md-4">
                     <Form.Group className="col-md-12">
                        <Form.Label>{props.t("restaurant.Lat")}({position.lat})</Form.Label>
                        <Form.Control type="number"  hidden name="lat" value={position.lat} required placeholder={props.t("restaurant.Lat")} />
                     </Form.Group>

                     <Form.Group className="col-md-12">
                        <Form.Label>{props.t("restaurant.Lng")}({position.lng})</Form.Label>
                        <Form.Control type="number" hidden value={position.lng} name="long" required placeholder={props.t("restaurant.Lng")} />
                        <Form.Control type="number" hidden value="30" name="delivery_time" required placeholder={props.t("restaurant.Lng")} />
                        <Form.Control type="number" hidden value="100" name="delivery_radius" required placeholder={props.t("restaurant.Lng")} />
                        <Form.Control type="number" hidden value="0" name="commission_rate" required placeholder={props.t("restaurant.Lng")} />
                        <Form.Control type="number" hidden value="0" name="for_two" required placeholder={props.t("restaurant.Lng")} />
                        <Form.Control type="number" hidden value="5" name="rating" required placeholder={props.t("restaurant.Lng")} />
                        <Form.Control type="number" hidden value="0" name="restaurant_charges" required placeholder={props.t("restaurant.Lng")} />
                      </Form.Group>
                  </Form.Group>
                  <Form.Group className="col-md-8">
                     <Map center={center} onClick={OnClickMap} />
                  </Form.Group>
   
                  <Form.Group className="col-6 mb-0">
                     <Form.Label>{props.t("restaurant.WiFi password")}</Form.Label>
                     <Form.Control type="text" name="wifi" autoComplete="off" />
                  </Form.Group>

                  <Form.Group className="col-6 pt-4">
                  <Form.Check className="text-warning" name="featured"  label={props.t("restaurant.Featured")}  type="checkbox"  id="inline-checkbox-1" />
                  </Form.Group>



               
               </div>

            </Modal.Body>

            <Modal.Footer>
               <Button type='button' onClick={props.onHide} variant="outline-primary" size="sm" className="d-flex text-center justify-content-center">{props.t("profile.Cancel")}</Button>
               <Button className="btn  btn-outline-secundary   btn-lg  w-25 mt-1 text-white btn bg-white" color="warning"
                  size="sm"
                  type="submit"
               >
                  {t("profile.Update")}
               </Button>			  </Modal.Footer> </Form>
      </Modal>
   );

}
const mapStateToProps = (state) => {
   return { state: state.auth }
}

export default connect(mapStateToProps, actions)(AddRestaurantModal);
