
 import { faSatellite, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Input, Row } from "reactstrap";
import FeaturesList from "../../components/home/FeaturesList";
import RestaurantList from "../../components/RestaurantCards/general";
import axios from "../../helpers/requests";
import GoogleMapReact from 'google-map-react';
import Geolocation from "react-native-geolocation-service";
import Ripples from "react-ripples";
import Marker from "../common/map2/Marker";

const Home: React.FC = (props: any) => {
	const [restaurants, setRestaurants] = useState([]);
	const [center, setCenter] = useState({ lat: 0, lng: 0 });
	const [countryCode, setCountryCode] = useState("");
	const [restaurants2, setRestaurants2] = useState([]);
	const [latest, setLatest] = useState([]);
	const [zoomC, setZoom] = useState(5);
	const [categories, setCategories] = useState([]);
	const [slides, setSlides] = useState([]);
	const [locations, setLocations] = useState<any>([]);

	const { t } = useTranslation();
	const [searching, setStatus] = useState(false)
	const [status, setStatus2] = useState({
		searching: false,
		loaded: false,
		error: "",
	});
	const [query, setQuery] = useState("");
	const [loading, setLoading] = useState(false);
	const exampleMapStyles = [
		{
			"featureType": "water",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#d3d3d3"
				}
			]
		},
		{
			"featureType": "transit",
			"stylers": [
				{
					"color": "#808080"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#b3b3b3"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#ffffff"
				},
				{
					"weight": 1.8
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#d7d7d7"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#ebebeb"
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#a7a7a7"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#efefef"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#696969"
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#737373"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#d6d6d6"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{},
		{
			"featureType": "poi",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#dadada"
				}
			]
		}
	];
	function getLocations() {
		axios
		  .get<any>("/locations")
		  .then((res) => {
			setLocations((locations) => [...locations, ...res.data]);
			console.log(res.data);
		  })
		  .catch((err) => {
			console.log(err);
		  });
	  }
	function getData(lat,lng) {
		setRestaurants([])
		axios
		  .get<Object>("/restaurants/nearby", {
			params: {
			  dstc: localStorage.getItem("dstc"),
			  lat: lat,
			  lng: lng,
			},
		  })
		  .then((res) => {
			setRestaurants(res.data["restaurants"]);
			setCategories(res.data["categories"]);
	
			setSlides(res.data["slides"]);
		  })
		  .catch((err) => {
			console.log(err);
		  });
		getData2(lat,lng);
	  }
	
	  function getData2(lat,lng) {
		axios
		  .get<Object>("/restaurants/latest", {
			params: {
			  dstc: localStorage.getItem("dstc"),
			  lat: lat,
			  lng: lng,
			},
		  })
		  .then((res) => {
			setLatest(res.data["restaurants"]);
		  })
		  .catch((err) => {
			console.log(err);
		  });
	  }
	function locateMe() {
		setLoading(true)
		Geolocation.getCurrentPosition(
		  (position) => {
			const { latitude, longitude } = position.coords;
			setCenter({
			  lat: latitude,
			  lng: longitude,
			});
			getData(latitude,longitude);
			getData2(latitude,longitude);
			localStorage.setItem("GPS","1");
			localStorage.setItem("lat",latitude.toString());
			localStorage.setItem("lng",longitude.toString());
			
			setZoom(17)
			setLoading(false)
		  },
		  (error) => {
			props.history.push("/nogps");
		  },
		  { enableHighAccuracy: false, timeout: 6000, maximumAge: 9000 }
		);
	  }
	function getData3() {
		axios
			.get<any>("/restaurants/nearby", {
				params: {
					dstc: localStorage.getItem("dstc"),
					lat: localStorage.getItem("lat")?localStorage.getItem("lat"):0,
					lng: localStorage.getItem("lng")?localStorage.getItem("lng"):0,
				},
			})
			.then(res => {
				setRestaurants(res.data.restaurants);
				setStatus(true)
			})
			.catch((err) => {
				console.log(err);
			});
	}
	const onSearch = (e) => {
		const q = e.target.value;
		setQuery( e.target.value)
		if (q.trim().length < 3) return;

		axios
			.get<any>("/restaurants/search", {
				params: {
					q:q,
					dstc: localStorage.getItem("dstc"),
					lat: localStorage.getItem("lat")?localStorage.getItem("lat"):0,
					lng: localStorage.getItem("lng")?localStorage.getItem("lng"):0,
				},
			})
			.then(res => {
				setRestaurants(res.data.restaurants);
				setStatus(true)
			console.log(restaurants)
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		let latitude = localStorage.getItem("lat")?parseFloat(localStorage.getItem("lat")!):0
		let longitude = localStorage.getItem("lng")?parseFloat(localStorage.getItem("lng")!):0
		setCenter({
			lat: latitude,
			lng: longitude,
		  });
		getData3();
		getLocations();

		getData(center.lat,center.lng);
	}, []);
	return (
		<>
			<div   className="section mt-5 pb-0  bg-white" style={{  position: 'relative', width: '100%', top: '0', left: '0', height: '370px' }}>
    {center.lat === 0 && <div className="floatingMapButton mb-0 text-center row">
    <h2 className="text-white text-center"> 
            <Button onClick={()=>{locateMe();setCountryCode("")}} className={"btn-locirajme btn btn-small ml-3 p-2 btn-location text-white "}>
                  <FontAwesomeIcon className={loading?"fa-spin":""}icon={faSatellite} /> {t("Locate me")}
                </Button></h2>
               
          </div>
  }
     {center.lat !== 0 && <div  className="floatingMap"  style={{ zIndex: 1, position: 'absolute' }}>
         { restaurants.map(marker => {
      //const onClick = props.onClick.bind(this, marker)
      let a = "";
      a= marker["name"]
      if(!a.includes('Test')){
      return (
        <Ripples
                        className="d-block myblock"
						key={marker["id"]}
                        onClick={() => {
                          setTimeout(function () {
                            props.history.push(`/shop/${marker["id"]}`);
                          }, 275);
                        }}
                      >
          <Row
              key={marker["id"]}
              className="p-2"
              
          >
             <div className="col-md-3 text-center ">
                      <img style={{width:"30px",height:"30px"}}alt={marker["name"]} src={marker["logo"]}/>
            </div>
                       <div className="col-md-9 text-left my-2">
                       {marker["name"]}
            </div>
          </Row>
          </Ripples>
      )
      }
  })}
  
  </div>}
  {restaurants &&  <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyC8NydMkPVNT4AsZOUolwFcQn6XVm-Q-YQ' }}
              defaultCenter={center.lat===0?{ lat: 45.8150108 ,lng: 15.981919 }:center}
              center={center.lat===0?{ lat: 45.8150108 ,lng: 15.981919 }:center}
              onClick={()=>{}}
              options={{
                  gestureHandling: "greedy",
                  styles: exampleMapStyles,
                  mapTypeControl: false,
                  streetViewControl: false,
                  zoomControl: false,
                  fullscreenControl: false,
                  zoom:zoomC,
                  minZoom: 5,
                  maxZoom: 10
              }}

              defaultZoom={zoomC}
          >
              {restaurants && restaurants.map(marker => {
      //const onClick = props.onClick.bind(this, marker)
      let a = "";
      a= marker["name"]
      if(!a.includes('Test')){
      return (
          <Marker
              key={marker["id"]}
              id={marker["id"]}
              lat={marker["addresses"][0]["latitude"]}
              lng={marker["addresses"][0]["longitude"]}
              /* onClick={}
              onTouchEnd={} */
              name={marker["name"]}
              address={marker["name"]}
              tel={marker["phone"]}
              color="blue"
              position={{ lat: marker["addresses"][0]["latitude"], lng:marker["addresses"][0]["longitude"] }}
          >
              
          </Marker>
      )
      }
  })}
          
          </GoogleMapReact>}
          </div>
		<Container className="mt-5 pt-4">
		
			<div className="bg-white ion-no-border border-bottom">
				<div color="section pt-2 pb-2 products-section bg-red">
						 
 
					<Input
						onChange={onSearch}
						value={query}
						placeholder={t("shop.Search for Restaurant and Dishes")}
						className="font-weight-bold text-default"
					></Input>
				</div>
			</div>

			<Container fluid={true} className="h-100">
				{searching && <Row className="mt-3"></Row>}

				<Row className="mt-3 searchlist">
					<Col className="pl-0 pr-0">
						<h5 className="font-weight-bold px-3"></h5>
						{restaurants ? (
							<FeaturesList
							search={true}
								autoplay={false}
								items0={2}
								items765={2}
								items1200={2}
								heading={""}
								featuredC={true}
								restaurants={restaurants}
								loop={true}
								latest={true}
								history={props.history}
							/>
						) : (
							<></>
						)}
					</Col>
				</Row>
			</Container>
		</Container>
		</>
	);
};

export default Home;
