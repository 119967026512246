 import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import PaymentMethods from '../payments/payment-methods';
import { RootStateOrAny, useSelector } from 'react-redux';
import loadScript from '../../helpers/loadScript';
import { useTranslation } from 'react-i18next';

const Payment: React.FC = (props: any) => {

  const [modal, setModal] = useState(false);
  const {t} = useTranslation();

  const toggle = () => setModal(!modal);

  const total = useSelector((state: RootStateOrAny) => state.cart.total);
	const currency_symbol = useSelector((state: RootStateOrAny) => state.auth.currency_symbol);

  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js', 'razorpay')
  }, [])

  return (
     
      <>
        <Container fluid={true} className="m-0 bg-white   pt-6">
          <h6 className="font-weight-bold my-0 text-center text-uppercase">{t("You have to pay")}</h6>
          <h2 className="font-weight-bold my-0 text-center">{(total+currency_symbol).toString()}</h2>
        </Container>

        <Container fluid={true} className="my-4">
          <h5 className="font-weight-bold my-0 text-center">{t("Select your payment method:")}</h5>
          <Row className="my-0">
            <Col lg="5">
              <PaymentMethods  togle={toggle} t={t} history={props.history} />
            </Col>
          </Row>
          {/* <Button className="position-fixed fixed-bottom w-100 bg-success p-3 m-0 text-white" onClick={toggle}>Place the order</Button> */}
        </Container>

        <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={true} style={{ top: '10%' }}>
          
          <ModalBody className="p-0">
            <h4 className="font-weight-bold my-0 text-center">
              {/* <IonIcon icon={closeCircleOutline} className="mt-2 text-warning" /> */}
              {/* <IonIcon icon={checkmarkCircleOutline} className="mt-2 text-warning" /> */}
            </h4>
            <Row>
              <Col xs="8" className="offset-2 px-0 pt-3">
                <img src='/img/icons/common/orderPlaced.jpeg' alt="payment success" />
              </Col>
            </Row>
            <h5 className="font-weight-bold my-0 text-center text-default">{t("Your order placed successfully")}</h5>
          </ModalBody>
          <ModalFooter className="justify-content-center">
          <Button
											
											className="btn  btn-outline-secundary w-50 btn-lg w-50 mt-1 text-white btn bg-white"
											color="warning"
											size="lg"
                      href="/orders"
											type="submit"
										>
											{t("Go to Orders")}
                          			</Button>
          
          </ModalFooter>
        </Modal>


    
    </ >
  );
};

export default Payment;
