export const TRANSLATIONS_DE = {
    "Details": "Einzelheiten",

    "Please wait...": "Bitte warten...",

    "welcome": {
        "title": "Willkommen zu {{framework}}",

        "best of": "Das Beste von",

        "pizzerias": "Pizzerien",

        "caterers": "Caterer",

        "restaurants": "Restaurants",

        "caffes": "Cafés",

        "clubs": "Clubs",

        "Call the WAITER, see digital MENU, order through the APP, see the table current BALANCE ...":
            "KELLNER rufen, siehe digitales MENÜ, Bestellung über die APP, siehe aktuellen SALDO für diesen Tisch ...",

        "Please turn on GPS to get location":
            "Bitte schalte das GPS ein, um den Standort zu erhalten",
    },

    "profile": {
        "Phone number": "Telefonnummer",

        "Cancel": "Abbrechen",

        "Update": "Aktualisieren",

        "Email": "Email",

        "Password": "Kennwort",

        "EDIT": "Bearbeiten",

        "Profile": "Profil",

        "Today Orders": "Die heutigen Bestellungen",

        "7 Days": "7 Tage",

        "7 Users": "7 Tage Benutzer",

        "Today Earnings": "Heutiger Verdienst",

        "Total Orders": "Gesamtbestellungen",

        "Total Users": "Gesamtnutzer",

        "Total Restaurants": "Alle Geschäfte",

        "Total Events": "Gesamtereignisse",

        "Total Banners": "Gesamtzahl der Banner",

        "Your Banners": "Deine Banner",

        "More info": "Mehr Info",

        "Featured Caffes": "Empfohlene Cafés",

        "Featured Dishes": "Empfohlene Gerichte",

        "Featured Categories": "Beliebte Kategorien",

        "Featured Restauraunts": "Empfohlene Restaurants",

        "Featured Clubs": "Empfohlene Clubs",

        "Featured Pizzerias": "Empfohlene Pizzerien",

        "Caffe": "Café",

        "Dishes": "Gerichte",

        "Add User": "Benutzer hinzufügen",

        "Drinks": "Getränke",

        "Restauraunt": "Restaurant",

        "Club": "Club",

        "Category": "Kategorie",

        "Categories": "Kategorien",

        "Price": "Preis",

        "Active": "Aktiv",

        "Action": "Aktion",

        "List of Dishes": "Liste der Gerichte",

        "List of Drinks": "Getränkeliste",

        "List of Addons": "Liste der Addons",

        "Addons Categories": "Add-ons Kategorien",

        "Dish Categories": "Gericht Kategorien",

        "Drink Categories": "Getränkekategorien",

        "Pizzeria": "Pizzeria",

        "Addons": "Addons",

        "Live Orders": "Live-Bestellungen",

        "This Week": "Diese Woche",

        "View Report": "Zeige Bericht",

        "Cake shop": "Konditorei",

        "Last 7 days orders": "Letzte 7 Tage Bestellungen",

        "Total Earnings": "Gesamteinnahmen",

        "Distance": "Distanz",

        "DistanceInfo": "max. Abstand, um Objekte herum zu zeigen",

        "Full Name": "Vollständiger Name",

        "Phone": "Telefon",

        "Language": "Sprache",

        "My Order": "Meine Bestellung",

        "Notifications": "Benachrichtigungen",

        "Logout": "Ausloggen",
    },

    "login": {
        "Melde dich an, um deine Sitzung zu starten": "Einloggen",

        "Password": "Kennwort",

        "Sign in": "Einloggen",

        "Sign up": "Registrieren",

        "Continue as guest": "Als Gast fortfahren",

        "Skip": "Überspringen",

        "Don't have an account?": "Du hast kein Konto?",

        "Forgot Password?": "Passwort vergessen?",

        "Remember Me": "Bleibe eingeloggt",

        "Please wait...": "Bitte warten...",
    },

    "nav": {
        "Home": "Home",

        "Explore": "Entdecken",

        "Orders": "Aufträge",

        "Profile": "Profil",
    },

    "shop": {
        "Featured": "Empfohlen",

        "Search Results": "Suchergebnisse",

        "Recommended for you": "Für dich empfohlen",

        "No featured dishes available": "Keine empfohlenen Gerichte verfügbar",

        "Articles": "Artikel",

        "No articles": "Keine Artikel",

        "Search for Restaurant and Dishes": "Suche nach Restaurants und Gerichten",
    },

    "home": {
        "Featured": "Empfohlen",

        "Discover Featured Places": "Empfohlene Orte entdecken",

        "Top Categories": "Top Kategorien",

        "Near you": "In deiner Nähe",
    },

    "restaurant": {
        "Name": "Name",

        "New": "Neues Restaurant",

        "License Code": "Lizenzcode",

        "Add New Restaurant": "Neues Restaurant hinzufügen",

        "WiFi password": "WiFi Kennwort",

        "Description": "Beschreibung",

        "Phone": "Telefon",

        "Email": "Email",

        "Web": "Seite URL",

        "Open": "Offen",

        "Close": "Schließen",

        "Work time": "Arbeitszeit",

        "Work days": "Arbeitstage",

        "Weekend": "Wochenende",

        "Hours": "Stunden",

        "Zip Code": "PLZ",

        "City": "Stadt",

        "Lat": "Breite",

        "Lng": "Längengrad",

        "Featured": "Empfohlen",

        "Full Address": "Vollständige Adresse",

        "Open Hours": "Öffnungszeiten",

        "Open Working Hours": "Offene Arbeitszeiten",
    },
    "myprofile": {
        "History": "Verlauf",

        "Favourites": "Favoriten",

        "Addresses": "Adressen",

        "Logout": "Ausloggen",
    },

    "Become iTable Member": "Werde iTable Mitglied",

    "Become iTable member and start experiencing the world of CAFES and RESTAURANTS in a different way":
        "Werde 😎 iTable Mitglied 😎 und erlebe 🌍 die Welt der CAFES und RESTAURANTS einmal anders ❤️❤️❤️",

   "Meni": "Menü",

    "Stanje": "Aktueller Zustand",

    "Bill": "Nach der Rechnung fragen",

    "Verify Phone!": "Verifiziere dein Telefon!",

    "Password must be minimum 6 char long":
        "Das Passwort muss mindestens 6 Zeichen lang sein",

    "Profile Picture": "Profilbild",

    "To save plase click Update": 'Zum Speichern bitte auf "Aktualisieren" klicken"',

    "Click on image to change": "Klicke um zu ändern",

    "ORDER PLACED": "Bestellung aufgegeben",

    "Accepted": "Akzeptiert",

    "In progres": "In Bearbeitung",

    "On the way": "Unterwegs",

    "Finished": "Fertig",

    "Users": "Die Benutzer",

    "Dashboard": "Dashboard",

    "Created": "Erstellt",

    "Email Verified": "Email verfiziert",

    "Phone Verified": "Telefon verfiziert",

    "Edit User": "Benutzer bearbeiten",

    "Currencie": "Währung",

    "Restaurants": "Restaurants",

    "Dishes": "Gerichte",

    "Drinks": "Getränke",

    "Dishes Categories": "Gerichte Kategorien",

    "Drinks Categories": "Getränke Kategorien",

    "New Category": "Neue Kategorie",

    "Add": "Hinzufügen",

    "Item": "Artikel",

    "Articls": "Die Artikel",

    "ITEM IN CART": "ARTIKEL IM WARENKORB",

    "Checkout": "Auschecken",

    "Grand Total": "Gesamtsumme",

    "Your order placed successfully": "Deine Bestellung wurde erfolgreich aufgegeben",

    "Go to Orders": "Gehe zu Bestellungen",

    "Select your payment method:": "Wähle eine Bezahlart:",

    "Cash on Delivery": "Barzahlung bei Lieferung",

    "COD": "BARGELD",

    "CARD": "BANKOMATKARTE / KREDITKARTE",

    "Close": "Schließen",

    "Language": "Sprache",

    "To change language, please click on flag.":
        "Um die Sprache zu ändern, klicke bitte auf die Flagge..",

    "Pay with card": "Mit Karte bezahlen",

    "Please login to order": "Bitte logge dich ein, um zu bestellen",

    "You have to pay": "Zum bezahlen",

    "Call waiter": "Kellner rufen",

    "Press bell to call": "Klingel drücken, um zurufen",

    "Try Again": "Versuch es noch einmal",

    "Current state for this table is:": "Aktueller Status für diese Tabelle ist:",

    "Action is disabled.": "Aktion ist deaktiviert.",

    "Time for action is limited to 5min. Please scann iTable code again.":
        "Die Aktionszeit ist auf 5 min. begrenzt. Bitte iTable code erneut scannen.",

    "This QR code no exist in our system.":
        "Dieser QR Code existiert in unserem System nicht.",

    "Register error":
        "Fehler bei der Registrierung: Bitte überprüfe, ob deine E-Mail-Adresse, dein Telefon oder dein Passwort richtig eingegeben wurde.",

    "Your cart is empty": "Dein Warenkorb ist leer",

    "Add something to let us start working":
        "Füge etwas hinzu, damit wir mit der Arbeit beginnen können",

    "Please scan iTable QR code ": "Bitte scanne den iTable QR Code ",

    "Home": "Home",

    "Search": "Suche",

    "Notifications": "Benachrichtigungen",

    "Profile": "Profil",
    "You have no new Notifications": "Du hast keine neuen Benachrichtigungen",
    "Your order": "Deine Bestellung",
    "is placed successfully": "ist erfolgreich platziert",
    "Recent Notifications": "Aktuelle Benachrichtigungen",
    "is Accepted": "ist Akzeptiert",
    "is prepared and will be picked up by a Delivery.":
        "ist vorbereitet und wird von einem Zusteller abgeholt.",
    "is on the way.": "ist unterwegs.",
    "is in progress": "ist in Bearbeitung",
    "is finished successfully": "ist erfolgreich abgeschlossen",
    "Page not found": "Seite nicht gefunden",
    "Uh-oh! Looks like the page you are trying to access, doesn't exist. Please start afresh.":
        "Uh-oh! Sieht so aus, als ob die Seite, auf die du zugreifen möchtest, nicht existiert. Bitte starte erneut.",
    "Restaurant has ": "Restaurant hat ",
    " more dishes": " mehr Artikel in Kategorie",
    "GPS location is OFF": "GPS Standort ist AUS",
    "GPS location is OFF": "GPS Standort ist AUS",
    "Please enable your GPS to use  ": "Bitte aktiviere GPS, um es zu verwenden  ",
    "application": "Anwendung",
    "Please check your GPS and try again": "Bitte überprüfe dein GPS und versuche es erneut",
    "new":"Neues 🎉🆕",
    "Continue":"Fortfahren",
    "Plans":"Abonnementpakete",
    "Please enter your name":"Bitte geben Sie Ihren Namen ein",
    "Name":"Namen",
    "Please enter a valid email address":"Bitte geben Sie eine gültige E-Mail-Adresse ein",
    "Please enter phone":"Bitte Telefon eingeben",
    "Phone":"Telefon",
    "City":"Stadt",
    "Please enter your city":"Bitte geben Sie Ihre Stadt ein",
    "Please enter a subject":"Bitte geben Sie einen Betreff ein",
    "Subject":"Betreff",
    "Message":"Nachricht",
    "Please enter a message":"Bitte geben Sie eine Nachricht ein",
    "Submit":"Absenden",
    "Statistic":"Status",
    "iTable location":"iTable-Speicherort",
    "Created for everyone":"Für alle geschaffen",
    "Ovaj paket je pogodan za sve velike ugostiteljske objekte i ukljucuje sve module, NEMA limita i kreće od 49+ stolova":"Dieses Paket ist für alle großen Gastronomiebetriebe geeignet und beinhaltet alle Module, die Tischanzahl ist auf 35 bis 49 begrenzt.",
    "Ovaj paket je pogodan za sve veće ugostiteljske objekte i ukljucuje sve module, limit broja stolova je od 35 do 49.":"Dieses Paket ist für alle großen Gastronomiebetriebe geeignet und beinhaltet alle Module, die Tischanzahl ist auf 35 bis 49 begrenzt.",
    "Ovaj paket je pogodan za sve manje ugostiteljske objekte i ukljucuje sve module sa limitom do maksimalno 19 stolova.":"Dieses Paket ist für alle kleineren Gastronomiebetriebe geeignet und beinhaltet alle Module mit einer Begrenzung auf bis zu 19 Tische.",
    "Ovaj paket je pogodan za sve srednje ugostiteljske objekte i ukljucuje sve module, limit broja stolova je od 20 do 34.":"Dieses Paket ist für alle mittelgroßen Gastronomiebetriebe geeignet und beinhaltet alle Module, die Tischanzahl ist auf 20 bis 34 begrenzt",
    "Subscription boards created for all types and sizes of caterers":"Abo-Boards für alle Arten und Größen von Caterern",
    "For full expirience please download iTable aplication":"Für eine vollständige Erfahrung laden Sie bitte die iTable-Anwendung herunter"

};