import axios from '../helpers/requests';
import { authConstants } from '../helpers/constants';
import { alertActions } from './alert'
 
export const add = (data, history) => async dispatch => {

    try {

        dispatch(alertActions.page_loader(true));
       
            const response = await axios.post<Object>('/admin/restaurants/store', data)
            console.log(response)
        


        dispatch(alertActions.page_loader(false));

        /*  history.replace('/')  */
    } catch (e: any) {
        dispatch({ type: authConstants.VERIFY_FAILURE, payload: e.toString() });
        dispatch(alertActions.page_loader(false));
    }
};
 
function logout() {
    return { type: authConstants.LOGOUT }
}

export default {
   
    add
    
    
};