import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spinner, Card, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CardItem from '../../common/CardItem';

import axios from '../../../helpers/requests'
import { faEdit, faMinusCircle, faRemoveFormat, faSort } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import EditProfileModal from "../../modals/EditProfileModal";
import { useSelector } from 'react-redux';
import $ from 'jquery'
import { connect, RootStateOrAny } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddRestaurantModal from '../../modals/restaurant/AddRestaurantModal';



const Restaurants: React.FC = (props: any) => {
	const [showEditProfile, setEditProfile] = useState(false);
	const [showAddUserModal, setAddUserModal] = useState(false);

	const { t } = useTranslation()
	const [restaurants, setRestaurants] = useState<any>([]);
	const [tempUser, setTempUser] = useState<any>([]);
	const authState = useSelector((state: RootStateOrAny) => state.auth);

	function roleFormatter(cell, row) {
		console.log(row)
		if (row.roles[0].name === "Admin") {
			return (
				<span>
					<strong style={{ color: 'red' }}>{cell} A</strong>
				</span>
			);
		}

		return (
			<span>{cell}</span>
		);
	}
	const columns = [{
		dataField: 'id',
		text: 'ID'
	}, {
		dataField: 'name',
		text: t('restaurant.Name'),
		filter: textFilter(),
		formater: roleFormatter
	}, {
		dataField: 'addresses[0].city',
		text: t('myprofile.Addresses'),
		filter: textFilter()
	}];
	function makeid(length) {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
		  result += characters.charAt(Math.floor(Math.random() * 
	 charactersLength));
	   }
	   return result;
	}
	function deleteItem(idu) {

		axios.post<Object>('/admin/restaurants/delete', { id: idu }).then((res) => {
 			console.log(res);
		})
		.catch((err) => {
			console.log(err);
		});


		/*  history.replace('/')  */

	}
	function renderModal(u) {

		setTempUser(u)
		setEditProfile(true)

	}
	const expandRow = {
		renderer: row => (
			<div className="row">
				<div className="col-2">
					<img src={row.logo ? row.logo : "/img/restaurant/default.png"} className="p-1 " width="50px" />

				</div>
				<div className="col-8">
				<p><b className="text-warning">{row.name}</b></p>
				<p><b className="text-danger">{row.license_code}</b></p>

				<p><b>{t('restaurant.Full Address')}</b> : {row.addresses[0].street}, {row.addresses[0].postal_code} {row.addresses[0].city}</p>
					<p><b>{t('profile.Email')}</b> : {row.email}</p>
					<p><b>{t('profile.Phone')}</b> : {row.phone}</p>



				</div>
				 

				<div className="col-2">
					<Button onClick={() => renderModal(row)} className="btn mr-2  btn-outline-secondary   btn-sm   border-primary mt-1  btn bg-primary text-white" color="warning" size="sm" >
						<FontAwesomeIcon icon={faEdit} />
					</Button>
					<Button onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteItem(row.id) }} className="btn  btn-outline-secondary   btn-sm   mt-1  btn bg-white text-primary" color="warning" size="sm" >
						<FontAwesomeIcon icon={faMinusCircle} />
					</Button>

				</div>
			</div>
		),
		showExpandColumn: true,
		nonExpandable: []
	};

	const options = {
		onSizePerPageChange: (sizePerPage, page) => {
			console.log('Size per page change!!!');
			console.log('Newest size per page:' + sizePerPage);
			console.log('Newest page:' + page);
		},
		onPageChange: (page, sizePerPage) => {
			console.log('Page change!!!');
			console.log('Newest size per page:' + sizePerPage);
			console.log('Newest page:' + page);
		}
	};

	function getData() {
		axios
			.get("/admin/restaurants")
			.then((res) => {
				setRestaurants(res.data);
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}
	const hideEditProfile = () => {

		setEditProfile(false);
	};
	const hideAddModal = () => {

		setAddUserModal(false);
	};
	useEffect(() => {
		getData();
	}, []);
	return (
		<>

			<div className='p-4 bg-white shadow-sm'>

				<Row>
					 
					<AddRestaurantModal
						code={makeid(9)}
						show={showAddUserModal}
						t={t}
						onHide={hideAddModal}
					/>
					<Col className="p-0 col-6">
						<h4 className="font-weight-bold mt-0 mb-3"> <i className="icofont-restaurant"></i> {t("Restaurants") } { }<span> ({restaurants?.length })</span></h4>
					</Col>
					<Col className="p-0 text-right">
						<Button onClick={()=>setAddUserModal(true)} className="btn  btn-outline-primary   btn-sm   mt-1 text-white btn bg-white" color="warning" size="sm" >
							{t("restaurant.New")}
						</Button>
					</Col>
					<Col md={12} className="p-0">
						<Card>
							<BootstrapTable
								keyField='id'
								className="iTableTable"
								data={restaurants}
								filter={filterFactory()}
								columns={columns}
								expandRow={expandRow}
								pagination={paginationFactory(options)}
							/>
						</Card>
					</Col>







					<Col md={12} className="text-center load-more">
						{restaurants !== [] ? "" : (<Button variant="primary" type="button">
							<Spinner animation="grow" size="sm" className='mr-1' />
							{t("Please wait...")}
						</Button>)}
					</Col>
				</Row>

			</div>
		</>
	);

}
export default Restaurants;