import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import Offers from "./myaccount/Offers";
import Orders from "./myaccount/Orders";
import Favourites from "./myaccount/Favourites";
import Payments from "./myaccount/Payments";
import Addresses from "./myaccount/Addresses";
import EditProfileModal from "./modals/EditProfileModal";
import { useTranslation } from "react-i18next";
import actions from "../action/user";
import actions2 from "../action/auth";
import { myAccHelper } from "../helpers/myAccount";
import { connect, RootStateOrAny, useSelector } from "react-redux";
import axios from '../helpers/requests'
import Dashboard from "./myaccount/admin/Dashboard";
import Users from "./myaccount/admin/Users";
import Restaurants from "./myaccount/admin/Restaurants";
import DishesC from "./myaccount/admin/DishesC";


const MyAccount: React.FC = (props: any) => {
  const authState = useSelector((state: RootStateOrAny) => state.auth);

  let { t } = useTranslation();
  const [user, setUser] = useState([]);
  const [role, setRole] = useState("");
  const [active, setSctive] = useState(0);

  useEffect(() => {
    axios.get<Object>("/user/chck").then((res) => {
      setRole(res.data['role'].toString())
      setSctive(res.data['active'])
      
         })
         .catch((err) => {
           console.log(err);
         }) 
    const user = authState.user;
    if (user) {
      setUser(user);
    } else {
      props.history.replace("/");
    }
  }, []);

  function logoutUser() {
    props.logout();
    localStorage.removeItem("user");
    localStorage.removeItem("auth_token");
    props.history.push("/");
  }

  const [showEditProfile, setEditProfile] = useState(false);

  const hideEditProfile = () => {
    const user = authState.user;

    if (user) {
      setUser(user);
    } else {
      props.history.push("/");
    }
    setEditProfile(false);
  };

  return (
    <>
      <EditProfileModal
        show={showEditProfile}
        user={user}
        t={t}
        onHide={hideEditProfile}
      />
      <section className="section pt-4 pb-4 osahan-account-page">
        <Container>
          <Row>
            <Col md={3}>
              <div className="osahan-account-page-left shadow-sm bg-white h-100">
                <div className="border-bottom p-4">
                  <div className="osahan-user text-center">
                    <div className="osahan-user-media">
                      {user && (
                        <Image
                          className="mb-3 rounded-pill shadow-sm mt-1"
                          src={
                            user["avatar"]
                              ? user["avatar"]
                              : "/img/user/default.png"
                          }
                          alt=""
                        />
                      )}
                      <div className="osahan-user-media-body">
                        <h6 className="mb-2">{user["name"]}</h6>
                        <p className="mb-1">{user["phone"]}</p>
                        <p>{user["email"]}</p>
                        <p className="mb-0 text-black font-weight-bold">
                          <Button
                            className="btn  btn-outline-primary w-50 btn-lg w-50 mt-1 text-white btn bg-white"
                            color="warning"
                            size="sm"
                            onClick={() => setEditProfile(true)}
                          >
                            {t("profile.EDIT")}
                          </Button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="nav flex-column border-0">
                {role  && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/orders"
                    >
                      <i className="icofont-food-cart"></i>{" "}
                      {t("myprofile.History")}
                    </NavLink>
                  </li>)}
                  {role && role === "Admin" && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/admin/dashboard"
                    >
                      <i className="icofont-home"></i>{" "}
                      {t("Dashboard")}
                    </NavLink>
                  </li>
                  
                  )}
                  {role && role === "Admin" && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/admin/restaurants"
                    >
                      <i className="icofont-restaurant"></i>{" "}
                      {t("Restaurants")}
                    </NavLink>
                  </li>
                  )}
                    {role && role === "Admin" && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/admin/disheskat"
                    >
                      <i className="icofont-chef"></i>{" "}
                      {t("Dishes Categories")}
                    </NavLink>
                  </li>
                  
                  )}
                    {role && role === "Admin" && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/admin/drinkskat"
                    >
                      <i className="icofont-beer"></i>{" "}
                      {t("Drinks Categories")}
                    </NavLink>
                  </li>
                  
                  )}
                   {role && role === "Admin" && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/admin/dishes"
                    >
                      <i className="icofont-culinary"></i>{" "}
                      {t("Dishes")}
                    </NavLink>
                  </li>
                  
                  )}
                   {role && role === "Admin" && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/admin/drinks"
                    >
                      <i className="icofont-juice"></i>{" "}
                      {t("Drinks")}
                    </NavLink>
                  </li>
                  
                  )}
                   {role && role === "Admin" && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/admin/users"
                    >
                      <i className="icofont-users"></i>{" "}
                      {t("Users")}
                    </NavLink>
                  </li>
                  
                  )}
                  {role && role === "Customer" && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/favourites"
                    >
                      <i className="icofont-heart"></i>{" "}
                      {t("myprofile.Favourites")}
                    </NavLink>
                    </li>)}
                  {/* <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/payments"
                    >
                      <i className="icofont-credit-card"></i> Payments
                    </NavLink>
                  </li> */}
                 {/*  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/profile/addresses"
                    >
                      <i className="icofont-location-pin"></i>{" "}
                      {t("myprofile.Addresses")}
                    </NavLink>
                  </li> */}
                  {myAccHelper.chck() === "Owner" && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        exact
                        to="/profile/addresses"
                      >
                        <i className="icofont-location-pin"></i>{" "}
                        {t("myprofile.Restaurants")}
                      </NavLink>
                    </li>
                  )}

                  <li className="nav-item text-primary">
                    <Button onClick={logoutUser} className="text-primary nav-link bg-white">
                      <i className="icofont-logout text-primary"></i> {t("myprofile.Logout")}
                    </Button>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={9}>
              <Switch>
                <Route path="/profile/admin/dashboard" exact component={Dashboard} />
                <Route path="/profile/admin/users" exact component={Users} />
                <Route path="/profile/admin/disheskat" exact component={DishesC} />
                <Route path="/profile/admin/restaurants" exact component={Restaurants} />
                <Route path="/profile/orders" exact component={Orders} />
                <Route path="/profile/offers" exact component={Offers} />
                <Route
                  path="/profile/favourites"
                  exact
                  component={Favourites}
                />
                <Route path="/profile/payments" exact component={Payments} />
                <Route
                  path="/profile/addresses"
                  exact
                  component={Addresses}
                />
              </Switch>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  return { state: state.auth };
};

export default connect(mapStateToProps, actions)(MyAccount);
