import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Index from './components/Index';
import Offers from './components/Offers';
import MyAccount from './components/MyAccount';
import List from './components/List';
import NotFound from './components/NotFound';
import NoRest from './components/NoRest';
 import Thanks from './components/Thanks';
import Extra from './components/Extra';
import Login from './components/Login';
import Verify from './components/Verify';
import Register from './components/Register';
import TrackOrder from './components/TrackOrder';
import Invoice from './components/Invoice';
import Checkout from './components/Checkout';
import Detail from './components/Detail';
import ToFar from './components/ToFar';
import Contact from './components/common/Contact';
import Cart from './components/shop/Cart';
import $ from 'jquery';
import Search from './components/home/Search'
import 'font-awesome/css/font-awesome.min.css';
import 'react-select2-wrapper/css/select2.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/animatedhl/css/reset.css';
import './assets/animatedhl/css/style.css';
import './App.css';
import './assets/css/custom.css'
import { RootStateOrAny, TypedUseSelectorHook, useSelector as useReduxSelector  } from 'react-redux';
import QR from './components/QR';
import NoGps from './components/NoGps';
import Payment from './components/shop/Payment';
import Orders from './components/myaccount/Orders';
 

class App extends React.Component  {

  componentDidMount(){
    var animationDelay = 2500,
		//loading bar effect
		barAnimationDelay = 3800,
		barWaiting = barAnimationDelay - 3000, //3000 is the duration of the transition on the loading bar - set in the scss/css file
		//letters effect
		lettersDelay = 50,
		//type effect
		typeLettersDelay = 150,
		selectionDuration = 500,
		typeAnimationDelay = selectionDuration + 800,
		//clip effect 
		revealDuration = 600,
		revealAnimationDelay = 1500;
	
	initHeadline();
	
 	
	function initHeadline() {
		//insert <i> element for each letter of a changing word
		singleLetters($('.cd-headline.letters').find('b'));
		//initialise headline animation
		animateHeadline($('.cd-headline'));
	}

	function singleLetters($words) {
		$words.each(function(){
			var word = $(this),
				letters = word.text().split(''),
				selected = word.hasClass('is-visible');
       
				letters.forEach(i => {
				
		 
				if(word.parents('.rotate-2').length > 0) letters[i] = '<em>' + letters[i] + '</em>';
				letters[i] = (selected) ? '<i class="in">' + letters[i] + '</i>': '<i>' + letters[i] + '</i>';
		 
		});
		    var newLetters = letters.join('');
		    word.html(newLetters).css('opacity', 1);
		});
	}

	function animateHeadline($headlines) {
		var duration = animationDelay;
		$headlines.each(function(){
			var headline =$(this);
			
			if(headline.hasClass('loading-bar')) {
				duration = barAnimationDelay;
				setTimeout(function(){ headline.find('.cd-words-wrapper').addClass('is-loading') }, barWaiting);
			} else if (headline.hasClass('clip')){
				var spanWrapper = headline.find('.cd-words-wrapper'),
					newWidth = spanWrapper.width() + 10
				spanWrapper.css('width', newWidth);
			} else if (!headline.hasClass('type') ) {
				//assign to .cd-words-wrapper the width of its longest word
				var words = headline.find('.cd-words-wrapper b'),
					width = 0;
				words.each(function(){
					var wordWidth = $(this).width();
				    if (wordWidth > width) width = wordWidth;
				});
				headline.find('.cd-words-wrapper').css('width', width);
			};

			//trigger animation
			setTimeout(function(){ hideWord( headline.find('.is-visible').eq(0) ) }, duration);
		});
	}

	function hideWord($word) {
		var nextWord = takeNext($word);
		
		if($word.parents('.cd-headline').hasClass('type')) {
			var parentSpan = $word.parent('.cd-words-wrapper');
			parentSpan.addClass('selected').removeClass('waiting');	
			setTimeout(function(){ 
				parentSpan.removeClass('selected'); 
				$word.removeClass('is-visible').addClass('is-hidden').children('i').removeClass('in').addClass('out');
			}, selectionDuration);
			setTimeout(function(){ showWord(nextWord, typeLettersDelay) }, typeAnimationDelay);
		
		} else if($word.parents('.cd-headline').hasClass('letters')) {
			var bool = ($word.children('i').length >= nextWord.children('i').length) ? true : false;
			hideLetter($word.find('i').eq(0), $word, bool, lettersDelay);
			showLetter(nextWord.find('i').eq(0), nextWord, bool, lettersDelay);

		}  else if($word.parents('.cd-headline').hasClass('clip')) {
			$word.parents('.cd-words-wrapper').animate({ width : '2px' }, revealDuration, function(){
				switchWord($word, nextWord);
				showWord(nextWord);
			});

		} else if ($word.parents('.cd-headline').hasClass('loading-bar')){
			$word.parents('.cd-words-wrapper').removeClass('is-loading');
			switchWord($word, nextWord);
			setTimeout(function(){ hideWord(nextWord) }, barAnimationDelay);
			setTimeout(function(){ $word.parents('.cd-words-wrapper').addClass('is-loading') }, barWaiting);

		} else {
			switchWord($word, nextWord);
			setTimeout(function(){ hideWord(nextWord) }, animationDelay);
		}
	}

	function showWord($word, $duration) {
		if($word.parents('.cd-headline').hasClass('type')) {
			showLetter($word.find('i').eq(0), $word, false, $duration);
			$word.addClass('is-visible').removeClass('is-hidden');

		}  else if($word.parents('.cd-headline').hasClass('clip')) {
			$word.parents('.cd-words-wrapper').animate({ 'width' : $word.width() + 10 }, revealDuration, function(){ 
				setTimeout(function(){ hideWord($word) }, revealAnimationDelay); 
			});
		}
	}

	function hideLetter($letter, $word, $bool, $duration) {
		$letter.removeClass('in').addClass('out');
		
		if(!$letter.is(':last-child')) {
		 	setTimeout(function(){ hideLetter($letter.next(), $word, $bool, $duration); }, $duration);  
		} else if($bool) { 
		 	setTimeout(function(){ hideWord(takeNext($word)) }, animationDelay);
		}

		if($letter.is(':last-child') && $('html').hasClass('no-csstransitions')) {
			var nextWord = takeNext($word);
			switchWord($word, nextWord);
		} 
	}

	function showLetter($letter, $word, $bool, $duration) {
		$letter.addClass('in').removeClass('out');
		
		if(!$letter.is(':last-child')) { 
			setTimeout(function(){ showLetter($letter.next(), $word, $bool, $duration); }, $duration); 
		} else { 
			if($word.parents('.cd-headline').hasClass('type')) { setTimeout(function(){ $word.parents('.cd-words-wrapper').addClass('waiting'); }, 200);}
			if(!$bool) { setTimeout(function(){ hideWord($word) }, animationDelay) }
		}
	}

	function takeNext($word) {
		return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
	}


	function switchWord($oldWord, $newWord) {
		$oldWord.removeClass('is-visible').addClass('is-hidden');
		$newWord.removeClass('is-hidden').addClass('is-visible');
	}
 
  } render() {
    return (
      <>
          {
            (this.props.location.pathname!=='/login' && this.props.location.pathname!=='/norest' && this.props.location.pathname!=='/contacta' && this.props.location.pathname!=='/nogps' && this.props.location.pathname!=='/register'  && this.props.location.pathname!=='/tofar' && this.props.location.pathname.indexOf("/qr/")!==0) ? <Header/>:''
          }
          <Switch>
            <Route path="/" exact component={Index} />
            <Route path="/offers" exact component={Offers} />
            <Route path="/listing" exact component={List} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/profile" component={MyAccount} />
            <Route path="/404" exact component={NotFound} />
            <Route path="/tofar" exact component={ToFar} />
            <Route path="/cart" exact component={Cart} />
            <Route path="/nogps" exact component={NoGps} />
            <Route path="/norest" exact component={NoRest} />
            <Route path="/search" exact component={Search} />
            <Route path="/extra" exact component={Extra} />
            <Route path="/login" exact component={Login} />
            <Route path="/verify" exact component={Verify} />
            <Route path="/register" exact component={Register} />
            <Route path="/orders" exact component={Orders} />
            <Route path="/prices" exact component={Offers} />
            <Route path="/payment" exact component={Payment} />
            <Route path="/track-order" exact component={TrackOrder} />
            <Route path="/invoice" exact component={Invoice} />
            <Route path="/checkout" exact component={Checkout} />
            <Route path="/thanks" exact component={Thanks} />
            <Route path="/shop/:id" exact component={Detail} />
            <Route path="/qr/:qr" exact component={QR} />
            <Route path="/qrtest/:qr" exact component={QR} />
            <Route exact component={NotFound} />
          </Switch>
          {
            (this.props.location.pathname!=='/payment' &&this.props.location.pathname!=='/cart' &&this.props.location.pathname!=='/contact' &&this.props.location.pathname!=='/norest' &&this.props.location.pathname!=='/login' && this.props.location.pathname!=='/nogps' && this.props.location.pathname!=='/tofar' && this.props.location.pathname!=='/register' && this.props.location.pathname.indexOf("/qr/")!==0) ?  <Footer/> :''
          }
      </>
    );
  }
}

export default App;
