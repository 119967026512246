import { faLock, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {Modal,Button} from 'react-bootstrap';
interface MyProps {
    show: boolean;
    onHide?: any;
    id_rest:string,
	qr_code:string,
    t?:any
}

const KonobarModal: React.FC<MyProps> = (props: any) => {
 const [pressed,setPressed]=useState("/img/icons/common/bell.gif")
	const saveData=()=>
	{
		setPressed("/img/icons/common/succes.gif")

	setTimeout(() => {  props.onHide() }, 2000); 
    
   }
   useEffect(() => {
    setPressed("/img/icons/common/bell.gif")
}, [props.show]);
    	return (
	        <Modal 
			backdrop={"static"}
	        	show={props.show} 
	        	onHide={props.onHide}
		        size="lg"
		        centered
		   	  >
			   
			  <Modal.Body>
                <div className=" text-center p-2">
			 <img onClick={()=>{saveData()}} className="radius-primary w-50 shadow w-50 b-2 border-primary" src={pressed}/>
  
				   <p className="mt-4"><i>{props.t("Press bell to call")}</i></p>
               </div>
           
			  </Modal.Body>

			  
			</Modal>
    	);
     
}
export default KonobarModal;