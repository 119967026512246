import React from "react";
import Ripples from 'react-ripples';
// reactstrap components
import { Button, Row } from "reactstrap";

import actions from './../../action/cart'
import { useDispatch, RootStateOrAny } from "react-redux";
import {  TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTrashAlt, faUserCheck, faUserLock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface PackCounterProps {
    dish: any,
    curr:any,
    mt?:number,
    restaurant_id: number,
    hide_addon?: boolean,
    login?:boolean
}
export const useSelector: TypedUseSelectorHook<RootStateOrAny> = useReduxSelector
const PackBoxCounter: React.FC<PackCounterProps> = ({ login=true,curr,dish, restaurant_id, hide_addon = false ,mt=4}) => {
    const {t} = useTranslation();

    const { id: dish_id, price, name, is_veg, addons_dish ,image} = dish
    //Selectors
    const getDishState = store => store.cart.dishes ?? []
    const authState = useSelector((state: RootStateOrAny) => state.auth)

    const findDishById = (store, id) => {
        // const index = getDishState(store).findIndex((dish: any) => dish.id === id)
        // const index = getDishState(store).reduce((result, dish) => {
        //     if (dish.id == id)
        //         result.push(dish.id);
        //     return result
        // }, [])
        // let index: any = []
        // getDishState(store).map((dish: any) => {
        //     if (dish.id == id)
        //         index.push(dish.id);
        // })
        const index = getDishState(store).map((dish, index) => dish.id === id ? index : '').filter(String);
        const findIndex = getDishState(store)[index[0]]
        return  (index.length > 0 && findIndex) ? findIndex.count * index.length : 0
    }

    //Redux Hooks
    const count = useSelector((state: RootStateOrAny) => findDishById(state, dish_id));
    const dispatch = useDispatch();

    const increment = () => {
        if (addons_dish.length > 0) {
             dispatch(actions.addons_show({image, dish_id, name, is_veg, price, restaurant_id, addons_dish,curr }))
        } else {
            const amount = isNaN(price) ? parseFloat(price).toFixed(2) : price
            console.log(amount)
            dispatch(actions.increment({ image,dish_id, name, is_veg, amount, restaurant_id,curr }))
        }

    }

    const decrement = () => {
        if (count > 0)
            dispatch(actions.decrement({ dish_id, restaurant_id }))
    }

    return (
        <>
           {/* {<Row className="pack-box m-0">
                <Ripples className="col-4 p-0">
                    <Button className="pack-btn shadow-none m-0 px-0" onClick={decrement}></Button>
                </Ripples>
                <small className="pack-count col-4 p-0">{count}</small>
                {(hide_addon == false || (addons_dish && addons_dish.length < 1)) && (
                    <Ripples className="col-4 p-0">
                        <Button className="pack-btn-plus alt shadow-none m-0 px-2" onClick={increment}></Button>
                    </Ripples>
                )}
            </Row>
            }{(hide_addon === false && addons_dish && addons_dish.length > 0) && (
                <Row className="m-0 mt-1">
                    <small className="w-100 p-0 text-warning text-center">Addons</small>
                </Row>
            )}    */}

            {(authState.user=="777"? ((hide_addon == false || (addons_dish && addons_dish.length < 1)) ? (
                <>
                   {count===0 && <Row className="pack-box m-0">
                        
                        <Ripples className="col-12 mt-1 p-0">
                            <a className="pack-btn-plus alt shadow-none m-0 px-2" onClick={increment}><FontAwesomeIcon icon={faPlus}/> {t("Add")}</a>
                        </Ripples>
                    </Row> }
                    {count>0 && <span className={"count-number float-right mt-"+mt} id="">
                                               <button   onClick={decrement} className="btn text-primary  r_from_cart btn-sm left dec"> <i className="icofont-minus"></i> </button>
                                               <input   defaultValue={count} className="count-number-input" type="text"  />
                                               <button   onClick={increment} className="btn   a_to_cart btn-sm right inc"> <i className="icofont-plus"></i> </button>
                                            </span> }
                   
                </>
            ) : (
                    <h4 onClick={decrement}><FontAwesomeIcon icon={faTrashAlt} className="text-muted mr-1 align-text-top" /></h4>
                )):     <></> /*   login &&           <a href="/login"> <div className="noLogin"><h4 onClick={decrement}><FontAwesomeIcon icon={faUserLock} className="text-gray mr-1 align-text-top" /></h4>
                   <span className="text-gray  "><i>{t("Please login to order")}</i></span></div></a>
                 */)}
        </>
    )
}

export default PackBoxCounter;