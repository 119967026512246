import React, { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";


import axios from '../helpers/requests';
import { faCartPlus, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { RootStateOrAny, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import Features from './dishes/featured';
import { usePromiseTracker } from 'react-promise-tracker';
import { RootStateOrAny, useSelector } from "react-redux";
import Addons from '../mcards/addons';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Detail: React.FC = (props: any) => {

	const authState = useSelector((state: RootStateOrAny) => state.auth);

	const { promiseInProgress } = usePromiseTracker();
	const [scroll, setScroll] = useState(false);

	const [showAddressModal, setddressModal] = useState(false);
	const [shopId, setShopId] = useState(0);
	const [liked, setLiked] = useState(false);
	const [qr, setQr] = useState(0);
	const [shop, setShop] = useState<any>({});
	const [likedusers, setLikedUsers] = useState([]);
	const [details, setDetails] = useState({} as any);
	const [dishesList, setDishesList] = useState([]);

	const [t] = useTranslation();
	const [address, setAddress] = useState("");
	const [load, setLoad] = useState(false);
	const [featuredDishes, setFeaturedDishes] = useState([]);




	// Get index


	function addToFavorite() {
		if (authState.user) {
			axios.post<Object>('/like', { id: shopId })
				.then((res) => {
					const datares = res['data']
					if (datares === "liked") {
						console.log(datares)

						setLiked(true)
					} else {
						console.log(datares)

						setLiked(false)

					}
				})
				.catch((err) => {
					console.log(err);
					props.history.replace('/404')
				});
		} else {
			props.history.replace("/login")
		}
	}
	function getData() {

		axios
			.get<any>("/restaurant/" + props.match.params.id)
			.then((res) => {
				setShop(res.data)
				setLikedUsers(res.data['liked'])

				if (authState.user) {

					if (res.data['liked'].includes(parseInt(authState.user['id']))) {
						setLiked(true)
					}
				}
				const data = res.data
				console.log(data.dishes)
				setDetails(data);
				setDishesList(data.dishes);

				let FeaturedDishes = data.dishes.filter(function (e) {
					return e.featured === 1;
				});

				setLoad(true);

				const address = data['addresses'][0]
				const add = address.street + ', ' + address.city + ', ' + address.postal_code
				setAddress(add)
				setFeaturedDishes(FeaturedDishes);
			})
			.catch((err) => {
				//console.log(err);
				// props.history.replace('/404')
			});

	}
	const getDishState = store => store.cart.dishes

	// Get index
	const getAllDishes = (store) => {
		const dishes = getDishState(store) ?? []
		let total = 0.00
		let addons_cost = 0.00
		dishes.map((dish) => {

			dish.addons_dish.map((d) => {
				d.addons.map((a) => addons_cost += parseFloat(a.price))
			})
			total += parseFloat(dish.count) * parseFloat(dish.price) + addons_cost
			return total
		})
		return { count: dishes.length, total }
	}

	//Redux Hooks
	const basket_restaurant_id = useSelector((state: RootStateOrAny) => state.cart.restaurant_id);
	const basket = useSelector((state: RootStateOrAny) => getAllDishes(state));
	const currency_symbol = useSelector((state: RootStateOrAny) => state.auth.currency_symbol);

	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 120);
		});

		setShopId(props.match.params.id)
		getData()

	}, []);



	return (
		<>	{shop['name'] ? featuredDishes && (<Row className='p-0 m-0'>

			<div className='col-md-8 col-sm-12 p-0'>
				<section className="section pt-4 mt-5 p-0 products-section bg-gray">
					{promiseInProgress && (
						<div className="spinner">
							<Loader type="ThreeDots" color="#F5365F" height="100" width="100" />
						</div>
					)}

					<Features autoplay={false} items0={2} items765={4} items1200={5} heading={t("home.Featured")} featuredC={true} dishes={featuredDishes} loop={false} latest={false} curr={shop['currencie'].code} history={props.history} />
				</section>
				<section className="section   pb-4 products-section bg-gray">
					{promiseInProgress && (
						<div className="spinner">
							<Loader type="ThreeDots" color="#F5365F" height="100" width="100" />
						</div>
					)}

					<Features curr={shop['currencie'].code} scroll={scroll} autoplay={false} list={true} items0={2} items765={4} items1200={8} heading={""} featuredC={true} dishes={dishesList} loop={true} history={props.history} />
				</section>
				{
					(load && details.id === basket_restaurant_id && basket.count > 0) && (
						<Link className="row fixed-bottom  btncardbottom radius-primary p-3 bg-primary text-white" to="/cart">
							<Col xs="8" className="p-0">
								{basket.count}  |  {basket.total} {details.currencie.code}
							</Col>
							<Col className="text-right p-0">
								{t("Checkout")}
								<FontAwesomeIcon icon={faCartPlus} className="ml-2 align-text-top text-white" />
							</Col>
						</Link>
					)
				}

				<Addons curr={shop['currencie'].code} />
			</div>	<div className='col-md-4 col-sm-12  bg-gray pr-3 pt-4'>
				<section className="pt-md-4 mt-md-5 homepage-shop-block position-relative">

					<div className="restaurant-baner">


						<img className="banner-img " src={shop['image']} />
						<img className="logo-shop " src={shop['logo']} />


						<div className="restaurant-detailed-header">
							<div className="details-shop">

								<div className="row d-flex align-items-end p-0 m-0">
									<div className="col-12">
										<div className="restaurant-detailed-header-left">

											<h1 className="text-white">{shop['name']}

											</h1>




											<a className="address btn-white-transparent text-white ml-1  text-white mb-1 mt-1 fs-20">

												<p className="mr-1 address btn-white-transparent text-white ml-1 text-white mb-1 mt-1 fs-20 "> <FontAwesomeIcon className="mr-1" icon={faMapMarkedAlt} />  {address}</p>
											</a>



										</div>
									</div>
									<div className="col-12">
										<div className="restaurant-detailed-header-right text-right">



											<a onClick={() => { addToFavorite() }} className="btn btn-white-transparent ml-2 "><i className={"icofont-heart " + (liked ? " text-primary" : " text-white")}></i></a>
											<button className="btn btn-white-transparent text-white ml-2 pull-right" data-toggle="modal" data-target="#infomodal">
												<i className="icofont-info"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				

				</section>
				
				<div className="w-100">

{/* <FeaturedMenu restaurant={details} menuList={featuredDishes} restaurant_id={details.id} /> */}
{shop['slides'].map((slide) => {

	return <div className="mt-4 baners-on-shop">
		<img className="banner-img-shop  " src={slide['images']} />
		<div className='overlay p-2'>
			<h4>
				{slide['name']}
			</h4>
			<p>
				{slide['desc']}
			</p>
		</div>
	</div>
})
}
</div>
				</div></Row>) : <></>}
		</>
	);

}


export default Detail;