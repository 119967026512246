export const TRANSLATIONS_SR = {
    "Details":"Detalji",
     "Please wait...":"Molimo sačekajte...",

    "welcome": {
        "title": "Pozdrav od {{framework}}",
        "best of": "najbolje od",
        "pizzerias":"picerija",
        "caterers":"ugostitelja",
        "restaurants":"restorana",
        "caffes":"kafića",
        "clubs":"klubova",
        "Call the WAITER, see digital MENU, order through the APP, see the table current BALANCE ...":"Pozovi KONOBARA, vidi MENI, naruči preko APLIKACIJE, vidi trenutno STANJE stola ...",
        "Please turn on GPS to get location":"Molimo ukljucite GPS da bi nastavili koristiti iTable"

    },
   
    "profile":{
        "Phone number":"Broj telefona",
        "Cancel":"Odustani",
        "Update":"Snimi",
        "Email":"Email",
        "Password":"Lozinka",
        "EDIT":"IZMJENI",
        "Profile":"Profil",
        "Total Users":"Ukupno Korisnika",

        "Today Orders":"Danas",
        "7 Days":"7 Dana",
        "Today Earnings":"Današnja zarada",
        "Total Orders":"Ukupno narudžbi",
        "Total Events":"Ukupno događaja",
        "Total Banners":"Ukupno plakata",
        "Your Banners":"Tvoji plakati",
        "More info":"Detaljnije",
        "Total Restaurants":"Ukupno objekata",
        "Featured Caffes":"Preporučeni kafići",
        "Featured Dishes":"Preporučena jela",
        "Featured Categories":"Preporučene kategorije",
        "Featured Restauraunts":"Preporučeni restorani",
        "Featured Clubs":"Preporučeni klubovi",
        "Featured Pizzerias":"Preporučenr picerije",
        "Caffe":"Kafić",
        "Dishes":"Jela",
        "Drinks":"Pića",
        "Restauraunt":"Restoran",
        "Club":"Klub",
        "Category":"Kategorija",
        "Categories":"Kategorije",
        "Price":"Cijena",
        "Active":"Aktivan",
        "Add User":"Novi Korisnik",
        "Action":"Akcija",
        "List of Dishes":"Lista jela",
        "List of Drinks":"Lista pića",
        "List of Addons":"Lista dodataka",
        "Addons Categories":"Kategorije dodataka",
        "Dish Categories":"Kategorije jela",
        "Drink Categories":"Kategorije pića",
        "Pizzeria":"Picerija",
        "Addons":"Dodaci",
        "Live Orders":"Narudžbe uživo",
        "This Week":"Ove sedmice",
        "View Report":"Pogledaj izvještaj",
        "Cake shop":"Slastičarna",
        "Last 7 days orders":"Narudžbe zadnjih 7 dana",
        "Total Earnings":"Ukupna zarada",
        "Distance":"Udaljenost",
        "DistanceInfo":"Max. udaljenost za pokazivanje objekata u tvojoj blizini",
        "Full Name":"Korisnicko ime",
        "Phone":"Telefon",
        "Language":"Jezik",
        "My Order":"Moje narudzbe",
        "Notifications":"Obavještenja",
        "Logout":"Odjavi se"

    },
    "login":{
        "Sign in to start your session":"Prijavite se",
        "Password":"Lozinka",
        "Sign in":"Prijavi se",
        "Sign up":"Registruj se",
        "Continue as guest":"Nastavi kao gost",
        "Skip":"Preskoči",
        "Don't have an account?":"Nemate nalog?",
        "Forgot Password?": "Zaboravljena lozinka?",
        "Remember Me": "Zapamti me",
        "Please wait...": "Molimo sačekajte..."

    },
    "nav":{
        "Home":"Početna",
        "Explore":"Istraži",
        "Orders":"Istorija",
        "Profile":"Profil"
    },
    "home":{
        "New":"Novo",
        "Discover Featured Places":"Popularno",
        "Featured":"Popularno",
        "Near you":"U blizini 🗺 🧭 📍",
        "Top Categories":"Popularne kategorije ⭐️ 🔝 💫 "

    },
    "shop":{
        "Featured":"Preporučeno",
        "Search Results":"Rezultati pretrage",
        "Recommended for you":"Recommended for you",
        "No featured dishes available":"Nema preporučenih artikala",
        "Articles":"Artikli",
        "No articles":"Nema artikala",
        "Search for Restaurant and Dishes":"Pretraga po objektu ili artiklima..."
         
    },
    "restaurant":{
        "Name":"Naziv",
        "New":"Novi restoran",
        "License Code":"LICENCA",
        "Description":"Kratak opis",
        "Add New Restaurant":"Novi restoran",
        "WiFi password":"WiFi lozinka",
        "Phone":"Telefon",
        "Email":"Email",
        "Web":"Web sajt",
        "Open":"Otvoreno",
        "Close":"Zatvoreno",
        "Work days":"Radni dani",
        "Work time":"Radno vrijeme",
        "Weekend":"Vikend",
        "Hours":"Sati",
        "Minutes":"Minute",
        "Zip Code":"Poštanski kod",
        "City":"Grad",
        "Lat":"Širina",
        "Lng":"Dužina",
        "Featured":"Preporučen",
        "Full Address":"Puna Adresa",
        "Open Hours":"Radni sati",
        "Open Working Hours":"Radni Sati",

    },
    "myprofile":{
        "History":"Istorija",
        "Favourites":"Favoriti",
        "Addresses":"Adrese",
        "Logout":"Odjavi se"
            }
    ,
    "Become iTable Member":"Postani iTable član porodice",
    "Become iTable member and start experiencing the world of CAFES and RESTAURANTS in a different way":"Postani 😎 iTable član porodice 😎    i doživi  🌍  svijet KAFIĆA i RESTORANA na potpuno drugi način ❤️❤️❤️",
    "Operate caffe or restaurant?":"Imate ugostiteljski objekt ?",
    "Work With Us":"Kontaktirajte nas",
    "Create an Account":"Kreiraj nalog",
    "Download app": "Preuzmi aplikaciju",

    "About us": "O nama",
    "Contact": "Kontakt",
    "Careers": "Karijera",
    "Prices": "Cijene paketa",
    "iTable Modules": "iTable Moduli",
    "See digital MENU": "Vidi digitalni meni",
    "Call the WAITER": "Pozovi konobara",
    "Current BALANCE": "Trenutno stanje stola",
    "Ask for BILL": "Zatraži račun keš/kartica",
    "For Restaurants": "Za ugostitelje",
    "Advertise": "Reklama na iTable",
    "Free WEB page on iTable": "Besplatna WEB stranica na iTable",
    "Live statistic": "Uživo statistika",
    "Track working hours ": "Radno vrijeme radnika",
    "Satisfied customers": "Zadovoljne mušterije",
    "Welcome back!":"Dobrodošli!",
    "Email / Phone":"Email ili Telefon",
    "Meni":"Meni",
    "Stanje":"Stanje",
    "Bill":"Račun",
    "Invalid Login Credentials!":"Netačni login podaci",
    "Verify Phone!":"Verifikuj telelefon!",
    "Password must be minimum 6 char long":"Lozinka mora biti minimalno 6 karaktera dužine",
    "Profile Picture":"Profilna slika",
    "To save plase click Update":"Za snimanje pritisnite \"Snimi\"",
    "Click on image to change":"Kliknite sliku za promjenu",
    "Placed":"Kreirana",
    "Finished":"Dostavljena",
    "Accepted":"Prihvaćena",
    "In progres":"U pripremi",
    "On the way":"Stiže",

    "Users":"Korisnici",
    "Dashboard":"Početna",
    "Created":"Kreiran",
    "Currencie":"Valuta",
    "Email Verified":"Email Verifikovan",
    "Phone Verified":"Telefon Verifikovan",
    "Edit User":"Izmjena",
    "Restaurants":"Restorani",
    "Dishes":"Jela",
    "Drinks":"Pića",
    "Dishes Categories":"Kategorije Jela",
    "Drinks Categories":"Kategorije Pića",
    "New Category":"Nova kategorija",
    "Add":"Dodaj",
    "Item":"Artikl",
    "Articls":"Artikla",
    "ITEM IN CART":"Stavke",
    "Checkout":"Nastavi",
    "Grand Total":"Ukupno",
    "Your order placed successfully":"Vaša narudžba je uspješno poslata",
    "Go to Orders":"Idi u narudžbe",
    "Select your payment method:":"Način plaćanja :",
    "Cash on Delivery":"Plaćanje gotovinom na odlasku",
    "COD":"GOTOVINA",
    "CARD":"KARTICA",
    "Language":"Jezik",
    "Pay with card":"Naznačite da ćete platiti karticom na odlasku",
    "Please login to order":"Za narudžbu je potrebna prijava",
    "You have to pay":"Za platiti",
    "Call waiter":"Poziv konobara",
    "Close":"Zatvori",
    "To change language, please click on flag.":"Za promjenu jezika izaberite odgovarajuću zastavicu.",
    "Current state for this table is:":"Trenutno STANJE stola je :",
    "Try Again":"Pokušajte ponovo",
    "Action is disabled.":"Akcija je onemogućena od strane objekta.",
    "Press bell to call":"Pritisnite zvono za poziv konobara",
    "Time for action is limited to 5min. Please scann iTable code again.":"Vaš iTable KOD je istekao.Molimo vas da skenirate ponovo iTable KOD.",
    "This QR code no exist in our system.":"QR kod ne postoji u iTable bazi objekata.",
    "Register error":"Greska prilikom registracije, provjerite unesene podatke da li su ispravni i da isti već ne postoje u sistemu.",
    "Your cart is empty":"iTable narudžba je prazna",
    "Add something to let us start working":"Vrati se na ponudu i odaberi artikle",
    "Please scan iTable QR code ":"Molimo skenirajte iTable QR kod na stolu",
    "Home":"Početna",
    "Search":"Pretraga",
    "Notifications":"Obavještenja",
    "Profile":"Profil",
    "You have no new Notifications":"Nemate novih obavještenja",
    "Your order":"Vaša narudžba",
    "is placed successfully" : "je poslata uspješno",
    "Recent Notifications":"Obavještenja",
    "is Accepted":"je prihvaćena",
    "is in progress":" je u pripremi",
    "is prepared and will be picked up by a Delivery.":" je u pripremi.",
    "is on the way.":" ja u putu prema vama.",
    "is finished successfully":" je uspješno završena.",
    "Page not found":"Stranica nije dostupna",
    "Uh-oh! Looks like the page you are trying to access, doesn't exist. Please start afresh.":"Upppps! Stranica nije dostupna, molimo pokusajte ponovo.",
    "Restaurant has ": "Klijent ima još ",
    " more dishes" :    " artikala u kategoriji" ,
    "GPS location is OFF":"GPS lokacija je ugašena",
    "GPS location is OFF":"GPS location is OFF",
    "Please enable your GPS to use  ":"Ukljčite GPS lokaciju da koristite  ",
    "application.": "aplikaciju.",
    "Please enter your name":"Molimo unesite ime",
    "Please check your GPS and try again" :  "Provjerite GPS postavke i pokušajte ponovo" ,
    "new":"Novo 🎉🆕",
    "Continue":"Nastavi",
    "Plans":"Paketi",
    "Please enter your name":"Molimo unesite ime",
    "Name":"Ime / Naziv firme",
    "Please enter a valid email address":"Unesite ispravnu email adresu",
    "Please enter phone":"Molimo da unesete vas telefonski broj",
    "Phone":"Telefon/Mob",
    "City":"Grad",
    "Please enter your city":"Unesite grad",
    "Please enter a subject":"Molimo unesite temu",
    "Subject":"Tema",
    "Message":"Poruka",
    "Please enter a message":"Unesite poruku",
    "Submit":"Pošalji",
    "Statistic":"Statistika",
    "iTable location":"iTable lokacija",
    "New":"Novo",
    "About Us": "O nama",
    "COUNTRIES": "Države",
    "Contact us":"Kontaktiraj nas",
    "Ovaj paket je pogodan za sve velike ugostiteljske objekte i ukljucuje sve module, NEMA limita i kreće od 49+ stolova":"Ovaj paket je pogodan za sve velike ugostiteljske objekte i ukljucuje sve module, NEMA limita i kreće od 49+ stolova",
    "Ovaj paket je pogodan za sve veće ugostiteljske objekte i ukljucuje sve module, limit broja stolova je od 35 do 49.":"Ovaj paket je pogodan za sve veće ugostiteljske objekte i ukljucuje sve module, limit broja stolova je od 35 do 49.",
    "Ovaj paket je pogodan za sve srednje ugostiteljske objekte i ukljucuje sve module, limit broja stolova je od 20 do 34.":"Ovaj paket je pogodan za sve srednje ugostiteljske objekte i ukljucuje sve module, limit broja stolova je od 20 do 34.",
    "Ovaj paket je pogodan za sve manje ugostiteljske objekte i ukljucuje sve module sa limitom do maksimalno 19 stolova.":"Ovaj paket je pogodan za sve manje ugostiteljske objekte i ukljucuje sve module sa limitom do maksimalno 19 stolova.",
    "Created for everyone":"Kreirano za sve ugostitelje",
    "Subscription boards created for all types and sizes of caterers":"Paketi pretplata za sve tipove i veličine ugostitelja!",
    "For full expirience please download iTable aplication":"Za vise opcija preuzmite iTable aplikaciju"















}