import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OwlCarousel from 'react-owl-carousel3';
import Checker from '../../helpers/checker'

import "../../i18n";
 import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { propTypes } from "react-bootstrap/esm/Image";

type ListProps = {
  history?: any,
   slides: any,
  heading:string
}
 
const TopSearch: React.FC<ListProps> = ({ history,slides,heading }) => {

  /* const [center, setCenter] = useState({ lat: -34.397, lng: 150.644 });*/
   const { t } = useTranslation();
     


  return (
    <section className="pb-4 homepage-search-block position-relative">
      <Checker history={history} />
      <div className="banner-overlay"></div>
      <Container>
        <Row className="d-flex align-items-center">
          <Col md={7} className="pt-4">
            <div className="homepage-search-title">
              <h1 className="cd-headline myhead rotate-1">
                <span><span className="firstL">i</span>Table {t("welcome.best of")} </span>
                <span className="cd-words-wrapper">
                  <b className="is-visible">{t("welcome.restaurants")}</b>
                  <b>{t("welcome.caffes")}</b>
                  <b>{t("welcome.clubs")}</b>
                  <b>{t("welcome.pizzerias")}</b>
                </span>
              </h1>
              <h5 className="mb-5 text-secondary font-weight-normal">
              {t("welcome.Call the WAITER, see digital MENU, order through the APP, see the table current BALANCE ...")}
              </h5>
            </div>
         {/*    <div className="homepage-search-form">
              <Form className="form-noborder">
                <div className="form-row">
                  <Form.Group className="col-lg-7 col-md-7 col-sm-12">
                    <Form.Control
                      type="text"
                      placeholder="Enter your delivery location"
                      size="lg"
                    />
                    <Link onClick={watchPosition} className="locate-me" to="#">
                      <Icofont icon="ui-pointer" /> Locate Me
                    </Link>
                  </Form.Group>
                  <Form.Group className="col-lg-2 col-md-2 col-sm-12">
                    <Link
                      to="listing"
                      className="btn btn-primary btn-block btn-lg btn-gradient"
                    >
                      Search
                    </Link>
                  </Form.Group>
                </div>
              </Form>
            </div> */}
           {/*  <h6 className="mt-4 text-shadow font-weight-normal">
              E.g. Beverages, Pizzas, Chinese, Bakery, Indian...
            </h6>
            <CategoriesCarousel /> */}
          </Col>
           <Col className="p-2" md={5}>
             
            {slides.length > 0 && (
              <OwlCarousel  margin={10}  {...options2} className="homepage-ad owl-theme">
                {slides.map((slide: any) => {
                  const { id, name, desc, images, typeId, type ,ownerId} = slide;
                  if(ownerId ===0){
                  return (
                    <img className="radius-primary" key={id}
                       src={
                        "https://api.itable.app/storage/img/restaurants/" + images
                      }
                    />
                  );
                    }
                })}
              </OwlCarousel>
            )}
          </Col>  
        </Row>
      </Container>
    </section>
  );
};
 
const options2 = {
  responsive: {
    0: {
      items: 1,
    },
    764: {
      items: 1,
    },
    765: {
      items: 1,
    },
    1200: {
      items: 1,
    },
  },
  lazyLoad: true,
  loop: true,
  autoplay: true,
  autoplaySpeed: 2000,
  dots: false,
  autoplayTimeout: 3000,
  nav: false,
  navText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>",
  ],
  autoplayHoverPause: true,
};  

export default TopSearch;
