import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CardItem from '../../common/CardItem';
import axios from '../../../helpers/requests'
const Dashboard: React.FC = (props: any) => {

	const { t } = useTranslation()
	const [statistic, setStatistic] = useState<any>([]);
	function getData() {
		axios
			.get("/admin/dashboard")
			.then((res) => {
				setStatistic(res.data);
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}
	useEffect(() => {
		getData();
	}, []);
	return (
		<>
			<div className='p-4 bg-white shadow-sm'>
				<Row>
					<Col md={12}>
						<h4 className="font-weight-bold mt-0 mb-3">{t("Dashboard")}</h4>
					</Col>
					<Col md={4}>
						<div className="card text-white bg-primary mb-3">
							<div className="card-header text-info">{t("profile.Total Restaurants")}</div>
							<div className="card-body">
								<h3 className="card-title text-info">{statistic?.all_restaurants}</h3>
								<p className="card-text"></p>
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className="card text-white bg-primary mb-3">
							<div className="card-header text-info">{t("profile.7 Days")}</div>
							<div className="card-body">
								<h3 className="card-title text-info">{statistic?.last_seven_days_restaurant}</h3>
								<p className="card-text"></p>
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className="card text-white bg-primary mb-3">
							<div className="card-header text-info">{t("profile.Today Orders")}</div>
							<div className="card-body">
								<h3 className="card-title text-info">{statistic?.today_restaurants}</h3>
								<p className="card-text"></p>
							</div>
						</div>
					</Col>

					<Col md={4}>
						<div className="card text-white bg-primary mb-3">
							<div className="card-header text-primary">{t("profile.Total Users")}</div>
							<div className="card-body">
								<h3 className="card-title text-primary">{statistic?.all_users}</h3>
								<p className="card-text"></p>
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className="card text-white bg-primary mb-3">
							<div className="card-header text-primary">{t("profile.7 Days")}</div>
							<div className="card-body">
								<h3 className="card-title text-primary">{statistic?.last_seven_days_users}</h3>
								<p className="card-text"></p>
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className="card text-white bg-primary mb-3">
							<div className="card-header text-primary">{t("profile.Today Orders")}</div>
							<div className="card-body">
								<h3 className="card-title text-primary">{statistic?.today_users}</h3>
								<p className="card-text"></p>
							</div>
						</div>
					</Col>


					<Col md={4}>
						<div className="card text-white bg-primary mb-3">
							<div className="card-header text-success">{t("profile.Total Events")}</div>
							<div className="card-body">
								<h3 className="card-title text-success">{statistic.all_orders?.length}</h3>
								<p className="card-text"></p>
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className="card text-white bg-primary mb-3">
							<div className="card-header text-success">{t("profile.7 Days")}</div>
							<div className="card-body">
								<h3 className="card-title text-success">{statistic?.last_seven_days_orders_count}</h3>
								<p className="card-text"></p>
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className="card text-white bg-primary mb-3">
							<div className="card-header text-success">{t("profile.Today Orders")}</div>
							<div className="card-body">
								<h3 className="card-title text-success">{statistic.today_orders?.length}</h3>
								<p className="card-text"></p>
							</div>
						</div>
					</Col>







					<Col md={12} className="text-center load-more">
						{statistic !== [] ? "" : (<Button variant="primary" type="button">
							<Spinner animation="grow" size="sm" className='mr-1' />
							{t("Please wait...")}
						</Button>)}
					</Col>
				</Row>

			</div>
		</>
	);

}
export default Dashboard;