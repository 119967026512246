import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, Image, Media } from "react-bootstrap";
import Icofont from "react-icofont";
import Ripples from "react-ripples";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faInfoCircle, faMoneyBillAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";

interface MyProps {
  id: string,
  image: string;
  type: string;
  imageAlt: string;
  history?: any;
  deliveredDate: string;
  orderTitle: string;
  orderDate: string;
  status: string;
  address: string;
  orderProducts: string;
  orderNumber: string;
  helpLink: string;
  detailLink: string;
  orderTotal: string;
  onEditClick?: any;
  onDeleteClick?: any;
  t?: any;
}
const OrderCard: React.FC<MyProps> = (props: any) => {
  const [statusColor, setStatusColor] = useState("")

  useEffect(() => {
    switch (props.status) {
      case "Placed":
        setStatusColor("warning")
        break;  
        case "Accepted":
        setStatusColor("info")
        break;
        case "On the way":
          setStatusColor("info")
          break;
        case "In progres":
        setStatusColor("info")
        break;
      case "Finished":
        setStatusColor("success")
        break;
      default:
        setStatusColor("primary")
    }

  }, [])
  return (
    <div className="col-md-4 p-0 pr-2 pl-2">
      <Ripples
        className={"border-primary d-block myblock border mb-3 shadow"}
        onClick={() => {
          setTimeout(function () {
            props.history.push(props.detailLink);
          }, 275);
        }}
      >

        <div className="bg-white  card p-0 order-list shadow-sm col-12 ">
          <div className="gold-members p-2 ">
            <div className="media">
              <img src={props.image} alt="" className="mr-4 radius-primary" />
              <div className="media-body">
                <div className="row">
                  <div className="col-12 p-0">
                    <div className={"" + " row  text-white p-2 mb-2 mr-1 ml-0 text-right"}>

                     
                      <p className="w-50 mb-0 text-white">

                         </p>
                      <p className={"w-50 mb-0  text-right text-white"}>
                        <span className={" w-100 p-1 pl-2 pr-2 m-0 radius-primary "+" bg-"+statusColor}> <i>{props.t(props.status)}</i>
                        <FontAwesomeIcon className="ml-2" icon={props.type === "COD" ? faMoneyBillAlt : (props.type === "CARD" ? faCreditCard : faUserCircle)} />{" "}
                     </span>
</p>
                    </div>
                    <h6 className="mb-2">

                      {props.orderTitle}{" "}

                    </h6>
                    <p className="mb-2">

                      {props.address}{" "}

                    </p>

                  </div>

                </div>


              </div>
            </div>
            <div className="media border-top mt-2">

              <div className="media-body">

                <div className="row">
                  <div className="col-8">
                    <h6 className="mt-2">


                    </h6>


                    <p className="text-gray mb-1"> {props.orderProducts.length > 120 ? props.orderProducts.substring(0, 120) + " ..." : props.orderProducts}</p>
                    <p className="mt-2">                    <span className="text-left text-gray"><i>{props.deliveredDate}</i></span>
                    </p>
                  </div>
                  <div className="col-4 ">
                    <p className={"text-" + statusColor + " mb-1 price-art"}>
                      {props.orderTotal}
                    </p>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Ripples>
    </div>
    /*  <div className="bg-white card mb-4 order-list shadow-sm">
            <div className="gold-members p-4">
                  <Media>
                     <Image className="mb-3 imageorder shadow-sm mt-1 w-25" src={props.image} alt={props.imageAlt} />
                     <Media.Body>
                          {props.deliveredDate?
                            (<>
                   <p>
                     
                     <span className="float-right">{props.t(props.status)}  
                     <Icofont icon="check-circled" className={props.status==="Delivered"?"text-success":"" + props.status==="ORDER PLACED"?"text-warning":"" +" ml-1"} />
                   </span>
                   </p>
                   <p>
                     
                  <span className="float-right">{props.deliveredDate}  
                  <Icofont icon="clock-time" className=" ml-1" />
                </span>
                </p>
              	
              </>
              	
                          )
                          :""
                          }
                        <h6 className="mb-2">
                          <Link to={props.detailLink}  className="text-black">{props.orderTitle} </Link>
                        </h6>
                        <p className="text-gray mb-1">
                          <Icofont icon="location-arrow" /> {props.address} 
                        </p>
                        <p className="text-gray mb-3">
                          <Icofont icon="list" />{props.orderNumber} 
                          <Icofont icon="clock-time" className="ml-2" /> {props.orderDate} 
                        </p>
                        <p className="text-dark">
                          {props.orderProducts} 
                        </p>
                        <hr />
                        <div className="float-right">
                          <Link className="btn btn-sm btn-outline-primary mr-1" to={props.helpLink}><Icofont icon="phone-alt" /> HELP</Link>
                          
                        </div>
                        <p className="mb-0 text-black text-primary pt-2">
                          <span className="text-black font-weight-bold"> Total Paid:</span> {props.orderTotal}
                        </p>
                     </Media.Body>
                  </Media>
            </div>
         </div> */
  );
};

export default OrderCard;
