import React, { Component, useEffect, useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

import { connect } from "react-redux";
import actions from '../../../action/user';
interface MyProps {
    show?: boolean;
    onHide?: any;
    onClick?:any;
    center?:any;
    onMapIdle?:any;
    t?:any
}


const Map: React.FC<MyProps> = (props: any) => {
  const [center, setCenter] = useState({lat: 11.0168, lng: 76.9558 });
 const [zoom, setZoom] = useState(11);

    useEffect(() => {
setCenter(props.center)
    },[])
    function handleClick(event) {
        
        console.log(event)
        var lat = event.lat
        setCenter({lat:event.lat,lng:event.lng})
         var lng = event.lng
         let position = {lat:lat,lng:lng}
        props.onClick(position)
        }
    return (
        <div style={{ height: '300px', width: '100%' }}>           
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyC8NydMkPVNT4AsZOUolwFcQn6XVm-Q-YQ' }}
          defaultCenter={props.center}
          center={center}
          options={{
            mapTypeId: 'hybrid',
            styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: true,
            fullscreenControl: false,
            minZoom: 2,
            maxZoom: 30
          }}
          onClick={(e)=>{handleClick(e)}}
          defaultZoom={zoom}
        >
           <Marker
            lat={center.lat}
            lng={center.lng}
            name=""
            color="blue"
          />
        </GoogleMapReact>
      </div>
    );
   
}

const mapStateToProps = (state) => {
    return { state: state.auth }
 }
 
 export default connect(mapStateToProps, actions)(Map);