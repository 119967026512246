import React, { useState } from "react";
import {Link} from 'react-router-dom';
import {Row,Col,Container,Form,Button, InputGroup, FormGroup, Alert} from 'react-bootstrap';
 import {useTranslation} from "react-i18next";
 import axios  from "../helpers/requests";
import { connect } from "react-redux";
import actions from '../action/auth';
import { Input, InputGroupAddon, InputGroupText, UncontrolledAlert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faUser } from "@fortawesome/free-solid-svg-icons";

const Login: React.FC = (props: any) => {
	const [t] = useTranslation();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	function getAlert (){
		  return (<Alert color="warning">
		<strong>{t(props.state.errorMsg)}</strong>
	</Alert>)
	}
	const onSubmit = async (e) => {
		e.preventDefault()
		props.login({ email, password }, props.history)

		
	}
    	return (
    	  <Container fluid className='bg-white'>
	         <Row>
	            <Col md={4} lg={6} className="d-none d-md-flex bg-image"></Col>
	            <Col md={8} lg={6}>
	               <div className="login d-flex align-items-center py-5">
	                  <Container>
	                     <Row>
	                        <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
	                           <h3 className="login-heading mb-4">{t("Welcome back!")}</h3>
	                           <Form role="form" onSubmit={onSubmit}>
							   <FormGroup>
										<InputGroup className="input-group-alternative mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
												<FontAwesomeIcon icon={faUser} className="ni ni-hat-3" />
												</InputGroupText>
											</InputGroupAddon>
											<Input placeholder={t("Email / Phone")} type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} required />

										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup className="input-group-alternative">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
												<FontAwesomeIcon icon={faLock} className="ni ni-hat-3" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder={t("Password")} 
												type="password"
												name="password"
												autoComplete="off"
												value={password} onChange={e => setPassword(e.target.value)} required />
										</InputGroup>
									</FormGroup>
									{props.state.errorMsg && (
										getAlert()
									)}
									<div className="text-center">
	                           	<Button
											
											className="btn  btn-outline-secundary w-50 btn-lg w-50 mt-1 text-white btn bg-white"
											color="warning"
											size="lg"
											type="submit"
										>
											{t("login.Sign in")}
                          			</Button>
									  </div>
	                              <div className="text-center pt-3">
								  {t("login.Don't have an account?")} <Link className="font-weight-bold" to="/register">{t("login.Sign up")}</Link>
	                              </div>
		                           <hr className="my-4" />
		                          
		                         
	                           </Form>
	                        </Col>
	                     </Row>
	                  </Container>
	               </div>
	            </Col>
	         </Row>
	      </Container>
    	);
    }

	const mapStateToProps = (state) => {
		return { state: state.auth }
	}
	
	export default connect(mapStateToProps, actions)(Login);
	