export const TRANSLATIONS_SL = { 
    "Details": "Podrobnosti",

    "Please wait...": "Prosim Počakaj...",

    "welcome": {
        "title": "Dobrodošli v {{framework}}",

        "best of": "Najboljše",

        "pizzerias": "Picerije",

        "caterers": "Gostinci",

        "restaurants": "Restavracije",

        "caffes": "Kavarne",

        "clubs": "Klubi",

        "Call the WAITER, see digital MENU, order through the APP, see the table current BALANCE ...":
            "Pokliči NATAKARJA, ogled digitalnega MENIJA, naroči preko APLIKACIJE, ogled trenutnega STANJA mize ...",

        "Please turn on GPS to get location":
            "Prosim vklopite GPS za pridobitev lokacije",
    },

    "profile": {
        "Phone number": "Telefonska številka",

        "Cancel": "Prekliči",

        "Update": "Posodobi",

        "Email": "Email",

        "Password": "Geslo",

        "EDIT": "UREDI",

        "Profile": "Profil",

        "Today Orders": "Današnja naročila",

        "7 Days": "7 Dni",

        "7 Users": "7 Uporabnikov",

        "Today Earnings": "Današnji zaslužek",

        "Total Orders": "Vsa naročila",

        "Total Users": "Vsi uporabniki",

        "Total Restaurants": "Vse restavracije",

        "Total Events": "Vsi dogodki",

        "Total Banners": "Vsi oglasi",

        "Your Banners": "Tvoj oglas",

        "More info": "Več informacij",

        "Featured Caffes": "Značilne kave",
        "Total Restaurants":"Ukupno objekata",

        "Featured Dishes": "Značilne jedi",

        "Featured Categories": "Značilne kategorije",

        "Featured Restauraunts": "Priporočene restavracije",
        "Featured Caffes":"Preporočeni gostinski lokali",
        "Featured Clubs": "Priporočeni klubi",

        "Featured Pizzerias": "Proporočene picerije",

        "Caffe": "Kave",

        "Dishes": "Jedi",

        "Add User": "Dodaj uporabnika",

        "Drinks": "Pijače",

        "Restauraunt": "Restavracija",

        "Club": "Klub",

        "Category": "Kategorija",

        "Categories": "Kategorije",

        "Price": "Cena",

        "Active": "Aktivno",

        "Action": "Akcija",
        "Add User":"Novi Uporabnik",
        "List of Dishes": "Seznam jedi",

        "List of Drinks": "Seznam pijač",

        "List of Addons": "Seznam dodatkov",

        "Addons Categories": "Kategorije dodatkov",

        "Dish Categories": "Kategorije jedi",

        "Drink Categories": "Kategorije pijač",

        "Pizzeria": "Picerija",

        "Addons": "Dodatki",

        "Live Orders": "Aktivna naročila",

        "This Week": "Ta teden",

        "View Report": "Ogled poročila",

        "Cake shop": "Slaščičarna",

        "Last 7 days orders": "Naročila zadnjih 7 dni",

        "Total Earnings": "Skupni zaslužek",

        "Distance": "Oddaljenost",

        "DistanceInfo": "Max. oddaljenost za prikaz predmetov",

        "Full Name": "Polno ime",

        "Phone": "Telefon",

        "Language": "Jezik",

        "My Order": "Moje naročilo",

        "Notifications": "Obvestila",

        "Logout": "Odjava",
    },

    "login": {
        "Sign in to start your session": "Prijavite se",

        "Password": "Geslo",

        "Sign in": "Prijava",

        "Sign up": "Vpis",

        "Continue as guest": "Nadaljuj kot gost",

        "Skip": "Preskoči",

        "Don't have an account?": "Nimate računa?",

        "Forgot Password?": "Ste pozabili geslo?",

        "Remember Me": "Zapomni si me",

        "Please wait...": "Prosim počakaj...",
    },

    "nav": {
        "Home": "Domov",

        "Explore": "Raziskati",

        "Orders": "Naročila",

        "Profile": "Profil",
    },

    "shop": {
        "Featured": "Priporočeno",

        "Search Results": "Rezultati iskanja",

        "Recommended for you": "Priporočeno za vas",

        "No featured dishes available": "Priporočenih jedi ni na voljo",

        "Articles": "Artilki",

        "No articles": "Ni artiklov",

        "Search for Restaurant and Dishes": "Išči restavracije in jedi",
    },

    "home": {
        "Featured": "Popularno",
        "New":"Novo",
        "Discover Featured Places": "Odkrijte popularna mesta",

        "Top Categories": "Popularne kategorije",

        "Near you": "Blizu vas",
    },

    "restaurant": {
        "Name": "Ime",

        "New": "Nova restavracija",

        "License Code": "Licenčna koda",

        "Add New Restaurant": "Dodaj novo restavracijo",

        "WiFi password": "WiFi Geslo",

        "Description": "Opis",

        "Phone": "Telefon",

        "Email": "Email",

        "Web": "Internetni naslov",

        "Open": "Odpri",

        "Close": "Zapri",

        "Work time": "Delovni čas",

        "Work days": "Delovni dnevi",

        "Weekend": "Vikend",

        "Hours": "Ure",

        "Zip Code": "Poštna številka",

        "City": "Mesto",

        "Lat": "Zemljepisna širina",

        "Lng": "Zemljepisna dolžina",

        "Featured": "Priporočeno",

        "Full Address": "Polni naslov",

        "Open Hours": "Delovni čas",

        "Open Working Hours": "Delovni čas",
    },
    "myprofile": {
        "History": "Zgodovina",

        "Favourites": "Priljubljeno",

        "Addresses": "Naslovi",

        "Logout": "Odjava",
    },

    "Become iTable Member": "Postani iTable član",

    "Become iTable member and start experiencing the world of CAFES and RESTAURANTS in a different way":
        "Postani 😎  iTable član 😎   in začni doživljati   🌍  svet KAVARN in RESTAVRACIJ na drugačen način❤️❤️❤️",
       "Operate caffe or restaurant?":"Imate gostinski objekt ?", 
       "Work With Us":"Kontaktirajte nas",
       "Create an Account":"Ustvari račun",
       "Download app": "Prenesi aplikacijo",
       "Contact": "Kontakt",
       "Careers": "Karijera",
       "Prices": "Cena paketov",
       "iTable Modules": "iTable Moduli",
       "See digital MENU": "Poglej digitalni menu",
       "Call the WAITER": "Pokliči natakarja",
       "Current BALANCE": "Trenutno stanje mize",
       "Ask for BILL": "Prosi za račun gotovina/kartica",
    "For Restaurants": "Za gostince",
    "Advertise": "Reklama na iTable",
    "Free WEB page on iTable": "Brezplačna spletna stran na iTable",
    "Live statistic": "Statistika v živo",
    "Track working hours ": "Delovni čas zaposlenih",
    "Satisfied customers": "Zadovoljne stranke",
    "Welcome back!":"Dobrodošli!",

   "Meni": "Menu",
 "Stanje":"Stanje",
    "Current state": "Trenutno stanje",
    "Invalid Login Credentials!":"Nepravilni login podatki",
    "Email / Phone":"Email ili Telefon",
    "Bill": "Račun",

    "Verify Phone!": "Preverite svoj telefon!",

    "Password must be minimum 6 char long":
        "Geslo mora vsebovati najmanj 6 znakov",

    "Profile Picture": "Profilna slika",

    "To save please click Update": "Za shranjevanje pritsnite Posodobitev",

    "Click on image to change": "Pritisni za spremembo",

    "ORDER PLACED": "Naročilo oddano",
    "To save plase click Update":"Za snemanje pritisnite \"Snemaj\"",
    
    "Placed":"Kreirano",
    "Finished":"Dostavljeno",

    "In progres":"V obdelavi",


    "Accepted": "Sprejeto",



    "On the way": "Na poti",

    "Finished": "Končano",

    "Users": "Uporabniki",

    "Dashboard": "Začetna",

    "Created": "Ustvarjeno",

    "Email Verified": "Email preverjen",

    "Phone Verified": "Telefon preverjen",

    "Edit User": "Uredi uporabnika",

    "Currencie": "Valuta",

    "Restaurants": "Restavracije",

    "Dishes": "Jedi",

    "Drinks": "Pijače",

    "Dishes Categories": "Kategorije jedi",

    "Drinks Categories": "Kategorije pijač",

    "New Category": "Nova kategorija",

    "Add": "Dodaj",

    "Item": "Predmet",

    "Articls": "Artikli",

    "ITEM IN CART": "Predmet v košarici",

    "Checkout": "Odjava",

    "Grand Total": "Skupna vsota",

    "Your order placed successfully": "Vaše naročilo je bilo uspešno dodano",

    "Go to Orders": "Pojdi na naročila",

    "Select your payment method:": "Izberite plačilo:",

    "Cash on Delivery": "Gotovina po povzetju",

    "COD": "Gotovina",

    "CARD": "Kartica",

    "Close": "Zapri",

    "Language": "Jezik",

    "To change language, please click on flag.":
        "Za spremembo jezika, prosim klikni na zastavo.",

    "Pay with card": "Plačati s kartico",

    "Please login to order": "Za naročilo se prijavite",

    "You have to pay": "Za plačilo",

    "Call waiter": "Pokliči natakarja",

    "Press bell to call": "Pritisni zvonec za klic",

    "Try Again": "Poskusi znova",

    "Current state for this table is:": "Trenutno stanje mize je:",

    "Action is disabled.": "Dejanje onemogočeno.",

    "Time for action is limited to 5min. Please scann iTable code again.":
        "Čas postopka je omejen na 5 minut. Prosim ponovno skenirajte iTable kodo",

    "This QR code no exist in our system.":
        "QR koda ne obstaja v našem iTable sistemu.",

    "Register error":
        "Napaka pri regigstraciji:Prosim preverite ali ste prav vnesli email,telefonsko številko ali geslo.",

    "Your cart is empty": "Vaša košarica je prazna",

    "Add something to let us start working":
        "Dodajte nekaj, da začnemo z naročilom",

    "Please scan iTable QR code ": "Prosim skeniraj iTable QR kodo ",

    "Home": "Domov",

    "Search": "Iskanje",

    "Notifications": "Obvestila",

    "Profile": "Profil",
    "is placed successfully" : "uspešno poslano",
    "Your order":"Vaše naročilo",
    "You have no new Notifications": "Nimate novih obvestil",
    "Your order": "Vaše naročilo",
    "is placed successfully": "je uspešno oddano",
    "Recent Notifications": "Nedavna obvestila",
    "is Accepted": "je sprejet",
    "is prepared and will be picked up by a Delivery.":
        "je pripravljeno in bo prevzeto s strani dostave.",
    "is on the way.": "je na poti.",
    "is in progress": "je v teku",
    "is finished successfully": "je uspešno končano",
    "Page not found": "Stran ni najdena",
    "Uh-oh! Looks like the page you are trying to access, doesn't exist. Please start afresh.":
        "Uh-oh! Stran do katere poskušate dostopati ne obstaja. Prosim začnite znova.",
    "Restaurant has ": "Rastavracija ima ",
    " more dishes": " več jedi",
    "GPS location is OFF": "GPS lokacija je izključena",
    "GPS location is OFF": "GPS lokacija je izključena",
    "Please enable your GPS to use  ": "Prosim omogočite GPS za uporabo  ",
    "application": "aplikacija",
    "Please check your GPS and try again": "Prosim preverite svoj GPS in poskusite znova",
    "new":"Novo 🎉🆕",
    "Continue":"Nadaljuj",
    "Plans":"Paketi",
    "Name":"Ime / Ime podjetja",
    "Please enter your name":"Prosim, vnesite svoje ime",
    "Name":"Ime",
    "Please enter a valid email address":"Prosim, vnesite veljaven e-poštni naslov",
    "Please enter phone":"Prosim vnesite telefon",
    "Phone":"Telefon",
    "City":"Mesto",
    "Please enter your city":"Prosim vnesite svoje mesto",
    "Please enter a subject":"Prosim, vnesite",
    "Subject":"Tema",
    "Statistic":"Statistika",
    "Message":"Sporočilo",
    "Please enter a message":"Prosim vnesite sporočilo",
    "Submit":"Predložiti",
    "Created for everyone":"Ustvarjeno za vsakogar",
    "Ovaj paket je pogodan za sve velike ugostiteljske objekte i ukljucuje sve module, NEMA limita i kreće od 49+ stolova":"Ta paket je primeren za vse večje gostinske objekte in vključuje vse module, omejitev števila miz je od 49+.",
    "Ovaj paket je pogodan za sve veće ugostiteljske objekte i ukljucuje sve module, limit broja stolova je od 35 do 49.":"Ta paket je primeren za vse večje gostinske objekte in vključuje vse module, omejitev števila miz je od 35 do 49.",
    "Ovaj paket je pogodan za sve srednje ugostiteljske objekte i ukljucuje sve module, limit broja stolova je od 20 do 34.":"Ta paket je primeren za vse srednje velike gostinske objekte in vključuje vse module, omejitev števila miz je od 20 do 34.",
    "Ovaj paket je pogodan za sve manje ugostiteljske objekte i ukljucuje sve module sa limitom do maksimalno 15 stolova.":"Ta paket je primeren za vse manjše gostinske objekte in vključuje vse module z omejitvijo do 15 miz.",

    "Subscription boards created for all types and sizes of caterers":"Naročniški paketi ustvarjeni za vse vrste in velikosti gostinskih obratov",
    "For full expirience please download iTable aplication":"Za popolno izkušnjo prenesite aplikacijo iTable",
    "iTable location":"iTable lokacija",
    "Locate me":"Moja lokacija",
    "New":"Novo",
    "About Us": "O nas",
    "COUNTRIES": "Države",
    "Contact us":"Kontaktiraj nas"




};
