import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Image} from 'react-bootstrap';

const NoRest: React.FC = (props: any) => {

	useEffect(() => {
		window.scrollTo(0, 0)
		 
	  }, []);
    	return (
    		<section className="section   osahan-not-found-page">
		         <Container>
		            <Row>
		               <Col md={12} className="text-center pt-3  pb-5">
		                  <Image className="img-fluid radius-primary w-80" src="/img/notFound.jpg" alt="no gps" />
		                  <h2 className="mt-5 mb-4">No object with this iTable code</h2>
		                  <h5 className="mt-2">Please check that you use original <b>iTable</b> codes <br />. Please check code and scan again.</h5>
		                  <Link className="btn bg-white btn-outline-secundary  btn-lg w-50 mt-4 text-white "  onClick={()=>{props.history.goBack()}} to="#">Try Again</Link>
		               </Col>
		            </Row>
					<Col md={12}>
            <Col md={12} className="text-center pt-5 pb-5">
              <Link to="/">
                <div className="text-center">
                  <Image
                    className="w-50 align-center mb-5"
                    src="/img/logo.png"
                    alt=""
                  />{" "}
                </div>
              </Link>
            </Col>
          </Col>
		         </Container>
		    </section>
    	);
   
}


export default NoRest;