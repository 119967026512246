import React, { Component, useEffect, useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import GoogleMapReact from 'google-map-react';
import Marker from "./map/Marker";

import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
interface MyProps {
    show?: boolean;
    onHide?: any;
    onClick?: any;
    center?: any;
    onMapIdle?: any;
    t?: any
}

const exampleMapStyles = [
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d3d3d3"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "color": "#808080"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#b3b3b3"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "weight": 1.8
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d7d7d7"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ebebeb"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#a7a7a7"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#efefef"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#696969"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#737373"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d6d6d6"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {},
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dadada"
            }
        ]
    }
];
const Contact: React.FC<MyProps> = (props: any) => {
    const [center, setCenter] = useState({ lat: 45.8150108 ,lng: 15.981919 });
     const [zoom, setZoom] = useState(6);
     const [location, setLocation] = useState("");
     const [office, setOfficeName] = useState("");
     const [officeAdr, setOfficeAdr] = useState("");
     const [officeTel, setOfficeTel] = useState("");
const {t} = useTranslation();
    let markers=[
        {
            id:1,
            latitude: 44.731798,
            longitude: 18.0869,
            shelter:'iTable BIH',
            address:'Vidovdanska bb,74000 Doboj',
            tel:'+38765341711'
    
        },
        {
            id: 2,
            latitude: 46.056946, 
            longitude: 14.505751,
            shelter: 'iTable SLO',
            address:'Mislejeva ulica 3, 1000 Ljubljana',
            tel:"+38671437007"
    
        },
        {
            id: 3,
            latitude: 48.30639, 
            longitude:  14.28611,
            shelter: 'iTable AUT',
            address:'Herderstraße 27, Tür 8, 4060 Leonding',
            tel:"+38766886927"
    
        }
    ]
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm();
      
      // Function that displays a success toast on bottom right of the page when form submission is successful
      const setOffice = (a,name,adr,tel)=>{
          setLocation(a)
          setOfficeName(name)
          setOfficeAdr(adr)
          setOfficeTel(tel)
       }
      const toastifySuccess = () => {
        toast('Form sent!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: 'submit-feedback success',
          toastId: 'notifyToast'
        });
      };
      
      // Function called on submit that uses emailjs to send email of valid contact form
      const onSubmit = async (data) => {
        // Destrcture data object
        const { from_name,to_name,city, phone,email, subject, message } = data;
        try {
          const templateParams = {
            to_name,
            from_name,
            email,
            city,
            phone,
            subject,
            message
          };
    if(location==="2"){
        await emailjs.send(
            "service_eg48w8b",
            "template_85u4e86",
            templateParams,
            "user_fpRTP8GVe0kGRqpl7APc3"
          );
    
        
    }else if(location==="3"){
          await emailjs.send(
            "service_eg48w8b",
            "template_7iprfmf",
            templateParams,
            "user_fpRTP8GVe0kGRqpl7APc3"
          );
        }else{
            await emailjs.send(
                "service_eg48w8b",
                "template_7iprfmf",
                templateParams,
                "user_fpRTP8GVe0kGRqpl7APc3"
              );
        }
          reset();
          toastifySuccess();
        } catch (e) {
          console.log(e);
        }
      };
    
  
    return (

        //fll
        <div className="container-contact100">

            <div  style={{ zIndex: -2, position: 'absolute', width: '100%', top: '0', left: '0', height: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyC8NydMkPVNT4AsZOUolwFcQn6XVm-Q-YQ' }}
                    defaultCenter={props.center}
                    center={center}
                    onClick={()=>setLocation("")}
                    options={{
                        gestureHandling: "greedy",
                        styles: exampleMapStyles,
                        mapTypeControl: false,
                        streetViewControl: false,
                        zoomControl: props.zoomC,
                        fullscreenControl: false,
                        zoom: props.zoom,
                        minZoom: 2,
                        maxZoom: 30
                    }}

                    defaultZoom={zoom}
                >
                    {markers.map(marker => {
            //const onClick = props.onClick.bind(this, marker)
            return (
                <Marker
                    key={marker.id}
                    id={marker.id}
                    lat={marker.latitude}
                    lng={marker.longitude}
                    onClick={setOffice}
                    onTouchEnd={setOffice}
                    name={marker.shelter}
                    address={marker.address}
                    tel={marker.tel}
                    color="blue"
                    position={{ lat: marker.latitude, lng: marker.longitude }}
                >
                    
                </Marker>
            )
        })}
                
                </GoogleMapReact>


                <div className="wrap-contact100">

 
                </div>
            </div>



            <div id="dropDownSelect1"></div>
           {location !=="" ? <div className='ContactForm'>
      <div className='container'>
          <h5 className="text-center text-sec">{office}</h5>
          <p className="text-center text-sec"><FontAwesomeIcon icon={faGlobe}/> {officeAdr}</p>
          <p className="text-center text-sec"><FontAwesomeIcon icon={faPhone}/>{officeTel}</p>
        <div className='row'>
          <div className='col-12 text-center'>
            <div className='contactForm'>
              <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* Row 1 of form */}
                <div className='row formRow'>
                  <div className='col-6'>
                    <input
                      type='text'
                      
                      {...register('from_name', {
                        required: { value: true, message: t('Please enter your name') },
                        maxLength: {
                          value: 30,
                          message: t(' <= 30')
                        }
                      })}
                      className='form-control formInput'
                      placeholder={t('Name')}
                    ></input>
                    {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                  </div>
                  <div className='col-6'>
                    <input
                      type='email'
                       
                      {...register('email', {
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      className='form-control formInput'
                      placeholder='Email'
                    ></input>
                    {errors.email && (
                      <span className='errorMessage'>{t("Please enter a valid email address")}</span>
                    )}
                  </div>
                  <div className='col-6'>
                    <input
                      type='phone'
                       
                      {...register('phone', {
                        required: true,
                       })}
                      className='form-control formInput'
                      placeholder={t('Phone')}
                    ></input>
                    {errors.email && (
                      <span className='errorMessage'>{t("Please enter phone")}</span>
                    )}
                  </div>
                  <div className='col-6'>
                    <input
                      type='text'
                       
                      {...register('city', {
                        required: true,
                       })}
                      className='form-control formInput'
                      placeholder={t('City')}
                    ></input>
                    {errors.email && (
                      <span className='errorMessage'>{t("Please enter your city")}</span>
                    )}
                  </div>
                </div>
                {/* Row 2 of form */}
                <div className='row formRow'>
                  <div className='col'>
                    <input
                      type='text'
                       
                      {...register('subject', {
                        required: { value: true, message: t('Please enter a subject') },
                        maxLength: {
                          value: 75,
                          message: '<= 75'
                        }
                      })}
                      className='form-control formInput'
                      placeholder={t('Subject')}
                    ></input>
                    {errors.subject && (
                      <span className='errorMessage'>{errors.subject.message}</span>
                    )}
                  </div>
                </div>
                {/* Row 3 of form */}
                <div className='row formRow'>
                  <div className='col'>
                    <textarea
                      rows={3}
                       {...register('message', {
                        required: true
                      })}
                      className='form-control formInput'
                      placeholder={t('Message')}
                    ></textarea>
                    {errors.message && <span className='errorMessage'>{t("Please enter a message")}</span>}
                  </div>
                </div>
                <button className='btn btn-sm mt-2 mb-2 radius-primary submit-btn' type='submit'>
                  {t("Submit")}
                </button>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>:<></>}
        </div>
    );

}

const mapStateToProps = (state) => {
    return { state: state.auth }
}

export default Contact;