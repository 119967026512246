import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spinner, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CardItem from '../../common/CardItem';

import axios from '../../../helpers/requests'
import { faEdit, faMinusCircle, faRemoveFormat, faSort } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import EditProfileModal from "../../modals/EditProfileModal";
import { useSelector } from 'react-redux';
import { connect, RootStateOrAny } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddUserModal from '../../modals/user/AddUserModal';



const Users: React.FC = (props: any) => {
	const [showEditProfile, setEditProfile] = useState(false);
	const [showAddUserModal, setAddUserModal] = useState(false);

	const { t } = useTranslation()
	const [users, setUsers] = useState<any>([]);
	const [tempUser, setTempUser] = useState<any>([]);
	const authState = useSelector((state: RootStateOrAny) => state.auth);

	function roleFormatter(cell, row) {
		
			return (
				<span>
					<strong style={{ color: 'red' }}>{cell} A</strong>
				</span>
			)
	}
	const columns = [{
		dataField: 'id',
		text: 'ID'
	}, {
		dataField: 'roles[0].name',
		text: t('profile.Roles'),
		hidden: true,
		formater: roleFormatter
	}, {
		dataField: 'email',
		text: t('profile.Email'),
		filter: textFilter(),
		formater: roleFormatter
	}, {
		dataField: 'phone',
		text: t('profile.Phone'),
		filter: textFilter()
	}

];
	function deleteUser(idu) {

		const response = axios.post<Object>('/admin/users/delete', { id: idu })
			.then((res) => {
				getData()

			})
			.catch((err) => {
			});



		/*  history.replace('/')  */

	}
	function renderModal(u) {

		setTempUser(u)
		setEditProfile(true)

	}
	const expandRow = {
		renderer: row => (
			<div className="row">
				<div className="col-2">
					<img src={row.avatar ? row.avatar : "/img/user/default.png"} className="p-1 " width="50px" />

				</div>
				<div className="col-4">
					<p><b>{t('profile.Full Name')}</b> : {row.name}</p>
					<p><b>{t('Created')}</b> : {row.created_at}</p>



				</div>
				<div className="col-4">
					{row.email_verified_at && (<p><b>{t('Email Verified')}</b> : {row.email_verified_at}</p>)}
					{row.phone_verified_at && (<p><b>{t('Phone Verified')}</b> : {row.phone_verified_at}</p>)}


				</div>

				<div className="col-2">
					<Button onClick={() => renderModal(row)} className="btn mr-2 btn-outline-secondary   btn-sm   border-primary mt-1  btn bg-primary text-white" color="warning" size="sm" >
						<FontAwesomeIcon icon={faEdit} />
					</Button>
					<Button onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteUser(row.id) }} className="btn  btn-outline-secondary   btn-sm   mt-1  btn bg-white text-primary" color="warning" size="sm" >
						<FontAwesomeIcon icon={faMinusCircle} />
					</Button>

				</div>
			</div>
		),
		showExpandColumn: true,
		nonExpandable: []
	};

	const options = {
		onSizePerPageChange: (sizePerPage, page) => {
			console.log('Size per page change!!!');
			console.log('Newest size per page:' + sizePerPage);
			console.log('Newest page:' + page);
		},
		onPageChange: (page, sizePerPage) => {
			console.log('Page change!!!');
			console.log('Newest size per page:' + sizePerPage);
			console.log('Newest page:' + page);
		}
	};

	function getData() {
		axios
			.get("/admin/users")
			.then((res) => {
				setUsers(res.data);
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}
	const hideEditProfile = () => {

		setEditProfile(false);
	};
	const hideAddModal = () => {

		setAddUserModal(false);
	};
	useEffect(() => {
		getData();
	}, []);
	return (
		<>

			<div className='p-4 bg-white shadow-sm'>

				<Row>
					<EditProfileModal key={tempUser.id}
						show={showEditProfile}
						userId={tempUser.id}
						admin={true}
						user={tempUser}
						t={t}
						onHide={hideEditProfile}
					/>
					<AddUserModal
						show={showAddUserModal}
						t={t}
						onHide={hideAddModal}
					/>
					<Col className="p-0 col-6">
						<h4 className="font-weight-bold mt-0 mb-3 col-6">{t("Users") + " (" + users?.length + ")"} { }</h4>
					</Col>
					<Col className="p-0 text-right">
						<Button onClick={()=>setAddUserModal(true)} className="btn  btn-outline-primary   btn-sm   mt-1 text-white btn bg-white" color="warning" size="sm" >
							{t("profile.Add User")}
						</Button>
					</Col>
					<Col md={12} className="p-0">
						<Card>
							<BootstrapTable
								keyField='id'
								data={users}
								filter={filterFactory()}
								columns={columns}
								expandRow={expandRow}
								pagination={paginationFactory(options)}
							/>
						</Card>
					</Col>







					<Col md={12} className="text-center load-more">
						{users !== [] ? "" : (<Button variant="primary" type="button">
							<Spinner animation="grow" size="sm" className='mr-1' />
							{t("Please wait...")}
						</Button>)}
					</Col>
				</Row>

			</div>
		</>
	);

}
export default Users;