import React, { useEffect, useState } from "react";
import OrderCard from "../common/OrderCard";
import actions from "../../action/user";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "../../helpers/requests";
import {  Container  } from 'reactstrap';

import { Button, Col, Row, Spinner } from "react-bootstrap";

const Orders: React.FC = (props: any) => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<any>([]);
  function getData() {
    axios
      .get("/orders")
      .then((res) => {
        setOrders(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    
    <section className="section pb-2   bg-gray">
    <Container   className="pt-6">
          
      <Row>
        <Col>
        <h4 className="font-weight-bold mt-0 mb-4">{t("Orders")}</h4>
    </Col>
   </Row>
   <Row>
       
        {orders &&
          orders.map((order) => {
            const { id,status,total, unique_id ,restaurant,created_at,order_dishes,updated_at,payment_mode} = order;
			let ordered=""
			let i=0
			order_dishes.forEach(element => {
			    i++
				if(i<order_dishes.length){
				ordered=ordered+(element.name +" x "+element.quantity+", ")
			     }else
				 {
					ordered=ordered+(element.name +" x "+element.quantity+"")
 
				 }
			});
			ordered.replace(',','')
            return (
              
              <OrderCard key={id}
              type={payment_mode}
			  t={t}
                image={restaurant.image}
				id={id}
				history={props.history}
                imageAlt={restaurant.name}
                orderNumber={unique_id}
                orderDate={created_at}
				status={status}
                deliveredDate={updated_at}
                orderTitle={restaurant.name}
                address={restaurant.addresses[0].street+", "+restaurant.addresses[0].postal_code+" "+restaurant.addresses[0].city} 
                orderProducts={ordered}
                orderTotal={total+" " + restaurant.currencie.code}
                helpLink="#"
                detailLink={"/shop/"+restaurant.id}
              />
            );
          })}
          
          </Row>
          <Col md={4} xs={12} className="text-center load-more">
	                    {orders.length>0?"":(<Button variant="primary" type="button">
	                    	<Spinner animation="grow" size="sm" className='mr-1' />
	                        {t("Please wait...")}
	                    </Button> )} 
	                 </Col>
           
      </Container>
      </section>
       
     
  );
};
const mapStateToProps = (state) => {
  return { state: state.auth };
};

export default connect(mapStateToProps, actions)(Orders);
