import React from "react";
import { Card, CardImg, Col, Container, Row } from "react-bootstrap";
import { CardBody } from "reactstrap";
import SectionHeading from "../common/SectionHeading";
import Ripples from "react-ripples";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBicycle, faCar, faWalking } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel3";

/* import { useTranslation } from "react-i18next";
import { usePromiseTracker } from "react-promise-tracker";
 */

type ListProps = {
  history?: any;
  categories: any;
  heading: string;
};

const Categories: React.FC<ListProps> = ({ history, categories, heading }) => {
  /* const { promiseInProgress } = usePromiseTracker();
	const { t } = useTranslation(); */

  return (
    <Container className="p-2">
      {categories.length > 2 && (
        <SectionHeading haveMini={false} heading={heading} />
      )}
      <Row>
        <Col md={12}>
          {categories.length > 1 && (
            <OwlCarousel {...options} className="cat-ad owl-theme">
              {categories.map((restaurant) => {
                const { id, name, image } = restaurant;
                return (
                  <div className="mr-3 myfrontslide" key={id}>
                    <Ripples
                      className="d-block myblock"
                      onClick={() => {
                        setTimeout(function () {
                          history.push(`/search`);
                        }, 275);
                      }}
                    >
                      <Card className="my-featured shadow-sm p-0 m-0">
                        <CardImg className="rounded-lg catimage" src={image} alt="" />

                        <CardBody className="p-2 h-category text-left">
                          <h6 className="my-0 font-weight-bold shop-name">
                            {name}
                          </h6>
                        </CardBody>
                      </Card>
                    </Ripples>
                  </div>
                );
              })}
            </OwlCarousel>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const options = {
  responsive: {
    0: {
      items: 3,
    },
    764: {
      items: 4,
    },
    765: {
      items: 6,
    },
    1200: {
      items: 6,
      rows:3,
    },
  },
  
  lazyLoad: true,
  loop: true,
  autoplay: true,
  
  autoplaySpeed: 7000,
  dots: false,
  autoplayTimeout: 3000,
  nav: false,
  navText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>",
  ],
  autoplayHoverPause: true,
};  
export default Categories;
