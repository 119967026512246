import React from 'react';
import {Link} from 'react-router-dom';
import {Card, Col, Row} from 'react-bootstrap';
import Icofont from 'react-icofont';
import Ripples from 'react-ripples';
import PropTypes from 'prop-types'; 

interface MyProps {
    title: string,
    icoIcon: string,
    disabled?: string,
    iconclassName?: string,
    address?: string,
    onEditClick: any,
    boxClass?:string,
    onDeleteClick?: any
}
const QrCard: React.FC<MyProps> = (props: any) => {

     	return (
           
        <div   className={"bg-white addresses-item mb-3 " + (props.disabled)}>
             <Ripples 
                          className={"d-block myblock bg-primary radius-primary " + (props.boxClass)}
                          onClick={() => {
                            setTimeout(function () {
                              // history.push(`/shop/${id}`);
                            }, 275);
                          }}
                        >
            <div onClick={props.onEditClick} className="">
               <Card>
                   <Row>
                       <Col className="col-8 pr-0">
                       <div className="media-body w-100">
                     <h5 className="mt-2 text-center text-secondary text-white">{props.title}</h5>
                         </div>
                  </Col>
                  <Col className="col-4 p-1 pl-2 col bg-primary text-primary">
                  <div className="text-center mr-3"><Icofont icon={props.icoIcon} className={props.iconclassName} /></div>

                        </Col>

                    </Row>
               </Card>
            </div>
            </Ripples>
        </div>
      
    	);
   
}
 

export default QrCard;