import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import actions from '../../action/cart';
import PaypalCheckout from "./PaypalCheckout";
import RazorpayCheckout from "./RazorpayCheckout";
import axios from '../../helpers/requests';
import { ToggleButtonGroup } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
 
interface ListProps {
    history: any,
    t:any,
    togle?:any,
    idres?:number
}
const PaymentMethods: React.FC<ListProps> =  (props) => {

    const [paymentGateway, setPaymentGateway] = useState({} as any)
    const [loaded, setLoaded] = useState(false)
    const cart = useSelector((state: RootStateOrAny) => state.cart);
	const state = useSelector((state: RootStateOrAny) => state);

    const dispatch = useDispatch();

    useEffect(() => {
        let basket_restaurant_id =  state.cart.restaurant_id;

        if(basket_restaurant_id){
        }else{
            basket_restaurant_id =   state.cart.restaurant_id; 
        }
        if(basket_restaurant_id){
        axios.get('/settings/payment_options',{params:{id_res:basket_restaurant_id}}).then((res) => {
            const payment_options = res.data
            setPaymentGateway(payment_options)
            setLoaded(true)
        })
        } 
    }, [state])
    const onCardPurchase = (e) => {
        let cartForm = cart
        cartForm.method = "CARD"
        cartForm.qr = localStorage.getItem("qr")
        dispatch(actions.place_order(cartForm, props.history))
        props.togle()

    }
    const onCODPurchase = (e) => {
        let cartForm = cart
        cartForm.method = "COD"
        cartForm.qr = localStorage.getItem("qr")

        dispatch(actions.place_order(cartForm, props.history))
        props.togle()

    }

    return (
        <>
            {
                loaded ? (
                    <>
                        {paymentGateway.pg_paypal_active === "1" && <PaypalCheckout history={props.history} client_id={paymentGateway.pg_paypal_key} currency_code={paymentGateway.currency_code} />}
                        {paymentGateway.pg_razorpay_active === "1" && <RazorpayCheckout history={props.history} client_id={paymentGateway.pg_razorpay_client_key} currency_code={paymentGateway.currency_code} />}
                        {paymentGateway.pg_cod_active === "1" && (
                        <Row className="flex-row flex-wrap  radius-primary shadow p-4 m-2 bg-white border-bottom" onClick={onCODPurchase}>
                            <Col xs="8" className="p-0">
                                <h5 className="font-weight-bold">{props.t("COD")}</h5>
                                {props.t("Cash on Delivery")}
                            </Col>
                            <Col xs="4" className="p-0">
                                <img className="w-100  radius-primary" src='/img/icons/common/monayPayment.png' alt="monay" />
                            </Col>
                        </Row>
                        )}
                         {paymentGateway.pg_card_active === 1 && (
                        <Row className="flex-row flex-wrap mt-4 radius-primary shadow p-4 m-2 bg-white border-bottom" onClick={onCardPurchase}>
                            <Col xs="8" className="p-0">
                                <h5 className="font-weight-bold">{props.t("CARD")}</h5>
                                {props.t("Pay with card")}
                            </Col>
                            <Col xs="4" className="p-0">
                                <img className="w-100 bg-gray  radius-primary" src='/img/icons/common/cardPayment.png' alt="card" />
                            </Col>
                        </Row>
                        )}
                    </>
                ) :
                    [...Array(4)].map((e, i) => (""))
            }

        </>
    )
};

export default PaymentMethods;