import { faLock, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';

import {Modal,Button} from 'react-bootstrap';
interface MyProps {
    show: boolean;
    reload?: boolean;
    onHide?: any;
    btn_text?: string;
    text:string,
    textBig?:string,
	image:string,
    location?:any,
    t?:any
}

const Infomodal: React.FC<MyProps> = (props: any) => {
    const [pressed,setPressed]=useState("/img/icons/common/bell.gif")
    const [image,setImage]=useState(props.image)
    const [btnText,setBtnText]=useState(props.t("Try Again"))

    const saveData=()=>
	{

		setImage("/img/icons/common/succes.gif")
	setTimeout(() => {  if(!props.reload){props.onHide()}else{window.location.reload()} ;setImage(props.image) }, 1500); 
    
   }
   useEffect(() => {
    setImage(props.image)
    if(props.btn_text){
    setBtnText(props.btn_text)
    }
}, [props.show]);
    	return (
	        <Modal 
			backdrop={"static"}
	        	show={props.show} 
	         className="info-modal"
		        size="lg"
		        centered
		   	  >
			   
			  <Modal.Body>
                <div className=" text-center p-2">
			 <img   className="radius-primary w-50" src={image}/>
             <h5  className="mt-4 mb-4 w-100 text-center text-default">{props.text}</h5>
             <h4  className="mt-4 mb-4 w-100 text-center text-default">{props.textBig}</h4>

             <button className="btn bg-white btn-outline-secundary w-50 btn-lg w-50 mt-4 text-white "  onClick={()=>saveData()} >{btnText}</button>
		              
				    
               </div>
           
			  </Modal.Body>

			  
			</Modal>
    	);
     
}
export default Infomodal;