import React from 'react';
import './Marker.css';
import jquery from 'jquery'
 

const Marker = (props: any) => {
    const { onTouchEnd,address,tel,color,onClick, name, id } = props;
    return (
      <div className="marker"
        style={{ backgroundColor: color, cursor: 'pointer'}}
        title={name}
      ><button onTouchEnd={()=>onTouchEnd(id,name,address,tel)} onClick={()=>onClick(id,name,address,tel)} className="contact100-btn-show">
			<i className="fa fa-envelope-o" aria-hidden="true"></i>
		</button></div>
    );
  };

  export default Marker;