export const TRANSLATIONS_EN = {
    "Details": "Details",

    "Please wait...": "Please wait...",

    "welcome": {
        "title": "Welcome to {{framework}}",

        "best of": "best of",

        "pizzerias": "pizzerias",

        "caterers": "caterers",

        "restaurants": "restaurants",

        "caffes": "cafes",

        "clubs": "clubs",

        "Call the WAITER, see digital MENU, order through the APP, see the table current BALANCE ...":
            "Call the WAITER, see digital MENU, order through the APP, see the current table BALANCE ...",

        "Please turn on GPS to get location":
            "Please turn on the GPS to get the location",
    },

    "profile": {
        "Phone number": "Phone number",

        "Cancel": "Cancel",

        "Update": "Update",

        "Email": "Email",

        "Password": "Password",

        "EDIT": "EDIT",

        "Profile": "Profile",

        "Today Orders": "Today's orders",

        "7 Days": "7 Days",

        "7 Users": "7 Days Users",

        "Today Earnings": "Today's Earnings",

        "Total Orders": "Total Orders",

        "Total Users": "Total Users",

        "Total Restaurants": "All Stores",

        "Total Events": "Total Events",

        "Total Banners": "Total Banners",

        "Your Banners": "Your Banners",

        "More info": "More info",

        "Featured Caffes": "Featured Cafes",

        "Featured Dishes": "Featured Dishes",

        "Featured Categories": "Featured Categories",

        "Featured Restauraunts": "Featured Restaurants",

        "Featured Clubs": "Featured Clubs",

        "Featured Pizzerias": "Featured Pizzerias",

        "Caffe": "Cafe",

        "Dishes": "Dishes",

        "Add User": "Add User",

        "Drinks": "Drinks",

        "Restauraunt": "Restaurant",

        "Club": "Club",

        "Category": "Category",

        "Categories": "Categories",

        "Price": "Price",

        "Active": "Active",

        "Action": "Action",

        "List of Dishes": "List of Dishes",

        "List of Drinks": "List of Drinks",

        "List of Addons": "List of Addons",

        "Addons Categories": "Add-ons Categories",

        "Dish Categories": "Dish Categories",

        "Drink Categories": "Drink Categories",

        "Pizzeria": "Pizzeria",

        "Addons": "Addons",

        "Live Orders": "Live Orders",

        "This Week": "This Week",

        "View Report": "View Report",

        "Cake shop": "Cake shop",

        "Last 7 days orders": "Last 7 days orders",

        "Total Earnings": "Total Earnings",

        "Distance": "Distance",

        "DistanceInfo": "Max. distance to show objects around",

        "Full Name": "Full Name",

        "Phone": "Phone",

        "Language": "Language",

        "My Order": "My Order",

        "Notifications": "Notifications",

        "Logout": "Logout",
    },

    "login": {
        "Sign in to start your session": "Sign in",

        "Password": "Password",

        "Sign in": "Sign in",

        "Sign up": "Sign up",

        "Continue as guest": "Continue as a guest",

        "Skip": "Skip",

        "Don't have an account?": "Don't have an account?",

        "Forgot Password?": "Forgot your Password?",

        "Remember Me": "Remember Me",

        "Please wait...": "Please wait...",
    },

    "nav": {
        "Home": "Home",

        "Explore": "Explore",

        "Orders": "Orders",

        "Profile": "Profile",
    },

    "shop": {
        "Featured": "Featured",

        "Search Results": "Search Results",

        "Recommended for you": "Recommended for you",

        "No featured dishes available": "No featured dishes available",

        "Articles": "Articles",

        "No articles": "No articles",

        "Search for Restaurant and Dishes": "Search for Restaurants and Dishes",
    },

    "home": {
        "Near you":"Near you",
        "Featured": "Featured",

        "Discover Featured Places": "Discover Featured Places",

        "Top Categories": "Top categories",

    },

    "restaurant": {
        "Name": "Name",

        "New": "New restaurant",

        "License Code": "License Code",

        "Add New Restaurant": "Add a New Restaurant",

        "WiFi password": "WiFi Password",

        "Description": "Description",

        "Phone": "Phone",

        "Email": "Email",

        "Web": "Site URL",

        "Open": "Open",

        "Close": "Close",

        "Work time": "Work time",

        "Work days": "Work days",

        "Weekend": "Weekend",

        "Hours": "Hours",

        "Zip Code": "Zip Code",

        "City": "City",

        "Lat": "Latitude",

        "Lng": "Longtitude",

        "Featured": "Featured",

        "Full Address": "Full Address",

        "Open Hours": "Open Hours",

        "Open Working Hours": "Open Working Hours",
    },
    "myprofile": {
        "History": "History",

        "Favourites": "Favorites",

        "Addresses": "Addresses",

        "Logout": "Logout",
    },

    "Become iTable Member": "Become iTable Member",

    "Become iTable member and start experiencing the world of CAFES and RESTAURANTS in a different way":
        "Become 😎  iTable member 😎   and start experiencing   🌍  the world of CAFES and RESTAURANTS in a different way ❤️❤️❤️",

   "Meni": "Menu",

    "Stanje": "Current State",

    "Bill": "Ask for the bill",

    "Verify Phone!": "Verify your Phone!",

    "Password must be minimum 6 char long":
        "Password must be minimum 6 characters long",

    "Profile Picture": "Profile Picture",

    "To save plase click Update": 'To save please click "Update"',

    "Click on image to change": "Click to change",

    "ORDER PLACED": "Order placed",

    "Accepted": "Accepted",

    "In progres": "In progress",

    "On the way": "On the way",

    "Finished": "Finished",

    "Users": "Users",

    "Dashboard": "Dashboard",

    "Created": "Created",

    "Email Verified": "Email Verified",

    "Phone Verified": "Phone Verified",

    "Edit User": "Edit User",

    "Currencie": "Currency",

    "Restaurants": "Restaurants",

    "Dishes": "Dishes",

    "Drinks": "Drinks",

    "Dishes Categories": "Dishes Categories",

    "Drinks Categories": "Drinks Categories",

    "New Category": "New Category",

    "Add": "Add",

    "Item": "Item",

    "Articls": "Articles",

    "ITEM IN CART": "ITEMS",

    "Checkout": "Checkout",

    "Grand Total": "Grand Total",

    "Your order placed successfully": "Your order is submited successfully",

    "Go to Orders": "Go to Orders",

    "Select your payment method:": "Select your payment method:",

    "Cash on Delivery": "Cash on Delivery",

    "COD": "CASH",

    "CARD": "CARD",

    "Close": "Close",

    "Language": "Language",

    "To change language, please click on flag.":
        "To change language, please click on flag.",

    "Pay with card": "Pay with card",

    "Please login to order": "Please login to order",

    "You have to pay": "You have to pay",

    "Call waiter": "Call the waiter",

    "Press bell to call": "Press the bell to call",

    "Try Again": "Try Again",

    "Current state for this table is:": "Current balance for this table is:",

    "Action is disabled.": "Action is disabled.",

    "Time for action is limited to 5min. Please scann iTable code again.":
        "Time for action is limited to 5min. Please scann iTable code again.",

    "This QR code no exist in our system.":
        "This QR code does not exist in our iTable system.",

    "Register error":
        "Error in registration:Please check that you entered your email,phone or password correctly.",

    "Your cart is empty": "Your cart is empty",

    "Add something to let us start working":
        "Add something to let us start working",

    "Please scan iTable QR code ": "Please scan iTable QR code ",

    "Home": "Home",

    "Search": "Search",

    "Notifications": "Notifications",

    "Profile": "Profile",
    "You have no new Notifications": "You have no new Notifications",
    "Your order": "Your order",
    "is placed successfully": "is placed successfully",
    "Recent Notifications": "Recent Notifications",
    "is Accepted": "is Accepted",
    "is prepared and will be picked up by a Delivery.":
        "is prepared and will be picked up by a Delivery.",
    "is on the way.": "is on the way.",
    "is in progress": "is in progress",
    "is finished successfully": "is finished successfully",
    "Page not found": "Page not found",
    "Uh-oh! Looks like the page you are trying to access, doesn't exist. Please start afresh.":
        "Uh-oh! Looks like the page you are trying to access, doesn't exist. Please start afresh.",
    "Restaurant has ": "Restaurant has ",
    " more dishes": " more items in category",
    "GPS location is OFF": "GPS location is OFF",
    "GPS location is OFF": "GPS location is OFF",
    "Please enable your GPS to use  ": "Please enable your GPS to use  ",
    "application": "application",
    "Please check your GPS and try again": "Please check your GPS and try again",
    "new":"New 🎉🆕",
    "Continue":"Continue",
    "Plans":"Plans",
    "Please enter your name":"Please enter your name",
    "Name":"Name",
    "Please enter a valid email address":"Please enter a valid email address",
    "Please enter phone":"Please enter phone",
    "Phone":"Phone",
    "City":"City",
    "Please enter your city":"Please enter your city",
    "Please enter a subject":"Please enter a subject",
    "Subject":"Subject",
    "Message":"Message",
    "Please enter a message":"Please enter a message",
    "Submit":"Submit",
    "iTable location":"iTable location",
    "Statistic":"Statistic",
    "Created for everyone":"Created for everyone",
    "Ovaj paket je pogodan za sve velike ugostiteljske objekte i ukljucuje sve module, NEMA limita i kreće od 49+ stolova":"This package is suitable for all major catering facilities and includes all modules, the limit on the number of tables is from 35 to 49.",
    "Ovaj paket je pogodan za sve veće ugostiteljske objekte i ukljucuje sve module, limit broja stolova je od 35 do 49.":"This package is suitable for all major catering facilities and includes all modules, the limit on the number of tables is from 35 to 49.",
    "Ovaj paket je pogodan za sve manje ugostiteljske objekte i ukljucuje sve module sa limitom do maksimalno 19 stolova.":"This package is suitable for all smaller catering facilities and includes all modules with a limit of up to 19 tables.",
    "Ovaj paket je pogodan za sve srednje ugostiteljske objekte i ukljucuje sve module, limit broja stolova je od 20 do 34.":"This package is suitable for all medium-sized catering facilities and includes all modules, the limit on the number of tables is from 20 to 34.",
    "Subscription boards created for all types and sizes of caterers":"Subscription boards created for all types and sizes of caterers",
    "For full expirience please download iTable aplication":"For full expirience please download iTable aplication"


};
