import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PageTitle from './common/PageTitle';
import CouponCard from './common/CouponCard';
import BankOffers from './common/BankOffers';
import axios from '../helpers/requests';
import { useTranslation } from 'react-i18next';

const Offers: React.FC = (props: any) => {
	let { t } = useTranslation();
	const [packages, setPackages] = useState([]);

	function getPackages() {
		axios
			.get<any>("/packages", {

			})
			.then((res) => {
				setPackages(res["data"])

			})
			.catch(function (error) {
				if (error) {

				}
			});
	}
	useEffect(() => {
		window.scrollTo(0, 0)
		getPackages()
	}, []);

	return (
		<>
			<PageTitle
				image="/img/gallery/all3appiTable.jpg"
				title={t("Created for everyone")}
				subTitle={t("Subscription boards created for all types and sizes of caterers")}
			/>
			<section className="section pt-3 pb-5">
				{localStorage.getItem("i18nextLng") !="sl" ? 
				<Container>
					<Row>
						<Col md={12}>
							<h4 className="text-center font-weight-bold mt-0 mb-3">{t("Prices")}</h4>
						</Col>


						 
						{packages.map(function(pack, i){
        return  (pack["name"] !="GOLD" && <div className="col-md-4 col-sm-6 p-3">
		<div className="pricingTable">
			<div className="pricingTable-header">
				<span className="heading">
					<h3>{pack["name"]}</h3>
				</span>
				<span className="price-value">{localStorage.getItem("i18nextLng")=="ba"||localStorage.getItem("i18nextLng")=="sr"?pack["price"]:pack["price"]*2} {localStorage.getItem("i18nextLng")=="ba"||localStorage.getItem("i18nextLng")=="sr"?"KM":"€"}<span className="month">{t(pack["desc"])}</span></span>
			</div>
			<div className=" pricingTable-sign-up">
				<a href="/contact" className="btn btn-default text-center" >{t("Contact")}</a>
			</div>
		</div>
	</div> || pack["name"] =="GOLD" && <div className="col-md-4 col-sm-6 p-3">
		<div className="pricingTable">
			<div className="pricingTable-header">
				<span className="heading">
					<h3>{pack["name"]}</h3>
				</span>
				<span className="price-value">{localStorage.getItem("i18nextLng")=="ba"||localStorage.getItem("i18nextLng")=="sr"?"PO DOGOVORU":"CUSTOM PRICE"} {localStorage.getItem("i18nextLng")=="ba"||localStorage.getItem("i18nextLng")=="sr"?"":""}<span className="month">{t(pack["desc"])}</span></span>
			</div>
			<div className=" pricingTable-sign-up">
				<a href="/contact" className="btn btn-default text-center" >{t("Contact")}</a>
			</div>
		</div>
	</div>    ) 
    })}
						

						 

					</Row>
				</Container>:<Container>
					<Row>
						<Col md={12}>
							<h4 className="text-center font-weight-bold mt-0 mb-3">{t("Prices")}</h4>
						</Col>


						 
					 
        <div className="col-md-4 col-sm-6 p-3">
		<div className="pricingTable">
			<div className="pricingTable-header">
				<span className="heading">
					<h3>MINIMUM</h3>
				</span>
				<span className="price-value">39.90 €<span className="month">Ta paket je primeren za vse manjše gostinske objekte in vključuje vse module z omejitvijo do 15 miz.</span></span>
			</div>
			<div className=" pricingTable-sign-up">
				<a href="/contact" className="btn btn-default text-center" >{t("Contact")}</a>
			</div>
		</div>
	</div> 
	<div className="col-md-4 col-sm-6 p-3">
		<div className="pricingTable">
			<div className="pricingTable-header">
				<span className="heading">
					<h3>STANDARD</h3>
				</span>
				<span className="price-value">54.90 €<span className="month">Ta paket je primeren za vse srednje velike gostinske objekte in vključuje vse module, omejitev števila miz je od 15 do 30.</span></span>
			</div>
			<div className=" pricingTable-sign-up">
				<a href="/contact" className="btn btn-default text-center" >{t("Contact")}</a>
			</div>
		</div>
	</div> 
	<div className="col-md-4 col-sm-6 p-3">
		<div className="pricingTable">
			<div className="pricingTable-header">
				<span className="heading">
					<h3>BUSINESS</h3>
				</span>
				<span className="price-value">69.90 €<span className="month">Ta paket je primeren za vse srednje velike gostinske objekte in vključuje vse module, omejitev števila miz je od 31 do 50.</span></span>
			</div>
			<div className=" pricingTable-sign-up">
				<a href="/contact" className="btn btn-default text-center" >{t("Contact")}</a>
			</div>
		</div>
	</div> 
	<div className="col-md-4 col-sm-6 p-3">
		<div className="pricingTable">
			<div className="pricingTable-header">
				<span className="heading">
					<h3>PREMIUM</h3>
				</span>
				<span className="price-value">81.90 €<span className="month">Ta paket je primeren za vse srednje velike gostinske objekte in vključuje vse module, omejitev števila miz je od 51 do 70.</span></span>
			</div>
			<div className=" pricingTable-sign-up">
				<a href="/contact" className="btn btn-default text-center" >{t("Contact")}</a>
			</div>
		</div>
	</div> 
	<div className="col-md-4 col-sm-6 p-3">
		<div className="pricingTable">
			<div className="pricingTable-header">
				<span className="heading">
					<h3>EXTRA</h3>
				</span>
				<span className="price-value">119,90 €<span className="month">Ta paket je primeren za vse večje gostinske objekte in vključuje vse module, omejitev števila miz je od 71 do 100.</span></span>
			</div>
			<div className=" pricingTable-sign-up">
				<a href="/contact" className="btn btn-default text-center" >{t("Contact")}</a>
			</div>
		</div>
	</div> 
	<div className="col-md-4 col-sm-6 p-3">
		<div className="pricingTable">
			<div className="pricingTable-header">
				<span className="heading">
					<h3>GOLD</h3>
				</span>
				<span className="price-value">po dogovoru<span className="month">Ta paket je primeren za vse JUMBO gostinske objekte in vključuje vse module, omejitev števila miz je od 101.</span></span>
			</div>
			<div className=" pricingTable-sign-up">
				<a href="/contact" className="btn btn-default text-center" >{t("Contact")}</a>
			</div>
		</div>
	</div> 
						

						 

					</Row>
				</Container>}
			</section>


		</>
	);
}



export default Offers;