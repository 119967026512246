import React, { useEffect, useState } from "react";
import Zoom from "react-reveal/Zoom";
// reactstrap components
import {
	Card,
	CardBody,
	CardHeader,
	CardImg,
	Col,
	Row,
	Container,
	Input,
	Label,
} from "reactstrap";
import PackBoxCounter from "../components/Utilities/pack-box-counter";

import actions from "../action/cart";

import { useSelector, RootStateOrAny, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import { Form, FormGroup, InputGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
interface MyProps {
     
    curr: string,
	onHide?: any;
}
const Addons: React.FC<MyProps> = (props:any) => {
	const {t} = useTranslation();
	const [selectedOption, setSelectedOption] = useState({});

	 

	//Selectors
	const getAddonState = useSelector(
		(state: RootStateOrAny) => state.cart.addons && state.cart.addons.addons_dish
	) ?? [];

	const getDishState = useSelector(
		(state: RootStateOrAny) => state.cart.addons && state.cart.addons
	) ?? {};

	 const [showModal, setShowModal] = useState(getAddonState.length > 0);
	const dispatch = useDispatch();

	const closeModal = () => {
		dispatch(actions.addons_hide());
	};

	const addAddons = () => {
		if (getAddonState.length > 0) {
			// getAddonState.addons_dish
		 

			const {
				dish_id,
				price,
				name,
				image,
				is_veg,
				restaurant_id,
				addons_dish,
			} = getDishState;

			// let addons = getAddonState.map((d) => {
			// 	const v = d.addons_category;
			// 	if (selectedOption[v.id]){
			// 		if(v.type == "MULTIPLE"){
			// 			const selected_addons = v.addons.filter(ad => selectedOption[v.id].includes(ad.id))
			// 			return { id: v.id, name: v.name, addons: selected_addons };
			// 		}else
			// 			return { id: v.id, name: v.name, addons: v.addons };
			// 	}
			// });
			var addons = getAddonState.reduce(function (result, d) {
				const v = d.addons_category;
				if (selectedOption[v.id]) {
					if (v.type == "MULTIPLE") {
						const selected_addons = v.addons.filter(ad => selectedOption[v.id].includes(ad.id))
						result.push({ id: v.id, name: v.name, addons: selected_addons });
					} else{
						const selected_addons = v.addons.filter(ad => selectedOption[v.id] == ad.id)
						result.push({ id: v.id, name: v.name, addons: selected_addons });
						// result.push({ id: v.id, name: v.name, addons: v.addons });
					}
				}
				return result;
			}, []);

			const amount = isNaN(price) ? parseFloat(price.substr(1)) : price;
			let curr=props.curr
			dispatch(actions.increment({ image,dish_id, name, is_veg, amount, restaurant_id, addons ,curr}))
			dispatch(actions.addons_hide());
			setSelectedOption({})
			// dispatch(actions.increment(getAddonState));
		}
	};

	const setSelected = (category_id, option_id, type) => {

		if (type === 2) {
			let addons = selectedOption[category_id]
			console.log(addons)
			if (addons != undefined) {
				const index = (addons as any).findIndex((addon: any) => addon === option_id);
				if (index !== -1) {
					addons.splice(index, 1);
					console.log(addons)
					setSelectedOption({ ...selectedOption, [category_id]: addons } as any);
				} else
					setSelectedOption({ ...selectedOption, [category_id]: [...selectedOption[category_id], option_id] } as any);
			} else
				setSelectedOption({ ...selectedOption, [category_id]: [option_id] } as any);

		}
		else if (type === 1)
			setSelectedOption({ ...selectedOption, [category_id]: option_id } as any);
	};

	const RenderAddons = (data) => {
		const { id, type, name, addons } = data.addons_category;
		

		if (type === "SINGLE") {
			return (
				<Row className="my-2">
					<Col lg="12">
						
						<FormGroup>
						 
							<h5 className="font-weight-bold">{name}</h5>

							{addons.map((d, i) => (
							<FormGroup key={i}>
								
							<Form.Check inline name="same" type="radio" label={d.name + " - "+d.price+" "+props.curr}
							checked={selectedOption[id] != undefined ? (selectedOption[id].filter(e => e == d.id).length > 0) : false}
							onChange={(e) => setSelected(id, d.id, 2)}
						/>

							
						</FormGroup>
						 
						))}
						</FormGroup>
					</Col>
				</Row>
			);
		} else if (type === "MULTIPLE") {
			return (
				<Row className="my-2">
					<Col lg="12">
						<h5 className="font-weight-bold">{name}</h5>

						{addons.map((d, i) => (
							<FormGroup key={i}>
								
							<Form.Check   type="checkbox" label={d.name + " - "+d.price+" "+props.curr}
							checked={selectedOption[id] != undefined ? (selectedOption[id].filter(e => e == d.id).length > 0) : false}
							onChange={(e) => setSelected(id, d.id, 2)}
						/>

							
						</FormGroup>
						 
						))}
					 
						 
						 
					</Col>
				</Row>
			);
		} else return <></>;
	};
 
	return (
		<Modal
		show={getAddonState && getAddonState.length > 0}
		onHide={()=>closeModal()}

			cssClass="my-custom-class"
		size="sm"
		centered
	 >
		<Modal.Header closeButton={true}>
		   <Modal.Title as='h5' id="edit-profile"></Modal.Title>
		</Modal.Header>
		 
		   <Modal.Body>
			 
					{getAddonState &&
						getAddonState.map((data, i) => (
							<RenderAddons {...data} key={i} />
						))}

					 
						<div
							className="text-center font-weight-bold p-0"
							onClick={() => addAddons()}
						>
							{t("Add")}
            </div>
					 
			
			</Modal.Body>
		</Modal>
	);
};

export default Addons;
